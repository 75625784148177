<template>
  <v-card class="ma-2 pa-0 mt-5">
    <v-container>
      <v-row class="justify-center">
        <v-col cols="10">
          <v-row>
            <v-col class="ml-4"
              cols="4"
            >
              <v-text-field v-model="subTypeName" maxlength="255"
                            label="小区分"
                            outlined
                            dense></v-text-field>
            </v-col>
            <v-col
              slot="header"
              class="btn-group"
              role="group"
              key="footer"
            >
              <v-btn color="primary" :disabled="subTypeName == ''" @click="add">新規</v-btn>
            </v-col>
          </v-row>
          <draggable tag="ul" :list="subTypeList" @end="endDraging" @change="reOder">
            <transition-group>
              <v-col
                class="list-group-item"
                v-for="(subType, idx) in subTypeList"
                :key="subType.id"
              >
                <v-col>
                  <v-icon class="handle">mdi-drag</v-icon>

                  <v-col class="d-inline" cols="3">
                    <span>{{subType.name}}</span>
                  </v-col>
                </v-col>

                 <v-col>
                   <v-btn class="float-end" color="warning" @click="removeAt(idx,subType.id)">削除</v-btn>
                   <v-btn class="float-end mr-2 " color="primary" @click="edit(subType.id,subType.name)">編集</v-btn>
                 </v-col>

              </v-col>
            </transition-group>
          </draggable>

        </v-col>

        <rawDisplayer class="col-3" :value="subTypeList" title="List" />
      </v-row>

      <v-row justify="center">
        <v-dialog
          v-model="editDialog"
          persistent
          max-width="700"
        >
          <v-card>
            <v-toolbar color="primary" dark>
              <span class="text-h5 ml-3">小区分マスタ表示</span>
              <v-spacer></v-spacer>
              <v-btn icon @click="editDialog = false">
                <v-icon large>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text>
                <v-row align="center" class="mt-4">
                  <v-col cols="4">
                    <p class="text-subtitle-1 ml-16">小区分</p>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field maxlength="255" outlined dense v-model="newNameChange"></v-text-field>
                  </v-col>
                </v-row>
              <v-row justify="center">
                <v-btn :disabled="!canEdit || newNameChange == ''"
                       rounded
                       color="primary"
                       @click="updateSubtype(subtypeEdit.id,newNameChange)"
                       x-large class="mb-3"
                >保存</v-btn>
              </v-row>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </v-container>
    <v-snackbar v-model="noti.success"
                :timeout="3000"
                absolute
                bottom
                multi-line
                color="success"
                right>
      <strong><v-icon>mdi-check</v-icon>
        {{noti.notiText}}</strong>
    </v-snackbar>
    <v-snackbar v-model="noti.error"
                :timeout="3000"
                absolute
                bottom
                multi-line
                color="red accent-2"
                right>
      <strong><v-icon>mdi-close</v-icon>
        {{noti.notiText}}</strong>
    </v-snackbar>

  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import subtypeServices from "@/services/subtypeServices";

export default {
  components: {
    draggable
  },
  data() {
    return {
      order: 0,
      id: 0,
      subTypeName: '',
      subTypeList: [],
      dragging: false,
      editDialog: false,
      canEdit: false,

      // notify
      noti:{
        error: false,
        success: false,
        notiText: '',
      },

      // edit
      newNameChange: '',
      subtypeEdit: [
        {
          id: null,
          name: '',
        }
      ],
    };
  },
  mounted() {
    subtypeServices.getSubtypeList().then(Response=>{
      this.subTypeList = Response.data.data;
    });
  },
  methods: {
    reOder(){
      const newSubtypeList = [...this.subTypeList].map((item,index)=>{
        const newOrder = index + 1;
        item.hasChanged = item.sortOrder !== newOrder;
        if(item.hasChanged){
          item.sortOrder = newOrder;
        }
        return item;
      });
      this.subTypeList = newSubtypeList;
    },
    async endDraging(){
      let params = this.subTypeList;
      subtypeServices.orderUpdate(params).then(Response=>{
        this.subTypeList = Response.data.data;
      });
    },
    async reloadList(){
      subtypeServices.getSubtypeList().then(Response=>{
        this.subTypeList = Response.data.data;
      })
    },
    async removeAt(idx,subId) {
      subtypeServices.deleteSubtype(subId).then(()=>{
        this.subTypeList.splice(idx, 1);
      })
    },
     async add() {
      if(this.subTypeName != ''){
        this.order++;
          let params = {
          name: this.subTypeName,
          order: this.order,
        }
        subtypeServices.createSubtype(params).then(()=>{
          this.reloadList();
          this.subTypeName = '';
        })
      }
    },
    edit(subId,subName){
      this.subtypeEdit.id = subId;
      this.subtypeEdit.name = subName;
      this.newNameChange = subName;
      this.editDialog = true;
    },
    async updateSubtype(id,name){
      let params = {name: name};
      subtypeServices.editSubtype(id,params).then(()=>{
        this.editDialog = false;
        this.newNameChange = '';
        this.subtypeEdit.name = '';
        this.subtypeEdit.id = null;
        this.reloadList();
      }).catch(()=>{
        this.editDialog = false;
        this.newNameChange = '';
        this.subtypeEdit.name = '';
        this.subtypeEdit.id = null;
        this.reloadList();
      });
    }
  },

  watch:{
    subTypeList(){
      if(this.subTypeList.length == 0){
        this.order = 0;
        this.id = 0;
      } else {
        this.order = Math.max.apply(Math, this.subTypeList.map(function (subTypeList){
          return subTypeList.order;
        }));

        this.id = Math.max.apply(Math, this.subTypeList.map(function (subTypeList){
          return subTypeList.id;
        }));
      }
    },
    newNameChange(){
      if(this.newNameChange == this.subtypeEdit.name){
        this.canEdit = false;
      } else this.canEdit = true;
    }
  }
};
</script>
<style scoped>

.handle {
  float: left;
}
input {
  display: inline-block;
  width: 50%;
}

.list-group-item{
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  align-content: center;
  padding: 0px;
}
.list-group-item > div {
  padding: 6px;
}
.list-group-item:hover{
  background-color: #d29e1921;
}
</style>
