import http from '@/httpCommon.js';

export default {
  async createSubtype(data) {
    return http.post(`/subtype`, data,{ headers: {enableMessage: true}});
  },
  async getSubtypeList(){
    return http.get('/subtype',{ headers: {enableMessage: false}});
  },
  async deleteSubtype(subId){
    return http.delete(`/subtype/${subId}`,{ headers: {enableMessage: true}});
  },
  async editSubtype(subId,name){
    return http.put(`/subtype/${subId}`,name,{ headers: {enableMessage: true}});
  },

  async orderUpdate(dataUpdate){
    return http.put('/subtype-order',dataUpdate,{ headers: {enableMessage: true}});
  }

};
