<template>
    <!-- <v-container class="grey lighten-3"> -->
    <v-card class="ma-1" :style="{backgroundColor: this.$store.state.backgroundColorProduct ? this.$store.state.backgroundColorProduct : this.defaultBackgroundColor}">
        <v-card-title>
            <v-container fluid>
                <v-row align="center">
                    <!-- 最小化による表示の切り替え -->
                    <v-col v-show="!minimizeFlg" cols="7" class="py-1">
                        <span class="text-h6 ml-3 mr-3">商品情報<span style="color: red;">*</span></span>
                      <v-btn
                        rounded
                        color="success"
                        class="px-15 text-subtitle-1"
                        @click="searchConditionsModalOpen"
                        v-if="!readonlyFlg"
                      >検索</v-btn>
                    </v-col>
                    <v-col v-show="minimizeFlg" class="text-truncate py-1" cols="7">
                        <span class="text-h6">商品名：</span>
                        <template v-if="newMatterProductsData.id !== null">
                            <span class="text-h6 ml-2">{{ newMatterProductsData.productName }}</span>
                        </template>
                        <template v-else>
                            <span class="text-h6 ml-2">商品が登録されていません</span>
                        </template>
                    </v-col>
                    <v-spacer></v-spacer>
                  <v-btn
                    rounded
                    class="px-6 mr-6"
                    color="primary"
                    @click="openColorPickerBackgroundModal"
                  > 背景設定
                  </v-btn>
                    <!-- 最小化による表示の切り替え -->
                    <div v-show="!minimizeFlg">
                        <v-btn
                            rounded
                            color="grey darken-1"
                            dark
                            class="px-8 mr-7"
                            @click="changeMinimizeMode(true)"
                        >最小化</v-btn>
                    </div>
                    <div v-show="minimizeFlg" class="text-right">
                        <v-btn
                            rounded
                            color="grey darken-1"
                            class="white--text px-8 mr-7"
                            @click="changeMinimizeMode(false)"
                        >開く</v-btn>
                    </div>
                  <v-dialog v-model="colorPickerBackground"
                            persistent
                            scrollable
                            max-width="350px">
                    <ColorPickerBackgroundModal
                      @closeColorPickerBackgroundModal="closeColorPickerBackgroundModal"
                      @updateBackgroundColor="updateBackgroundColor"
                      @resetBackgroundColor="resetBackgroundColor"
                      :backgroundColor="backgroundColor"
                      :defaultBackgroundColor="defaultBackgroundColor"
                    />
                  </v-dialog>
                </v-row>
            </v-container>
        </v-card-title>
        <!-- 最小化状態なら非表示 -->
        <div v-show="!minimizeFlg">
          <v-divider class="mx-4 mb-2"></v-divider>
          <v-row>
            <v-col cols="9">
              <!-- 商品情報テーブル -->
              <v-data-table
                :headers="headers"
                :items="tableItem"
                :page.sync="page"
                :items-per-page="itemsPerPage"
                @page-count="pageCount = $event"
                hide-default-footer
                no-data-text="選択された商品はありません"
                class="elevation-1 mx-4"
                @dblclick:row="productDetailModalOpen"
              >
                <!-- 削除アイコン部分 -->
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    text
                    color="red"
                    @click="deleteItem(item, matterId)"
                    :disabled="readonlyFlg"
                    >
                    <v-icon left>mdi-delete</v-icon>削除
                  </v-btn>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="3">
              <ProductInformationLot-component
                v-bind:matterStatusData.sync = "matterStatusData"
                :matter-id = "matterId"
                style="padding-right: 16px;"
                :readonlyFlg="readonlyFlg"
              ></ProductInformationLot-component>
            </v-col>
          </v-row>
          <v-divider class="mx-4 mt-2"></v-divider>
          <!-- 検索ボタン -->
          <v-card-actions v-show="!historyModalFlg">
            <v-row align="center" justify="center" style="height:70px;">
              <!-- 検索条件モーダル -->
              <v-dialog v-model="searchConditionsModal" persistent max-width="800">
                <SearchConditions-component
                  ref="searchConditionsModal"
                  @modal-close="searchConditionsModalClose"
                  @add-table="productServiceAddition($event, matterId)"
                ></SearchConditions-component>
              </v-dialog>
            </v-row>
          </v-card-actions>

          <!-- ダブルクリックで表示する商品詳細モーダル -->
          <v-dialog v-model="productDetailModal" scrollable max-width="95%">
            <DetailInformation-component
              v-if="productDetailModal"
              :productInfo="productInfo"
              @modal-close="productDetailModalClose"
            ></DetailInformation-component>
          </v-dialog>
        </div>
    </v-card>
    <!-- </v-container> -->
</template>

<script>
import DetailInformation from './ProductInformationDetailInformationModal.vue'
import SearchConditions from './ProductInformationSearchConditionsModal.vue'
import ColorPickerBackgroundModal from './ColorPickerBackgroundModal.vue'
import updateMatterService from '@/services/updateMatterService.js'
import ProductInformationLot from './ProductInformationLot.vue'
import userSettingService from "@/services/userSettingService";

export default {
    props: {
        // 親コンポーネントよりmatterIdを取得
        matterId: {
            type: Number,
        },
        matterProductsData: {
            type: [String, Number, Object]
        },
        readonlyFlg: {
            type: Boolean,
        },
        // 問い合わせ履歴モーダル
        historyModalFlg: {
            type: Boolean,
        },
        matterStatusData: {
          type: [String, Number, Object]
        },
    },
    components: {
        'DetailInformation-component': DetailInformation,
        'SearchConditions-component': SearchConditions,
        'ProductInformationLot-component': ProductInformationLot,
        ColorPickerBackgroundModal,
    },
    data() {
        return {
            // テーブルのページネーション関係
            page: 1,
            pageCount: 0,
            // 1ページに表示する行数の指定
            itemsPerPage: 5,

            // テーブルのヘッダー
            headers: [
                // { text: 'No', value: 'number' },
                { text: '商品コード', value: 'productCode', sortable: false },
                { text: '品番', value: 'productNumber', sortable: false },
                { text: '商品名', value: 'productName', sortable: false },
                { text: '商品名(カナ)', value: 'productNameKana', sortable: false },
                { text: '削除', value: 'actions', sortable: false },
            ],

            // 親から受け取ったmatterProductsDataを子で更新するためのData
            newMatterProductsData: '',

            // 検索条件モーダルflg
            searchConditionsModal: false,

            // 検索結果モーダルflg
            searchResulDialog: false,

            // ダブルクリックで表示する商品詳細モーダルのflg
            productDetailModal: false,
            // ダブルクリックされた商品の情報を格納
            productInfo: '',

            // 最小化の表示を切り替えるflg
            minimizeFlg: false,
            colorPickerBackground: false,

            // loading時間
            loadingCount: 0,
            backgroundColor: this.$store.state.backgroundColorProduct,
            defaultBackgroundColor: '#CBCB86',
        }
    },
    computed: {
        // テーブルアイテム
        tableItem() {
            if (this.newMatterProductsData.id != null) {
                return [this.newMatterProductsData];
            }
            return [];
        },
    },
    methods: {
        // 商品特定済みなら最小化で表示
        productIdentifyCheck() {
            if (this.newMatterProductsData.id !== null) {
                this.changeMinimizeMode(true);
            } else {
                this.changeMinimizeMode(false);
            }
        },

        // テーブルから商品を削除
        deleteItem: async function (item, matterId) {
            this.loadingCount++;
            try {
                let productId = item.id;
                if (item.id !== null) {
                    productId = null
                }
                // DB更新(商品削除)
                await updateMatterService.updateProductData(
                    matterId,
                    productId,
                ).then(response => {
                  this.newMatterProductsData = response.data.data.product;
                  // 親コンポーネント(InquiryHandle.vue)更新
                  this.$emit('update:matterProductsData', this.newMatterProductsData);
                }).catch(error => {
                  if(error.response.status === 403){
                    const editing_by = error.response.data.message.editing_by;
                    this.$emit('isEditing',editing_by);
                  }
                });

            } catch (e) {
                console.log(e);
            }
            this.loadingCount--;
        },
        createProductData: async function (productId) {
          const response = await updateMatterService.createMatterProductData(productId);
          // 取得(更新)したデータを格納
          const newMatter = response.data.data;
          this.$emit('create:matterProduct', newMatter);
        },
        // 検索結果モーダルよりダブルクリックした行の商品をDBに追加、モーダルを２つ閉じる
        productServiceAddition: async function ($event, matterId) {
            this.$refs.searchConditionsModal.loadingCount++;
            this.loadingCount++;
            try {
                // 子コンポーネント側でダブルクリックされた行の商品情報を「$event」として受けとる
                // 登録商品が既に登録済みの場合、DB更新処理を行わない
                let productIdCheck = true;
                if (this.newMatterProductsData.id === $event.id) {
                    productIdCheck = false;
                }
                // DB更新(商品登録)
                if (productIdCheck) {
                    if (matterId > 0) {
                      await updateMatterService.updateProductData(
                          matterId,
                          $event.id
                      ).then(response => {
                        this.newMatterProductsData = response.data.data.product;
                        // 親コンポーネント(InquiryHandle.vue)更新
                        this.$emit('update:matterProductsData', this.newMatterProductsData);
                      }).catch(error =>{
                        if(error.response.status === 403){
                          const editing_by = error.response.data.message.editing_by;
                          this.$emit('isEditing',editing_by);
                        }
                      });
                    }else{
                      await this.createProductData(
                          $event.id);
                    }
                }
                // 検索結果のモーダルを閉じる
                this.searchResulDialog = false;
                // 検索条件のモーダルを閉じる
                this.searchConditionsModal = false;
            } catch (e) {
                console.log(e);
            }
            this.$refs.searchConditionsModal.loadingCount--;
            this.loadingCount--;
        },
        updateBackgroundColor(backgroundColorProduct) {
          const data = {
            background_product: backgroundColorProduct,
          }
          this.$store.commit('updateBackgroundColorProduct', backgroundColorProduct);
          userSettingService.updateMyUserSettings(data);
        },
        resetBackgroundColor() {
          const data = {
            background_product: this.defaultBackgroundColor,
          }
          this.$store.commit('updateBackgroundColorProduct', this.defaultBackgroundColor);
          userSettingService.updateMyUserSettings(data);
          this.closeColorPickerBackgroundModal();
        },
        openColorPickerBackgroundModal() {
          if (this.$store.state.backgroundColorProduct == null){
            this.backgroundColor = this.defaultBackgroundColor
          }else {
            this.backgroundColor = this.$store.state.backgroundColorProduct
          }
          this.colorPickerBackground = true
        },
        closeColorPickerBackgroundModal() {
          this.colorPickerBackground = false
        },
        // 検索条件モーダルを開く
        searchConditionsModalOpen() {
            this.searchConditionsModal = true
        },
        // 検索条件モーダルを閉じる
        searchConditionsModalClose() {
            this.searchConditionsModal = false
        },

        // テーブルの行をダブルクリックした時に、モーダルに渡す「productInfo」を更新し商品詳細情報モーダルを開く
        productDetailModalOpen(event, { item }) {
            this.productInfo = item;
            this.productDetailModal = true;
        },
        // 商品詳細情報モーダルを閉じる
        productDetailModalClose() {
            this.productDetailModal = false
        },

        // バックエンドから取得したMatterProductsDataをコピー
        matterProductsDataCopy() {
            this.newMatterProductsData = this.matterProductsData;
        },
        // ルートが変更された時に呼び出す関数(この関数がないと/inquiryhandle/1から/inquiryhandle/2に変わった時にコンポーネントが更新されない)
        reloadProductInformation: async function () {
            try {
                // バックエンドから取得したMatterProductsDataをコピー
                this.matterProductsDataCopy();
                // 商品特定済みなら最小化で表示
                this.productIdentifyCheck();
            } catch (e) {
                console.log(e);
            }
        },
        changeMinimizeMode(val){
          this.minimizeFlg = val;
          this.$emit('changeMinimizeMode', val);
        },
    },
    mounted() {
        // バックエンドから取得したMatterProductsDataをコピー
        this.matterProductsDataCopy();
        // 商品特定済みなら最小化で表示
        this.productIdentifyCheck();
    },
}
</script>

<style scoped>
/* テーブルのヘッダーのフォントサイズ */
.v-data-table ::v-deep th {
    font-size: 18px !important;
    background-color: #bdbdbd;
}

/* 検索結果が「０」の場合に表示するテキストのフォントサイズ */
::v-deep .v-data-table__empty-wrapper > td {
    font-size: 20px !important;
    /* font-family: sans-serif !important; */
}
</style>
