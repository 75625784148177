<template>
  <div>
    <v-card class="ma-3">
      <v-card-title class="py-1">お知らせ一覧</v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pb-0">
        <!-- 検索条件 -->
        <v-row align="center" no-gutters>
          <!-- 日付検索 -->
          <v-col cols="2" class="d-flex justify-center">
            <v-subheader>投稿/更新日時検索</v-subheader>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="startDate"
              type="date"
              clearable
              label="開始日"
              class="mini-size"
              outlined
              dense
              hide-details
            />
          </v-col>
          <div class="text-h5 text-center">～</div>
          <v-col cols="4">
            <v-text-field
              v-model="endDate"
              type="date"
              clearable
              label="終了日"
              class="mini-size-right"
              outlined
              dense
              hide-details
            />
          </v-col>
        </v-row>
        <v-row align="center" no-gutters>
          <!-- 商品検索 -->
          <v-col cols="2" class="d-flex justify-center">
            <v-subheader class="float-none">商品検索</v-subheader>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="relatedProducts"
              clearable
              label="関連商品"
              hide-details
              outlined
              dense
              class="mini-size"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="4" class="style-checkbox">
            <v-checkbox
              v-model="deletedChecked"
              :label="'削除済みも含む'"
              class="d-inline-flex mini-size"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="outOfPeriodChecked"
              :label="'掲載期間外も含む'"
              class="d-inline-flex mini-size"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row align="center" no-gutters>
          <!-- タイトル、文章検索 -->
          <v-col cols="2" class="d-flex justify-center">
            <v-subheader>タイトル</v-subheader>
          </v-col>
          <v-col cols="4">
            <v-text-field
              v-model="title"
              clearable
              label="タイトル"
              hide-details
              outlined
              dense
              class="mini-size"
            ></v-text-field>
          </v-col>
          <v-col cols="1"></v-col>
          <v-col cols="4" class="style-checkbox">
            <v-checkbox
              v-model="improvementFlgChecked"
              :label="'要望事項のみ表示'"
              class="d-inline-flex mini-size"
              dense
            ></v-checkbox>
            <v-checkbox
              v-model="nonRequestItem"
              :label="'要望事項以外を表示'"
              class="d-inline-flex mini-size"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="justify-center">
        <v-btn
          @click="onSearch"
          rounded
          elevation="5"
          width="150"
          color="success"
          class="white--text"
        >
          <v-icon left>mdi-magnify</v-icon>検索
        </v-btn>
      </v-card-actions>
      <v-divider class="mx-4"></v-divider>
      <v-card-text>
        <!-- 検索結果 -->
        <notification-table
          :notifications="notifications"
          :pageCount="pageCount"
          @curentPage="page = $event"
          :isDashboardScreen="false"
          :isSearch="isSearch"
          :key="notificationTblKey"
          @open-dialog-for-viewing="openDialogForViewing"
          @open-dialog-for-creating="openDialogForCreating"
          @open-dialog-for-editing="openDialogForEditing"
          @open-dialog-for-deleting="openDialogForDeleting"
          @readAllNotifications="readAllNotifications"
          @clearIsSearch="isSearch = false"
        />
      </v-card-text>

      <div></div>
      <!-- お知らせ詳細ダイアログ -->
      <notification-details-dialog
        v-bind="notification"
        :dialog.sync="detailsDialog"
        @close-dialog="
          notification = null;
          detailsDialog = false;
        "
      />
      <!-- お知らせ編集ダイアログ -->
      <notification-edit-dialog
        v-bind="notification"
        :validation-errors="validationErrors"
        :dialog.sync="editDialog"
        @create-notification="createNotification"
        @update-notification="updateNotification"
        @close-dialog="
          notification = null;
          editDialog = false;
          validationErrors = {};
        "
      />
      <!-- 削除確認ダイアログ -->
      <v-dialog v-model="deleteDialog" width="500px">
        <v-card>
          <v-card-title class="text-h6">お知らせの削除確認</v-card-title>
          <v-card-text v-html="delMsg" style="font-size: 18px"></v-card-text>
          <v-card-actions>
            <!-- 閉じるボタン -->
            <v-spacer></v-spacer>
            <v-btn color="warning" @click="deleteNotifications">削除</v-btn>
            <v-btn color="primary" @click="deleteDialog = false">閉じる</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-snackbar v-model="snackbar" color="error" top :timeout="3000">
        <v-layout align-center>
          <v-layout column>
            <div>
              <strong>エラー</strong>
            </div>
            <div
              v-for="(message, index) in validationErrors.title
                ? validationErrors.title
                : []"
              :key="`title-${index}`"
            >
              {{ message }}
            </div>
            <div
              v-for="(message, index) in validationErrors.notificationStartDate
                ? validationErrors.notificationStartDate
                : []"
              :key="`start-date-${index}`"
            >
              {{ message }}
            </div>
            <div
              v-for="(message, index) in validationErrors.notificationEndDate
                ? validationErrors.notificationEndDate
                : []"
              :key="`end-date-${index}`"
            >
              {{ message }}
            </div>
          </v-layout>
        </v-layout>
      </v-snackbar>
      <v-dialog v-model="snackbarUpdateSuccess" scrollable max-width="500px">
        <v-card>
          <v-card-title class="text-h5" style="margin: auto">お知らせ情報を更新しました。</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded color="primary" large class="px-15" @click="snackbarUpdateSuccess = false">確認</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog v-model="snackbarCreateSuccess" scrollable max-width="500px">
        <v-card>
          <v-card-title class="text-h5" style="margin: auto">お知らせ情報を投稿しました。</v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn rounded color="primary" large class="px-15" @click="snackbarCreateSuccess = false">確認</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <!-- ローディング中にスピナーを表示させる -->
      <v-overlay :value="loadingCount > 0">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import NotificationEditDialog from '@/components/parts/NotificationEditDialog.vue';
import NotificationDetailsDialog from '@/components/parts/NotificationDetailsDialog.vue';
import NotificationTable from '../components/parts/NotificationTable.vue';
import notificationService from '@/services/notificationService';

export default {
  components: {
    'notification-details-dialog': NotificationDetailsDialog,
    'notification-edit-dialog': NotificationEditDialog,
    'notification-table': NotificationTable,
  },
  data() {
    return {
      page: 1,
      startDate: '',
      endDate: '',
      relatedProducts: '',
      title: '',
      deletedChecked: '',
      outOfPeriodChecked: '',
      improvementFlgChecked: '',
      nonRequestItem: '',
      detailsDialog: false,
      editDialog: false,
      deleteDialog: false,
      pageCount: null,
      isSearch: false,
      delMsg: '',
      notificationsForDeleting: [],
      headers: [
        { text: 'id', value: 'id', align: ' d-none' },
        { text: '投稿日時', value: 'createdAt' },
        { text: '更新日時', value: 'updatedAt' },
        { text: 'タイトル', value: 'title' },
        { text: '関連商品', value: 'relatedProducts' },
        { text: '掲載期間', value: 'postPeriod' },
      ],
      notifications: [],
      notification: null,
      notificationTblKey: 0,
      validationErrors: {},
      snackbar: false,
      snackbarCreateSuccess: false,
      snackbarUpdateSuccess: false,
      loadingCount:0,
      Valid_Read_Status: 1,
    };
  },
  watch: {
    async page(){
      await this.searchNotifications();
      },
    },
  mounted() {
      this.$store.dispatch('headerTitleChangeAction',5)
  },
  methods: {
    async onSearch() {
      this.isSearch = true
      await this.searchNotifications(1)
    },
    async searchNotifications(page = null) {
      this.loadingCount++;
      if (page) this.page = page
      // 検索条件を取得してお知らせを取得
      const conditions = {
        start_date: this.startDate,
        end_date: this.endDate,
        related_products: this.relatedProducts,
        title: this.title,
        contain_deleted: this.deletedChecked ? 1 : 0,
        contain_out_of_period: this.outOfPeriodChecked ? 1 : 0,
        contain_improvement_flg: this.improvementFlgChecked ? 1 : 0,
        contain_non_request_item: this.nonRequestItem ? 1 : 0,
        page:this.page
      };
      const response = await notificationService.searchNotifications(
        conditions
      );
      this.pageCount = response.data.data.last_page;
      this.notifications = response.data.data.data.map((notification) => {
        let postPeriod;
        // postPeriod = notification.notificationStartDate ?? '          ';
        // postPeriod += ' ～ ';
        // postPeriod += notification.notificationEndDate ?? '          ';
        postPeriod =
          notification.notificationStartDate === null
            ? '          '
            : notification.notificationStartDate;
        postPeriod += ' ～ ';
        postPeriod +=
          notification.notificationEndDate === null
            ? '          '
            : notification.notificationEndDate;
        notification.postPeriod = postPeriod;
        return notification;
      });
      this.loadingCount--;
    },
    async updateUnreadNotify(){
      let response = await notificationService.countUnreadNotifications();
      this.$store.commit('updateUnreadNotify',response.data.amount);
    },
    openDialogForViewing(item) {
      this.notification = item;
      this.detailsDialog = true;
      this.validationErrors = {};
    },
    openDialogForCreating() {
      this.notification = null;
      this.editDialog = true;
      this.validationErrors = {};
    },
    openDialogForEditing(item) {
      this.notification = item;
      this.editDialog = true;
    },
    openDialogForDeleting(items) {
      this.notificationsForDeleting = items;
      const notificationsCount = items.length;
      this.delMsg = `${notificationsCount}個のお知らせが削除されます。宜しいでしょうか？`;
      this.deleteDialog = true;
    },
    async createNotification(notification) {
      notification.improvementFlg = 0;
      if (notification.notificationEndDate < notification.notificationStartDate ){
        this.editDialog = true;
        this.validationErrors.notificationEndDate = ["掲載終了日は掲載開始日より後ろの日付に設定してください"];
        this.snackbar = true;
      }else {
        // APIを呼び出し、保存処理を行う
        try {
          await notificationService.createNotification(notification);
          this.updateUnreadNotify();
          this.snackbarCreateSuccess = true;
        } catch (e) {
          console.log(e)
          this.editDialog = true;
          if (e.response && e.response.status === 422) {
            this.validationErrors = e.response.data.errors;
            this.snackbar = true;
          }
          return;
        }
        this.notification = null;
        this.validationErrors = {};
        // お知らせを再取得し、テーブルを更新する
        await this.searchNotifications();
        // 検索結果部分を初期状態に更新
        this.notificationTblKey++;
      }
    },
    async updateNotification(notification) {
      if (notification.notificationEndDate < notification.notificationStartDate ){
        this.editDialog = true;
        this.validationErrors.notificationEndDate = ["掲載終了日は掲載開始日より後ろの日付に設定してください"];
        this.snackbar = true;
      }else {
        // APIを呼び出し、保存処理を行う
        try {
          await notificationService.updateNotification(
            notification.id,
            notification
          );
          this.updateUnreadNotify();
          this.snackbarUpdateSuccess = true;
        } catch (e) {
          this.editDialog = true;
          if (e.response && e.response.status === 422) {
            this.validationErrors = e.response.data.errors;
            this.snackbar = true;
          }
          return;
        }
        this.notification = null;
        this.validationErrors = {};
        // お知らせを再取得し、テーブルを更新する
        await this.searchNotifications();
        // 検索結果部分を初期状態に更新
        this.notificationTblKey++;
      }
    },
    async readAllNotifications(item) {
      if (item){
        for (let i in item){
          let notification = item[i];
          if (notification.read_status == 0){
            this.loadingCount++;
            notification.read_status = this.Valid_Read_Status;
            const conditions = {
              notificationId: notification.id,
              readStatus: 1
            };
            await notificationService.createNotificationReader(conditions);
            this.loadingCount--;
          }
        }
        await this.updateUnreadNotify();
      }
    },
    async deleteNotifications() {
      this.loadingCount++;
      this.deleteDialog = false;
      // APIを呼び出し、削除処理を行う
      await notificationService.deleteNotifications(
        this.notificationsForDeleting
      );
      this.updateUnreadNotify();
      this.notificationsForDeleting = [];
      // お知らせを再取得し、テーブルを更新する
      await this.searchNotifications();
      // 検索結果部分を初期状態に更新
      this.notificationTblKey++;
      this.loadingCount--;

    },
  },
  async created() {
    await this.searchNotifications();
  },
};
</script>

<style scoped>
.mini-size {
  transform: scale(0.85);
  transform-origin: left;
}
.mini-size-right {
  transform: scale(0.85);
  transform-origin: right;
}
.style-checkbox{
  margin-top: -2px;
  margin-left: -23px;
}
</style>
