import http from "@/httpCommon";

export default {
  getProductUploadList(){
    return http.get(`/product-uploads`);
  },

  deleteProductUpload(product_upload_id){
    return http.delete(`/product-uploads/${product_upload_id}`);
  },
}
