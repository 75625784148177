import http from '@/httpCommon.js';

export default {
  async createClassification(data){
    return http.post(`/classification`,data)
  },

  async getClassifiCationChild(){
    return http.get(`/classification-child`)
  },

  async searchClassificationcation(data) {
    let query = ''
    const key = Object.keys(data)
    const value = Object.values(data)

    for(let i=0; i < key.length; i++){
      if(i == 0 ) query += `?${key[i]}=${value[i]}`
      else query += `&${key[i]}=${value[i]}`
    }
    return http.get(`/classification-search${query}`);
  },
  async getClassification(id){
    return http.get(`/classification/${id}`);
  },
  async updateClassification(id,data){
    return http.put(`/classification/${id}`,data)
  },
  async deleteClassification(id){
    return http.delete(`/classification/${id}`)
  }
}
