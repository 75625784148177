import axios from 'axios';
import store from '@/store';
import router from '@/router';

const instance = axios.create({
  withCredentials: true,
  baseURL: process.env.VUE_APP_API_BASE_URL,
});


instance.interceptors.response.use((response)=>{
  let enableMessage = response.config.headers.enableMessage;
  if (response.data && response.data.data && response.data.data.message){
    if (enableMessage === true){
      store.dispatch('snackbar/activateSuccessNotification',{
        message: response.data.data.message
      })
    }
  }
  return response;
}, async (error) => {
  if (error) {
    const originalRequest = error.config;
    if (
      (error.response.status === 401 || error.response.status === 419) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      if (error.config.url != '/logout'){
        await store.dispatch('auth/logout');
        return router.push({name: 'Login'});
      }
    }
    let enableMessage = error.config.headers.enableMessage;
    if (error.response.data && error.response.data && error.response.data.message){
      if (enableMessage === true){
        store.dispatch('snackbar/activateErrorNotification',{
          message: error.response.data.message
        })
      }
    }
    return Promise.reject(error);
  }
});

export default instance;
