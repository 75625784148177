<template>
  <v-card class="ma-1" style="background-color: #98BB93;" id="inquiry-content-modals">
    <v-toolbar color="primary" dark>
      <span class="text-h5 ml-3">問い合わせ内容</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="$emit('modal-close')">
        <v-icon large>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <!-- 問い合せのやり取り -->
    <v-card-text v-if="mountCompletion" class="overflow-y-auto py-2" style="max-height: 600px;">
      <template v-if="matterDetailInquiriesData.length > 0">
        <b>案件番号:&nbsp;&nbsp;&nbsp;{{matterMemoData.matterNumber}}</b>
        <div v-for="inquiryContent in matterDetailInquiriesData" :key="inquiryContent.id">
          <!-- 問合せ(受信)の場合 -->
          <div v-if="inquiryContent.receiveSendFlg === 0">
            <v-row align="end" class="px-7">
              <!-- 新規案件登録(電話対応) -->
              <v-col v-if="inquiryContent.inquiryType === 1" cols="10">
                <InquiryContentsCardTelRecode
                  :historyModalFlg="historyModalFlg"
                  :inquiryContent="inquiryContent"
                  :matter-id="matterId"
                ></InquiryContentsCardTelRecode>
              </v-col>
              <!-- 受信メールの表示 -->
              <v-col v-else-if="inquiryContent.inquiryType === 0" cols="10">
                <InquiryContentsCardReceiveSend
                  :subject="inquiryContent.subject"
                  :content="inquiryContent.content"
                ></InquiryContentsCardReceiveSend>
              </v-col>
              <v-col cols="2" class="px-0">
                <p
                  class="mb-0 text-subtitle-1"
                  style="line-height: 1;"
                >{{ getInquiryTypeName(inquiryContent.inquiryType) }}問い合せ
                </p>
                <span
                  class="mb-0 text-subtitle-1"
                  style="line-height: 1.5;"
                >{{ getDate(inquiryContent.receiveTime) }}</span>
                <span
                  class="pl-3 mb-0 text-subtitle-1"
                  style="line-height: 1.5;"
                >{{ getTime(inquiryContent.receiveTime) }}</span>
              </v-col>
            </v-row>
          </div>
          <!-- 回答(送信)の場合 -->
          <div v-else-if="inquiryContent.receiveSendFlg === 1">
            <v-row align="end" class="px-7">
              <v-col cols="2" class="px-0">
                <p
                  class="mb-0 text-subtitle-1"
                  style="line-height: 1;"
                >{{ inquiryContent.userName }}</p>
                <span
                  class="mb-0 text-subtitle-1"
                  style="line-height: 1.5;"
                >{{ getDate(inquiryContent.sendTime) }}</span>
                <span
                  class="pl-3 mb-0 text-subtitle-1"
                  style="line-height: 1.5;"
                >{{ getTime(inquiryContent.sendTime) }}</span>
              </v-col>
              <v-col cols="10" class="mt-3">
                <InquiryContentsCardReceiveSend
                  :subject="inquiryContent.subject"
                  :content="inquiryContent.content"
                ></InquiryContentsCardReceiveSend>
              </v-col>
            </v-row>
          </div>
          <!-- 対応情報の場合 -->
          <div
            v-else-if="inquiryContent.receiveSendFlg === null && inquiryContent.correspondenceInfo !== null"
          >
            <v-row align="end" class="px-7">
              <v-col cols="2" class="px-0">
                <p
                  class="mb-0 text-subtitle-1"
                  style="line-height: 1;"
                >{{ inquiryContent.userName }}
                </p>
                <span
                  class="mb-0 text-subtitle-1"
                  style="line-height: 1.5;"
                >{{ getDate(inquiryContent.createdAt) }}</span>
                <span
                  class="pl-3 mb-0 text-subtitle-1"
                  style="line-height: 1.5;"
                >{{ getTime(inquiryContent.createdAt) }}</span>
              </v-col>
              <v-col cols="10" class="mt-3">
                <InquiryContentsCardCorrespondenceInfo
                  :historyModalFlg="historyModalFlg"
                  :inquiryContent="inquiryContent"
                  :matter-id="matterId"
                ></InquiryContentsCardCorrespondenceInfo>
              </v-col>
            </v-row>
          </div>
        </div>
      </template>
      <template v-else>
        <div>
          <v-alert type="info" class="mb-0">問い合わせ内容はありません</v-alert>
        </div>
      </template>
    </v-card-text>
    <v-divider
      class="mx-4 mt-1"
      color="#BDBDBD"
      style="border-width: 1px;"
      v-show="!historyModalFlg"
    />

    <v-card class="ma-4" color="grey lighten-3">
      <CustomerInquiryContentsMemoInfo
        v-bind:matterMemoData.sync = "matterMemoData"
        :matter-id="matterId"/>
    </v-card>

      <!-- ローディング中にスピナーを表示させる -->
      <v-overlay :value="loadingCount > 0">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
  </v-card>
</template>
<script>
import InquiryContentsCardReceiveSend from './InquiryContentsCardReceiveSend.vue'
import InquiryContentsCardCorrespondenceInfo from './InquiryContentsCardCorrespondenceInfo.vue'
import InquiryContentsCardTelRecode from './InquiryContentsCardTelRecode.vue'
import CustomerInquiryContentsMemoInfo from './CustomerInquiryContentsMemoInfo'

export default {
  props: {
    matterList: Array,
    // 親コンポーネントよりmatterIdを取得
    matterId: {
      type: Number,
    },
    // 親コンポーネントよりバックエンドapi取得_案件データ(basicInfo)を取得
    matterMemoData: {
      type: [String, Number, Object]
    },
    matterDetailInquiriesData: {
      type: [String, Object, Array,Number]
    },
    matterShipmentData: {
      type: [Array]
    },
    // 問い合わせ履歴モーダル
    historyModalFlg: {
      type: Boolean,
    },
    matterCustomerData: {
      type: Object
    },
    matterProductData: {
      type: Object
    },
    shippingModal: {
      type: Boolean,
    },
    hasShippingData:{
      type: Boolean,
    },
  },
  components: {
    'InquiryContentsCardReceiveSend': InquiryContentsCardReceiveSend,
    'InquiryContentsCardCorrespondenceInfo': InquiryContentsCardCorrespondenceInfo,
    'InquiryContentsCardTelRecode': InquiryContentsCardTelRecode,
    'CustomerInquiryContentsMemoInfo': CustomerInquiryContentsMemoInfo,
  },
  data() {
    return {
      // 問い合せ種類
      inquiryType: [
        {
          id: 0,
          name: 'メール'
        },
        {
          id: 1,
          name: '電話'
        },
      ],
      // 発送履歴のデータ
      shippingHistoryData: [],
      // 「返答を入力(メール作成)」ボタンのflg
      mailCreateFlg: false,
      // 「対応情報を入力」ボタンのflg
      correspondenceInfoFlg: false,
      // 「発送履歴を入力」ボタンのflg
      shippingHistoryFlg: false,
      // mount処理完了フラグ
      mountCompletion: false,
      // loading表示用
      loadingCount: 0,
    }
  },
  methods: {
    // 問い合せもしくは返答の「日付け」or「時分秒」を返す。「０：日付」「1：時分秒」
    getDate(date) {
      const newDate = new Date(date);
      const year = newDate.getFullYear();
      const month = ("00" + (newDate.getMonth() + 1)).slice(-2);
      const day = ("00" + newDate.getDate()).slice(-2);
      return year + '/' + month + '/' + day
    },
    getTime(date) {
      const newDate = new Date(date);
      const hours = ("00" + newDate.getHours()).slice(-2);
      const minutes = ("00" + newDate.getMinutes()).slice(-2);
      const seconds = ("00" + newDate.getSeconds()).slice(-2);

      return hours + ':' + minutes + ':' + seconds
    },

    // 問い合せ種類のflgを取得し、問い合わせ名を返す
    getInquiryTypeName(inquiryTypeFlg) {
      for (let i = 0; i < this.inquiryType.length; i++) {
        if (this.inquiryType[i].id === inquiryTypeFlg) {
          return this.inquiryType[i].name
        }
      }
    },
  },
  mounted() {
    // マウント時に行う処理
    this.mountCompletion = true;
  },
}
</script>
<style>
#inquiry-content-modals .theme--light.v-input--is-disabled input, .theme--light.v-input--is-disabled textarea{
  color: black;
}
</style>
