<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <span class="text-h5 ml-3">要望事項</span>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-row class="py-2">
        <v-card-text>
          <v-row align="center" no-gutters>
            <v-col cols="2">
            <span>案件番号</span>
            </v-col>
            <v-col cols="5">
              <v-text-field
                dense
                v-model="proposalNumber"
                hide-details
                outlined
                disabled
                class="py-2"
              ></v-text-field>
            </v-col>
            <v-spacer></v-spacer>
          </v-row>
          <!-- 関連商品 -->
          <v-row align="center" no-gutters>
            <v-col cols="2">
              <span>関連商品</span>
            </v-col>
            <v-col cols="8">
              <v-text-field
                dense
                v-model="newRelatedProducts"
                outlined
                class="py-2"
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" no-gutters>
            <v-col cols="2">
              <span>内容</span>
            </v-col>
            <v-col cols="10">
              <!-- お知らせ詳細 -->
              <v-textarea
                dense
                v-model="newContent"
                no-resize
                rows="10"
                outlined
                class="py-2"
                hide-details
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-col cols="12" class="text-center">
          <v-btn rounded color="primary" class="px-12 mx-5"
                 @click="saveRequestForImprovementModal">保存
          </v-btn>
          <v-btn
            rounded
            color="warning"
            class="px-10 mx-5"
            @click="$emit('RequestForImprovementModalClose')"
          >閉じる
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import {getBackgroundColor} from '@/components/helpers/utils';

export default {
  props: {
    matterStatusData: {
      type: Object,
    },
    matterProductData: {
      type: Object,
    },
    // 親コンポーネントよりmatterIdを取得
    matterId: {
      type: Number,
    },
    // 親コンポーネントよりmatterIdを取得
    RequestForImprovementModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      proposalNumber: null,
      newContent: null,
      newRelatedProducts: null,
    };
  },
  watch:{
    RequestForImprovementModal:{
      immediate:true,
      handler(val){
        if (val){
          this.proposalNumber = this.matterStatusData.matterNumber;
          this.newRelatedProducts = this.matterProductData.productName;
          this.newContent = '';
        }
      }
    }
  },
  methods: {
    getBackgroundColor,
    async saveRequestForImprovementModal() {
      const notification = {
        title: '要望事項',
        content: '案件番号：' + this.proposalNumber + '\n内容：' + (this.newContent?this.newContent:''),
        relatedProducts: this.newRelatedProducts,
        improvementFlg: 1,
        createdAt: this.createdAt,
        updatedAt: this.updatedAt,
      };
        this.$emit('saveRequestForImprovement', notification);
    },
  },
}
</script>
<style scoped></style>
