<template>
  <v-row justify="center">
    <v-dialog
      v-model="customerMstDialog"
      persistent
      overflow-y=initial
    >
    <v-card>
        <v-card-title class="headline">{{this.dialogTitle}}</v-card-title>
        <v-card-text>
        <v-container fluid>
          <v-row>
            <v-col cols='6'>
              <v-card flat>
                <v-container fluid>
                  <v-row>
                    <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>id</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined height=5 ma-0 pa-0 hide-details dense v-model='customer_data.id'></v-text-field></v-col>
                    <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>要注意フラグ</v-col>
                    <v-col cols="3" class="flex-grow-0 flex-shrink-0">
                      <v-radio-group :readonly=readOnly v-model="customer_data.attentionFlg" row ma-0 pa-0 dense>
                        <v-radio
                          v-for="n,index in this.attentionFlgName"
                          :key="index"
                          :label=n
                          :value="index"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
<!--                     <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>お客様種別</v-col>
                    <v-col cols="3"><v-text-field outlined ma-0 pa-0 hide-details dense v-model='customer_data.customer_type_flg'></v-text-field></v-col> -->
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7 flex-grow-0 flex-shrink-0" style="margin-top: 13px;" right>お客様種別</v-col>
<!--                     <v-col cols="3"><v-text-field outlined ma-0 pa-0 hide-details dense v-model='customer_data.customer_type_flg'></v-text-field></v-col> -->
                    <v-col cols="9" class="flex-grow-0 flex-shrink-0">
                          <v-radio-group :readonly=readOnly v-model="customer_data.customerTypeFlg" row ma-0 pa-0 dense>
                          <v-radio
                            v-for="n,index in this.customerTypeFlgName"
                            :key="index"
                            :label=n
                            :value=index
                          ></v-radio>
                        </v-radio-group>
                    </v-col>
                  </v-row>

                  <v-row>
                    <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>名前</v-col>
                    <v-col cols="9"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='customer_data.lastName'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>名前（かな）</v-col>
                    <v-col cols="9"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='customer_data.lastNameKana'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>性別</v-col>
<!--                     <v-col cols="2"><v-text-field outlined ma-0 pa-0 hide-details dense v-model='customer_data.gender'></v-text-field></v-col> -->
                    <v-col cols="9">
                          <v-radio-group :readonly=readOnly v-model="customer_data.gender" row ma-0 pa-0 dense>
                          <v-radio
                            v-for="n,index in this.genderName"
                            :key="index"
                            :label=n
                            :value=index+1
                          ></v-radio>
                        </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>メールアドレス</v-col>
                    <v-col cols="4"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="actionMode!=0?emailRules:rules" v-model='customer_data.email1' placeholder="aaa@bbb.co.jp"></v-text-field></v-col>
                    <v-col cols="4"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="actionMode!=0?emailRules:rules" v-model='customer_data.email2' placeholder="yyy@bbb.com"></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>電話</v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='customer_data.phoneNumber1' placeholder="0312345678"></v-text-field></v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='customer_data.phoneNumber2' placeholder="0312345678"></v-text-field></v-col>
                    <v-col cols="1" class="text-right text-h6" style="margin-top: 10px;" right>FAX</v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='customer_data.faxNumber' placeholder="0312345678"></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>会社名</v-col>
                    <v-col cols="9"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='customer_data.companyName'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>会社名（かな）</v-col>
                    <v-col cols="9"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='customer_data.companyNameKana'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h6" style="margin-top: 10px;" right>業種名</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='customer_data.industryName'></v-text-field></v-col>
                    <v-col cols="2" class="text-right text-h6" style="margin-top: 10px;" right>部署名</v-col>
                    <v-col cols="4"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='customer_data.departmentName'></v-text-field></v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols='6' style="margin-left: 0;">
              <v-card flat>
                <v-container fluid>
                  <v-row>
                    <v-col cols="2" class="text-right text-h6" style="margin-top: 10px;" right>郵便番号</v-col>
                    <v-col cols="10"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='customer_data.postalCode' placeholder="1234567" @input="changePostalCode"></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right text-h6" style="margin-top: 10px;" right>都道府県</v-col>
                    <v-col cols="10"><v-select
                                        v-model="customer_data.prefectureName"
                                        :items="prefectureNameList"
                                        placeholder="選択してください"
                                        outlined
                                        hide-details="auto"
                                        dense
                                    ></v-select></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right text-h6" style="margin-top: 10px;" right>住所</v-col>
                    <v-col cols="10"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='customer_data.address'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right text-h6" style="margin-top: 10px;" right>購入先</v-col>
                    <v-col cols="10"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='customer_data.retailerName'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right text-h6" style="margin-top: 10px;" right>備考</v-col>
                    <v-col cols="10" md4 ><v-textarea :readonly=readOnly dense outlined no-resize :rules="rules" v-model='customer_data.remarks'></v-textarea></v-col>
<!--                     <v-col cols="9"><v-text-field outlined ma-0 pa-0 hide-details dense v-model='customer_data.remarks'></v-text-field></v-col> -->
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right text-h6" style="margin-top: 10px;" right>作成者</v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense v-model='customer_data.createdBy'></v-text-field></v-col>
                    <v-col cols="2" class="text-right text-h6" style="margin-top: 10px;" right>更新者</v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense v-model='customer_data.updatedBy'></v-text-field></v-col>
                    <v-col cols="2" class="text-right text-h6" style="margin-top: 10px;" right>削除者</v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense v-model='customer_data.deletedBy'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right text-h6" style="margin-top: 10px;" right>作成日時</v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details style='font-size: 14px' dense v-model='customer_data.createdAt' placeholder="YYYY/MM/DD"></v-text-field></v-col>
                    <v-col cols="2" class="text-right text-h6" style="margin-top: 10px;" right>更新日時</v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details style='font-size: 14px' dense v-model='customer_data.updatedAt' placeholder="YYYY/MM/DD"></v-text-field></v-col>
                    <v-col cols="2" class="text-right text-h6" style="margin-top: 10px;" right>削除日時</v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details style='font-size: 14px' dense v-model='customer_data.deletedAt' placeholder="YYYY/MM/DD"></v-text-field></v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template v-if='this.actionMode!=2'>
            <v-btn color="primary" @click="cancelEvent" style="width: 120px;">キャンセル</v-btn>
          </template>
          <template v-if='this.actionMode==2'>
            <v-btn color="primary" :disabled="!isValid" @click="updateCustomer" style="width: 120px;">保存</v-btn>
            <v-btn color="primary" @click="deleteCustomer" style="width: 120px;">削除</v-btn>
          </template>
          <v-btn color="primary" :disabled="!isValid && actionMode==1" @click="okEvent" style="width: 120px;">{{okButtonName[actionMode]}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import customerService from '@/services/customerService.js';
  import postal_code from "japan-postal-code";
  export default {
    name: 'masterCustomerDialog',
    props: {
      dialogTitle: String,
      customerMstDialog: Boolean,
      actionMode: Number, /* 0:検索, 1:登録, 2:表示 */
      dialogItems: Object,
      readOnly: Boolean
    },
    data: function () {
      return {
        genderName: ['男', '女', 'その他'],
        attentionFlgName: ['注意', '要注意','なし'],
        customerTypeFlgName: ['個人消費者', '企業消費者', '販売店', '支店', 'その他'],
        prefectureNameList: ['北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県',
                '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県',
                '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県',
                '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'],
        empty_array: [],
        okButtonName: ['検索', '登録', '閉じる'],
        dialog: false,
        select_db: -1,
        customer_data: {},
        rules: [v => (!v || v.length < 255) || "Max 255 characters"],
        emailRules: [v => (!v || (v.length < 256  && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) )) || "Invalid email"]
      }
    },
    computed: {
      isValid(){
        return (!this.customer_data.lastName || !this.customer_data.lastName.length < 256)
         && (!this.customer_data.lastNameKana || this.customer_data.lastNameKana.length < 256)
         && (!this.customer_data.email1 || (this.customer_data.email1.length < 256 && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.customer_data.email1)))
         && (!this.customer_data.email2 || (this.customer_data.email2.length < 256 && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.customer_data.email2)))
         && (!this.customer_data.phoneNumber1 || this.customer_data.phoneNumber1.length < 256)
         && (!this.customer_data.phoneNumber2 || this.customer_data.phoneNumber2.length < 256)
         && (!this.customer_data.companyName || this.customer_data.companyName.length < 256)
         && (!this.customer_data.companyNameKana || this.customer_data.companyNameKana.length < 256)
         && (!this.customer_data.industryName || this.customer_data.industryName.length < 256)
         && (!this.customer_data.departmentName || this.customer_data.departmentName.length < 256)
         && (!this.customer_data.perfectureName || this.customer_data.perfectureName.length < 256)
         && (!this.customer_data.postalCode || this.customer_data.postalCode.length < 256)
         && (!this.customer_data.faxNumber || this.customer_data.faxNumber.length < 256)
         && (!this.customer_data.address || this.customer_data.address.length < 256)
         && (!this.customer_data.retailerName || this.customer_data.retailerName.length < 256)
         && (!this.customer_data.remarks || this.customer_data.remarks.length < 256)
      }
    },
    methods: {
      cancelEvent() {
        this.$emit('result', {'res': false, 'message': []})
      },
      reloadData(){
        this.$emit('reload')
      },
      async okEvent() {
        if(this.actionMode == 1){
          await customerService.createCustomer(this.customer_data)
          this.reloadData()
        }
        if(this.actionMode == 0 ){
          // const res = await customerService.searchForCustomer(this.customer_data)
          this.$emit('result', {'res': true, 'message': this.customer_data})
        }
        this.cancelEvent()
      },
      changePostalCode(){
        this.customer_data.prefectureName = '';
        this.customer_data.address = '';
        let root = this;
        postal_code.get(this.customer_data.postalCode, function(address) {
          if (address){
            if (address.prefecture){
              root.customer_data.prefectureName = address.prefecture;
            } else{
              root.customer_data.prefectureName = '';
            }
            let ad = '';
            if (address.city) ad = address.city;
            if (address.area) ad += address.area;
            if (address.street) ad += address.street;
            root.customer_data.address = ad;
          }
        });
      },
      async deleteCustomer(){
        await customerService.deleteCustomer(this.customer_data.id)
        this.cancelEvent()
        this.reloadData()
      },
      async updateCustomer(){
        await customerService.updateCustomer(this.customer_data.id, this.customer_data)
        this.cancelEvent()
        this.reloadData()
      }
    },
    watch: {
      customerMstDialog: function (newCustomerMstDialog, oldCustomerMstDialog) {
        oldCustomerMstDialog == oldCustomerMstDialog
        if(newCustomerMstDialog == true){
          this.customer_data = JSON.parse(JSON.stringify(this.dialogItems))
        }
      },
      actionMode(val){
        if(val == 1){
          this.customer_data.gender = 0;
          this.customer_data.customerTypeFlg = 0
          this.customer_data.attentionFlg = 0
        }
      }
    }
  }
</script>
<style>
.v-text-field input {
    font-size: 1.0em;
    height: 1.1em;
    margin: 0.1em;
    padding: 0.1em;
  }
  .v-col {
    margin: 0.1em;
    padding: 0.1em;
  }
  .v-row {
    margin: 0.1em;
    padding: 0.1em;
  }

</style>
