import http from '@/httpCommon.js';

export default {
  async createCustomer(data) {
    return http.post(`/customers`, data);
  },
  async importCustomer(data,headers){
    return http.post('/customers/import-csv',data,headers);
  },
  async updateCustomer(customerId, customer) {
    return http.put(`/customers/${customerId}`, customer);
  },

  async deleteCustomer(customerId) {
    return http.delete(`/customers/${customerId}`);
  },

  async getCustomer(customerId) {
    return http.get(`/customers/${customerId}`);
  },
  async searchForCustomer(data) {
    let query = ''
    const key = Object.keys(data)
    const value = Object.values(data)

    for(let i=0; i < key.length; i++){
      if(i == 0 ) query += `?${key[i]}=${value[i]}`
      else query += `&${key[i]}=${value[i]}`
    }
    return http.get(`/customer-search${query}`);
  },
};
