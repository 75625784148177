<template>
  <v-row justify="center">
    <v-dialog
      v-model="productionMstDialog"
      persistent
      overflow-y=initial
    >
    <v-card>
      <v-card class="ma-3">
        <v-card-title class="headline" style='padding-top: 3px;padding-bottom: 7px;'>{{this.dialogTitle}}</v-card-title>
        <v-container fluid>
          <v-row>
            <v-col cols='6'>
              <v-card flat>
                <v-container fluid>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" right>id</v-col>
                    <v-col cols="9"><v-text-field :readonly=readOnly outlined  hide-details dense v-model='product_data.id'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" right>商品/サービス種別</v-col>
                    <v-col cols="9"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="rules" v-model='product_data.productType'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" ma-1 pa-1 right>商品コード</v-col>
                    <v-col cols="9"><v-text-field :readonly=readOnly outlined ma-1 pa-1 hide-details dense :rules="rules" v-model='product_data.productCode'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" ma-1 pa-1 right>品番</v-col>
                    <v-col cols="9"><v-text-field :readonly=readOnly outlined ma-1 pa-1 hide-details dense :rules="rules" v-model='product_data.productNumber'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" style="margin-top: 8px;" right>商品名</v-col>
                    <v-col cols="9"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="rules" v-model='product_data.productName'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" style="margin-top: 8px;" right>商品名(カナ)</v-col>
                    <v-col cols="9"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="rules" v-model='product_data.productNameKana'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7">大分類</v-col>
                    <v-col cols="4"><v-text-field :readonly=readOnly outlined hide-details dense :rules="rules" v-model='product_data.unifiedClassificationL'></v-text-field></v-col>
                    <v-col cols="1" class="text-right text-h7" right>中分類</v-col>
                    <v-col cols="4"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="rules" v-model='product_data.unifiedClassificationM'></v-text-field></v-col>
                    <v-col cols="3" class="text-right text-h7" right>小分類</v-col>
                    <v-col cols="4"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="rules" v-model='product_data.unifiedClassificationS'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" right>単品バーコード</v-col>
                    <v-col cols="9"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="rules" v-model='product_data.barcode'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" right>小売価格</v-col>
                    <v-col cols="9"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="numberRules" v-model='product_data.retailPrice'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" right>色名</v-col>
                    <v-col cols="9"><v-text-field :readonly=readOnly outlined  hide-details dense v-model='product_data.colorName'></v-text-field></v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols='6' style="margin-left: 0;">
              <v-card  flat>
                <v-container fluid>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7">幅</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="numberRules" v-model='product_data.sizeWidth'></v-text-field></v-col>
                    <v-col cols="3" class="text-right text-h7" right>奥行き</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="numberRules" v-model='product_data.sizeDepth'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" right>高さ</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="numberRules" v-model='product_data.sizeHeight'></v-text-field></v-col>
                    <v-col cols="3" class="text-right text-h7" right>重量</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="numberRules" v-model='product_data.sizeWeight'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" right>中箱１入数</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="numberRules" v-model='product_data.middleBoxQuantity'></v-text-field></v-col>
                    <v-col cols="3" class="text-right text-h7" right>梱箱入数</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="numberRules" v-model='product_data.packingBoxQuantity'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" right>代替品番</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="rules" v-model='product_data.alternativeProductNumber'></v-text-field></v-col>
                    <v-col cols="3" class="text-right text-h7" right>発売年月日</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense v-model='product_data.releaseDate' placeholder="YYYY/MM/DD"></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" ma-1 pa-1 right>オープン価格区分</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="rules" v-model='product_data.openPriceType'></v-text-field></v-col>
                    <v-col cols="3" class="text-right text-h7" right>参考価格A</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="numberRules" v-model='product_data.referencePriceA'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" right>カタログ掲載終了</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense v-model='product_data.catalogEndDate' placeholder="YYYY/MM/DD"></v-text-field></v-col>
                    <v-col cols="3" class="text-right text-h7" right>環境対応フラグ</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense v-model='product_data.envSupportFlg'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" right>エコマークフラグ</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense v-model='product_data.ecoMarkFlg'></v-text-field></v-col>
                    <v-col cols="3" class="text-right text-h7" right>グリーン購入法</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense v-model='product_data.greenPurchaseLaw'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="3" class="text-right text-h7" right>エコマーク再生樹脂比率</v-col>
                    <v-col cols="3"><v-text-field :readonly=readOnly outlined  hide-details dense :rules="rules" v-model='product_data.ecoMarkRate'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right text-h7" right>作成者</v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined  hide-details dense v-model='product_data.createdBy'></v-text-field></v-col>
                    <v-col cols="2" class="text-right text-h7" right>更新者</v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined  hide-details dense v-model='product_data.updatedBy'></v-text-field></v-col>
                    <v-col cols="2" class="text-right text-h7" right>削除者</v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined  hide-details dense v-model='product_data.deletedBy'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="2" class="text-right text-h7" right>作成日時</v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined  hide-details dense v-model='product_data.createdAt' placeholder="YYYY/MM/DD"></v-text-field></v-col>
                    <v-col cols="2" class="text-right text-h7" right>更新日時</v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined  hide-details dense v-model='product_data.updatedAt' placeholder="YYYY/MM/DD"></v-text-field></v-col>
                    <v-col cols="2" class="text-right text-h7" right>削除日時</v-col>
                    <v-col cols="2"><v-text-field :readonly=readOnly outlined  hide-details dense v-model='product_data.deletedAt' placeholder="YYYY/MM/DD"></v-text-field></v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card class="ma-3" elevation-24 dense>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template v-if='this.actionMode!=2'>
            <v-btn color="primary" @click="cancelEvent" style="width: 120px;">キャンセル</v-btn>
          </template>
           <template v-if='this.actionMode==2'>
            <v-btn color="primary" :disabled="!isValid" @click="updateProduct" style="width: 120px;">保存</v-btn>
            <v-btn color="primary" @click="deleteProduct" style="width: 120px;">削除</v-btn>
          </template>
          <v-btn color="primary" :disabled="!isValid && actionMode==1" @click="okEvent" style="width: 120px;">{{okButtonName[actionMode]}}</v-btn>
        </v-card-actions>
      </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import productService from '@/services/productService.js';

  export default {
    name: 'masterProductionDialog',
    props: {
      dialogTitle: String,
      productionMstDialog: Boolean,
      actionMode: Number, /* 0:検索, 1:追加 */
      dialogItems: Object,
      readOnly: Boolean
    },
    data: function () {
      return {
        okButtonName: ['検索', '追加', '閉じる'],
        dialog: false,
        product_data: {},
        rules: [v => (v.length < 256 || !v ) || "Max 255 characters"],
        numberRules: [v => (/^\d+$/.test(v) || !v) || "Integer type"]
      }
    },
    computed: {
      isValid(){
        return (!this.product_data.productCode || this.product_data.productCode.length < 256)
         && (!this.product_data.productType || this.product_data.productType.length < 256)
         && (!this.product_data.productName || this.product_data.productName.length < 256)
         && (!this.product_data.productNumber || this.product_data.productNumber.length < 256)
         && (!this.product_data.productNameKana || this.product_data.productNameKana.length < 256)
         && (!this.product_data.alternativeProductNumber || this.product_data.alternativeProductNumber.length < 256)
         && (!this.product_data.ecoMarkRate || this.product_data.ecoMarkRate.length < 256)
         && (!this.product_data.unifiedClassificationL || this.product_data.unifiedClassificationL.length < 256)
         && (!this.product_data.unifiedClassificationM || this.product_data.unifiedClassificationM.length < 256)
         && (!this.product_data.unifiedClassificationS || this.product_data.unifiedClassificationS.length < 256)
         && (!this.product_data.barcode || this.product_data.barcode.length < 256)
         && (!this.product_data.sizeWidth || /^\d+$/.test(this.product_data.sizeWidth))
         && (!this.product_data.sizeHeight || /^\d+$/.test(this.product_data.sizeHeight))
         && (!this.product_data.sizeDepth || /^\d+$/.test(this.product_data.sizeDepth))
         && (!this.product_data.sizeWeight || /^\d+$/.test(this.product_data.sizeWeight))
         && (!this.product_data.middleBoxQuantity || /^\d+$/.test(this.product_data.middleBoxQuantity))
         && (!this.product_data.packingBoxQuantity || /^\d+$/.test(this.product_data.packingBoxQuantity))
         && (!this.product_data.retailPrice || /^\d+$/.test(this.product_data.retailPrice))
         && (!this.product_data.referencePriceA || /^\d+$/.test(this.product_data.referencePriceA))
      }
    },
    methods: {
      cancelEvent() {
        this.$emit('result', {'res': false, 'message': []})
      },
      reloadData(){
        this.$emit('reload')
      },
      async okEvent() {
        if(this.actionMode == 1){
          await productService.createProduct(this.product_data)
          this.reloadData()
        }
        if(this.actionMode == 0 ){
          // const res = await productService.searchForProduct(this.product_data)
          this.$emit('result', {'res': true, 'message': this.product_data})
        }
        this.cancelEvent()
      },
      async deleteProduct(){
        await productService.deleteProduct(this.product_data.id)
        this.cancelEvent()
        this.reloadData()
      },
      async updateProduct(){
        await productService.updateProduct(this.product_data.id, this.product_data)
        this.cancelEvent()
        this.reloadData()
      }
    },
    watch: {
      productionMstDialog: function (newProductionMstDialog, oldProductionMstDialog) {
        oldProductionMstDialog == oldProductionMstDialog
        if(newProductionMstDialog == true){
          this.product_data = JSON.parse(JSON.stringify(this.dialogItems))
        }
      }
    }
  }
</script>
<style>
.container{
  margin: 0px;
     padding:0px;
  }
</style>
