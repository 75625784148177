<template>
  <v-snackbar
    v-model="snackbar"
    :color="color"
    :timeout="timeout"
    bottom
    right
    multi-line
    class="font-weight-bold"
  >
    {{ message }}
  </v-snackbar>
</template>

<script>
export default {
  data() {
    return {
      timeout: 3000,
      snackbar: false,
      message: '',
      color: ''
    }
  },
  computed: {
    notification() {
      return this.$store.getters['snackbar/notification']
    }
  },
  watch: {
    notification(val) {
      this.apply(val)
    }
  },
  created() {
    const val = this.$store.getters['snackbar/notification']
    this.apply(val)
  },
  methods: {
    closeSnackbar() {
      this.$store.dispatch('snackbar/deactivateNotification')
    },
    apply(val) {
      this.message = val.message
      this.color = val.color
      this.snackbar = val.activate
      if (this.snackbar) {
        setTimeout(this.closeSnackbar, this.timeout)
      }
    }
  }
}
</script>
<style>
.v-snack--multi-line .v-snack__wrapper{
    min-height: max-content !important;
    min-width: max-content !important;
}
</style>