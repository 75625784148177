import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import DashBoard from '../views/DashBoard.vue'
import NotificationList from '../views/NotificationList.vue'
import AllMatterSearch from '@/views/AllMatterSearch.vue'
import MasterDataManage from '../views/MasterDataManage.vue'
import InquiryHandle from '../views/InquiryHandle.vue'
import store from '@/store';
import Download from "@/views/Download";
import Upload from "@/views/Upload.vue";
import WebcsMatterListSearch from "@/views/WebcsMatterListSearch.vue";
import SIDMatterListSearch from "@/views/SIDMatterListSearch.vue";
import OShopMatterListSearch from "@/views/OShopMatterListSearch.vue";

const NotFound = { template: '<div>このページは存在しません</div>' }

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
    meta: { guest: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { guest: true },
  },
  {
    path: '/dashboard',
    name: 'DashBoard',
    component: DashBoard,
    meta: { requiresAuth: true },
  },
  {
    path: '/notifications',
    name: 'Notifications',
    component: NotificationList,
    meta: { requiresAuth: true, requiresAdminOrUserAuth: true  },
  },
  {
    path: '/all-matter-search',
    name: 'AllMatterSearch',
    component: AllMatterSearch,
    meta: { requiresAuth: true },
  },
  {
    path: '/upload',
    name: 'Upload',
    component: Upload,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: '/download',
    name: 'Download',
    component: Download,
    meta: { requiresAuth: true },
  },
  {
    path: '/mastermng',
    name: 'MasterDataManage',
    component: MasterDataManage,
    meta: { requiresAuth: true, requiresAdminOrUserAuth: true },
  },
  {
    path: '/webcs-matter-list',
    name: 'webcs-matter-list',
    component: WebcsMatterListSearch,
    meta: { requiresAuth: true, requiresAdminOrUserAuth: true },
  },
  {
    path: '/oshop-matter-list',
    name: 'oshop-matter-list',
    component: OShopMatterListSearch,
    meta: { requiresAuth: true, requiresAdminOrUserAuth: true },
  },
  {
    path: '/sid-matter-list',
    name: 'sid-matter-list',
    component: SIDMatterListSearch,
    meta: { requiresAuth: true, requiresAdminOrUserAuth: true },
  },
  {
    path: '/inquiryhandle/:id',
    name: 'InquiryHandle',
    component: InquiryHandle,
    meta: { requiresAuth: true },
    // props: (route) => ({matterId: Number(route.params.id)}),
  },

  { path: '*', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // ダッシュボード画面などで認証済みでない場合
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !store.getters['auth/isAuthenticated']
  ) {
    next('/login');
    return;
  }

  // ログイン画面などで認証済みの場合
  if (
    to.matched.some((record) => record.meta.guest) &&
    store.getters['auth/isAuthenticated']
  ) {
    next('/dashboard');
    return;
  }
  // can access to mastermng
  if((to.matched.some((record)=>record.meta.requiresAdminOrUserAuth) && store.state.auth.user.isViewer) ||
    (to.matched.some((record)=>record.meta.requiresAdminOrUserAuth) && store.state.auth.user.isGuest))
  {
    next('/dashboard');
  }

  if((to.matched.some((record)=>record.meta.requiresAdmin) && !store.state.auth.user.isAdmin)) {
    next('/dashboard');
  }
  next();
})

export default router
