<template>
  <div>
    <v-card class="ma-5" elevation-10 style="background-color: #FFFFFF">
      <v-card-text class="pt-2 pb-2" style="font-size: 15px"><b>{{ pTitle }}</b></v-card-text>
      <v-divider></v-divider>
      <v-col cols=12>
        <v-row>
        <DashOnedata class="ma-2" :p-title="pSubtitle[0]" :p-count="pCount[0]" :p-bgcolor="pBgcolor[0]" :p-event-key="pEventKey[0]" :server-flag="serverFlag"/>
        <DashOnedata class="ma-2" :p-title="pSubtitle[1]" :p-count="pCount[1]" :p-bgcolor="pBgcolor[1]" :p-event-key="pEventKey[1]" :server-flag="serverFlag"/>
        <DashOnedata class="ma-2" :p-title="pSubtitle[2]" :p-count="pCount[2]" :p-bgcolor="pBgcolor[2]" :p-event-key="pEventKey[2]" :server-flag="serverFlag"/>
        <DashOnedata class="ma-2" :p-title="pSubtitle[3]" :p-count="pCount[3]" :p-bgcolor="pBgcolor[3]" :p-event-key="pEventKey[3]" :server-flag="serverFlag"/>
        <DashOnedata class="ma-2" :p-title="pSubtitle[4]" :p-count="pCount[4]" :p-bgcolor="pBgcolor[4]" :p-event-key="pEventKey[4]" :server-flag="serverFlag"/>
        <DashOnedata class="ma-2" :p-title="pSubtitle[5]" :p-count="pCount[5]" :p-bgcolor="pBgcolor[5]" :p-event-key="pEventKey[5]" :server-flag="serverFlag"/>
        <DashOnedata class="ma-2" v-if="pSubtitle[6]" :p-title="pSubtitle[6]" :p-count="pCount[6]" :p-bgcolor="pBgcolor[6]" :p-event-key="pEventKey[6]" :server-flag="serverFlag"/>
        </v-row>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import DashOnedata from '@/components/DashOneData.vue'

export default {
  name: 'dash_status',
  data: function () {
    return {
    }
  },
  props: {
    pTitle: String,
    pSubtitle: Array,
    pCount: Array,
    pBgcolor: Array,
    pEventKey: Array,
    serverFlag: Number
  },
  components: {
    DashOnedata
  }
}
</script>
