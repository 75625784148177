const state = {
  notification: {
    activate: false,
    message: '',
    color: '',
  },
};

const getters = {
  notification: (state) => {
    return state.notification;
  },
};

const mutations = {
  setNotification(state, data) {
    state.notification = {
      activate: data.activate,
      message: data.message,
      color: data.color,
    };
  },
};

const actions = {
  activateNotification({ commit }, data) {
    commit('setNotification', {
      activate: true,
      message: data.message,
      color: data.color,
    });
  },

  // INFO NOTIFICATION
  activateSuccessNotification({ commit }, data) {
    commit('setNotification', {
      activate: true,
      message: data.message,
      color: 'success',
    });
  },

  // ERROR NOTIFICATION
  activateErrorNotification({ commit }, data) {
    commit('setNotification', {
      activate: true,
      message: data.message,
      color: 'error',
    });
  },

  // CLEAR NOTIFICATION
  deactivateNotification({ commit }) {
    commit('setNotification', { activate: false, message: '', color: '' });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
