<template>
  <v-card>
    <v-toolbar color="yellow deep-orange accent-2" dark>
      <span class="text-h5">【確認】入力されていない項目があります。問い合わせを閉じてもよろしいでしょうか？</span>
      <!-- <v-spacer></v-spacer>
      <v-btn icon @click="$emit('modal-close-button-click')">
          <v-icon large>mdi-close</v-icon>
      </v-btn> -->
    </v-toolbar>
    <!-- <v-card-title class="text-h5">保存されていない変更項目があります。問い合せを閉じてもよろしいでしょうか？</v-card-title> -->
    <v-divider class="mx-4 mb-3"></v-divider>
    <v-card-text>
      <v-row>
        <!-- 状態パネルの保存されていない変更項目 -->
        <v-col :cols="12">
          <v-card color="grey lighten-2" height="auto" class="mt-5 pb-2">
            <v-card-title class="pb-0 pt-2 text-h6">入力必須項目</v-card-title>
            <v-card class="my-1 mx-1">
              <div
                v-for="(items,index) in this.nullStatusDataList"
                :key="index"
              >
                <p class="px-4 py-2 text-h6 font-weight-bold">{{ items.title }} : <span class="red--text">未記入</span></p>
              </div>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
    <v-divider class="mx-4"></v-divider>
    <v-card-actions style="height:80px;">
      <v-row align="center" justify="center" style="height:80px;">
        <v-btn rounded color="warning" x-large class="px-12 mr-15" @click="$emit('modal-close-button-click')">いいえ
        </v-btn>
        <v-btn rounded color="primary" x-large class="px-14 ml-15" @click="$emit('matter-close-button-click')">はい
        </v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  props: {
    // モーダルに表示する変更項目リスト(状態パネル)
    nullStatusDataList: {
      type: Array
    },
  },
  data() {
    return {}
  },
  computed: {},
  methods: {},
  watch: {},
  mounted() {
  },
}
</script>

<style scoped>
/* 検索結果が「０」の場合に表示するテキストのフォントサイズ */
::v-deep .v-data-table__empty-wrapper > td {
  font-size: 20px !important;
}
</style>
