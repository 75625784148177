<template>
  <v-card>
    <v-toolbar color="lime darken-3" dark>
      <span class="text-h5 ml-3">対応メモ</span>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-row class="py-2">
        <v-col cols="12">
          <v-textarea
            v-model="memo"
            outlined
            auto-grow
            solo
            flat
            hide-details
            style="padding-top: 15px"
            row-height="80"
            placeholder="内容を入力してください"
            :background-color="getBackgroundColor(memo, matterStatusData.oldMemo)"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn rounded color="primary" class="px-12 mx-5"
                 @click="saveMemoInfo">保存
          </v-btn>
          <v-btn
            rounded
            color="warning"
            class="px-10 mx-5"
            @click="$emit('InquiryContentsMemoInfoModalClose')"
          >閉じる
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import {getBackgroundColor} from '@/components/helpers/utils';

export default {
  props: {
    matterStatusData: {
      type: Object,
    },
    // 親コンポーネントよりmatterIdを取得
    matterId: {
      type: Number,
    },
    InquiryContentsMemoInfoModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      memo: ''
    }
  },
  watch: {
    InquiryContentsMemoInfoModal:{
      immediate:true,
      handler(val){
        if (val){
          this.memo = this.matterStatusData.memo;
        }
      }
    }
  },
  methods: {
    getBackgroundColor,
    async saveMemoInfo(){
        let masterStatus = Object.assign({}, this.matterStatusData)
        masterStatus.memo = this.memo
        this.$emit('saveMemo', this.matterId, masterStatus)
    },
  },
}
</script>
<style scoped></style>
