// 引数で渡した二つの値が等しくなかったら背景色を返す
export const getBackgroundColor = (value1, value2)  => {
  if (value1 !== value2) {
    if (value1 === null && value2 === '') {
      return null;
    } else if (value1 === '' && value2 === null) {
      return null;
    } else {
      return "red lighten-5";
    }
  }
}

export const statusFlg = (status_flg) => {
  if (status_flg === 0) {
    return '未対応';
  } else if (status_flg === 1) {
    return '対応中';
  } else if (status_flg === 2) {
    return '返送待ち';
  } else if (status_flg === 3) {
    return '商品手配中';
  } else if (status_flg === 4) {
    return '対応済み';
  } else {
    return '未達完了';
  }
}

export const category = (category) => {
  if (category === 1) {
    return 'お問合せ';
  } else if (category === 2) {
    return '苦情';
  } else if (category === 3) {
    return 'ご意見';
  } else if (category === 4){
    return 'その他';
  } else return '未達完了';
}

export const gender  = (gender) => {
  if (gender === 1) {
    return '男';
  } else if (gender === 2) {
    return '女';
  } else if (gender === 3){
    return 'その他';
  } else return '未達完了';
}

export const customerTypeFlg = (customer_type_flg) => {
  if (customer_type_flg === 0) {
    return '個人消費者';
  } else if (customer_type_flg === 1) {
    return '企業消費者';
  } else if (customer_type_flg === 2) {
    return '販売店';
  } else if (customer_type_flg === 3) {
    return '支店';
  } else if (customer_type_flg === 4){
    return 'その他';
  } else return '未達完了';
}

export const inquiryType = (inquiry_type) => {
  if (inquiry_type === 0) {
    return 'メール';
  } else if (inquiry_type === 1) {
    return '電話';
  } else if (inquiry_type === 2) {
    return '郵送';
  } else if (inquiry_type === 3) {
    return '来社';
  } else if (inquiry_type === 4) {
    return '他部署転送';
  } else if (inquiry_type === 5){
    return 'その他';
  } else return '未達完了';
}

export const dealType = (deal_type) => {
  if (deal_type === 0) {
    return 'メール';
  } else if (deal_type === 1) {
    return '電話';
  } else if (deal_type === 2) {
    return '郵送';
  } else if (deal_type === 3) {
    return '来社';
  } else if (deal_type === 4) {
    return '他部署依頼';
  } else if (deal_type === 5){
    return 'その他';
  } else return '未達完了';
}

export const timeStampFormat = (datetime) => {
    const newDate = new Date(datetime);
    const year = newDate.getFullYear();
    const month = ("00" + (newDate.getMonth() + 1)).slice(-2);
    const day = ("00" + newDate.getDate()).slice(-2);
    const hour = ("00" + newDate.getHours()).slice(-2);
    const minutes = ("00" + newDate.getMinutes()).slice(-2);


  return year + '/' + month + '/' + day + ' ' + hour + ':' + minutes;
}
