import http from "@/httpCommon.js";

export default {

  async getUserSettings(userId){
    return http.get(`/user-settings/${userId}`);
  },

  async updateUserSettings(userId,data){
    return http.put(`/user-settings/${userId}`,data);
  },

  async updateMatterSettings(data){
    return http.put(`/matter-settings`,data);
  },
  async updateMatterSettingOrders(data){
    return http.put(`/matter-setting-orders`,data);
  },
  async updateDisplaySettingOrders(userId,data){
    return http.put(`/display-setting-orders/${userId}`,data);
  },
  async updateMyUserSettings(data){
    return http.put(`/my-user-settings`,data);
  },
  async getMyUserSettings(){
    return http.get(`/my-user-settings`);
  }
}
