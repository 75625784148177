<template>
    <v-card>
        <v-card-title>
            <v-row align="center">
                <v-col cols="auto" class="py-1">
                    <span class="text-h6">履歴検索結果</span>
                    <span
                        class="text-h6 ml-5"
                    >「検索対象 ： {{ historySearchTargetList[historySearchFlg] }} 」「キーワード ： {{ historySearchKeyword }} 」</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-btn
                    v-if="historyTabFlg"
                    rounded
                    small
                    color="red lighten-1"
                    class="px-5 mr-15 text-subtitle-1 white--text"
                    @click="$emit('tab-delete-button-click', historySearchFlg, historySearchKeyword)"
                >タブを削除</v-btn>
                <v-btn
                    v-else
                    rounded
                    small
                    color="primary"
                    class="px-5 mr-15 text-subtitle-1"
                    @click="tabAddHistory"
                >タブとして保存</v-btn>
                <v-btn
                    rounded
                    small
                    color="warning"
                    class="px-5 mr-2 text-subtitle-1"
                    @click="$emit('modal-close-button-click')"
                >閉じる</v-btn>
            </v-row>
        </v-card-title>
        <v-divider class="mx-4 mt-1" color="#BDBDBD" />
        <v-card-text class="pa-0">
            <!-- 問い合せ一覧テーブル表示 -->
            <MatterListSearchTable
                :matter-list="matterList"
                :item-count="itemCount"
                inquiry-history-flg="true"
                @inquiry-history-matter="matterCardOpen($event)"
            />
            <!-- 案件画面 -->
            <v-card v-if="matterCardFlg" class="grey lighten-2">
                <InquiryStatus
                    v-if="getMatterCompletion"
                    ref="inquiryStatus"
                    :matterId="matterId"
                    :readonlyFlg="readonlyFlg"
                    v-bind:matterStatusData.sync="matterStatusData"
                    historyModalFlg="true"
                    @nd-display-tel-search="getNdDisplayTelSearch"
                />
                <CustomerInformation
                    v-if="getMatterCompletion"
                    ref="customerInformation"
                    :matterId="matterId"
                    :ndDisplayTelSearch="ndDisplayTel"
                    :readonlyFlg="readonlyFlg"
                    v-bind:matterCustomerData.sync="matterCustomerData"
                    historyModalFlg="true"
                />
                <ProductInformation
                    v-if="getMatterCompletion"
                    ref="productInformation"
                    :matterId="matterId"
                    :readonlyFlg="readonlyFlg"
                    v-bind:matterProductsData.sync="matterProductsData"
                    historyModalFlg="true"
                />
                <InquiryContents
                    v-if="getMatterCompletion"
                    :matterId="matterId"
                    historyModalFlg="true"
                />
            </v-card>
        </v-card-text>
        <!-- ローディング中にスピナーを表示させる -->
        <v-overlay :value="loadingCount > 0">
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>
    </v-card>
</template>

<script>
import MatterListSearchTable from '@/components/parts/MatterListSearchTable.vue';
import InquiryStatus from '@/components/parts/InquiryStatus.vue'
import CustomerInformation from '@/components/parts/CustomerInformation.vue'
import ProductInformation from '@/components/parts/ProductInformation.vue'
import InquiryContents from '@/components/parts/InquiryContents.vue'
import getMatterService from '@/services/getMatterService.js';

export default {
    components: {
        MatterListSearchTable,
        InquiryStatus,
        CustomerInformation,
        ProductInformation,
        InquiryContents,
    },
    props: {
        // テーブルに表示するデータ(バックエンドから取得)
        historySearchFlg: Number,
        historySearchKeyword: String,
        historyTabFlg: Boolean,
    },
    data() {
        return {
          ndDisplayTel: null,
          // 検索対象のアイコンリスト
            historySearchIconList: {
                1: 'mdi-account-multiple',
                2: 'mdi-grease-pencil',
                3: 'mdi-forum-outline'
            },
            historySearchTargetList: {
                1: '顧客',
                2: '商品',
                3: 'お問い合わせ'
            },
            // モーダルに表示するテーブル行数
            itemCount: 14,
            // 案件詳細を表示するかテーブルのみか
            matterCardFlg: false,
            // 問い合わせ履歴モーダルの場合は、案件をReadonlyにする
            readonlyFlg: true,
            // 案件取得処理完了フラグ
            getMatterCompletion: false,
            // loading時間
            loadingCount: 0,

            // 取得した案件データを格納
            matterId: 0,
            matterStatusData: '',
            matterCustomerData: '',
            matterProductsData: '',
            matterInquiriesData: [],

            // 問い合わせ履歴取得APIより取得予定の案件データ(テストデータ)
            // テーブルに渡す案件リスト
            matterList: [
                {
                    "matterId": 2,
                    "matterNumber": "220113-000002",
                    "idPerDate": 2,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": 5,
                    "status": 0,
                    "customerFirstName": null,
                    "customerLastName": null,
                    "customerCompany": null,
                    "customerPhoneNum1": null,
                    "customerPhoneNum2": null,
                    "matterTitle": "title2",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-01-13 06:35:31",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": null,
                    "matterUrgency":null
                },
                {
                    "matterId": 30,
                    "matterNumber": "220113-000030",
                    "idPerDate": 30,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": null,
                    "status": 0,
                    "customerFirstName": null,
                    "customerLastName": null,
                    "customerCompany": null,
                    "customerPhoneNum1": null,
                    "customerPhoneNum2": null,
                    "matterTitle": "title30",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-01-14 01:13:51",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": null,
                    "matterUrgency":null
                },
                {
                    "matterId": 29,
                    "matterNumber": "220113-000029",
                    "idPerDate": 29,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": null,
                    "status": 0,
                    "customerFirstName": null,
                    "customerLastName": null,
                    "customerCompany": null,
                    "customerPhoneNum1": null,
                    "customerPhoneNum2": null,
                    "matterTitle": "title29",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-01-13 09:50:28",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": null,
                    "matterUrgency":null
                },
                {
                    "matterId": 28,
                    "matterNumber": "220113-000028",
                    "idPerDate": 28,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": null,
                    "status": 0,
                    "customerFirstName": "裕美子",
                    "customerLastName": "木村",
                    "customerCompany": "有限会社 吉本",
                    "customerPhoneNum1": "0663043227",
                    "customerPhoneNum2": "0310862366",
                    "matterTitle": "title28",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-01-14 09:23:57",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": "木村 裕美子",
                    "matterUrgency":null
                },
                {
                    "matterId": 27,
                    "matterNumber": "220113-000027",
                    "idPerDate": 27,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": null,
                    "status": 0,
                    "customerFirstName": null,
                    "customerLastName": null,
                    "customerCompany": null,
                    "customerPhoneNum1": null,
                    "customerPhoneNum2": null,
                    "matterTitle": "title27",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-01-13 10:37:10",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": null,
                    "matterUrgency":null
                },
                {
                    "matterId": 26,
                    "matterNumber": "220113-000026",
                    "idPerDate": 26,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": null,
                    "status": 0,
                    "customerFirstName": null,
                    "customerLastName": null,
                    "customerCompany": null,
                    "customerPhoneNum1": null,
                    "customerPhoneNum2": null,
                    "matterTitle": "title26",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-01-13 10:24:29",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": null,
                    "matterUrgency":null
                },
                {
                    "matterId": 25,
                    "matterNumber": "220113-000025",
                    "idPerDate": 25,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": null,
                    "status": 0,
                    "customerFirstName": "直子",
                    "customerLastName": "山田",
                    "customerCompany": "有限会社 吉田",
                    "customerPhoneNum1": "0931529147",
                    "customerPhoneNum2": "0290311044",
                    "matterTitle": "title25",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-01-14 09:24:40",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": "山田 直子",
                    "matterUrgency":null
                },
                {
                    "matterId": 24,
                    "matterNumber": "220113-000024",
                    "idPerDate": 24,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": null,
                    "status": 0,
                    "customerFirstName": null,
                    "customerLastName": null,
                    "customerCompany": null,
                    "customerPhoneNum1": null,
                    "customerPhoneNum2": null,
                    "matterTitle": "title24",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-01-13 01:28:43",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": null
                },
                {
                    "matterId": 23,
                    "matterNumber": "220113-000023",
                    "idPerDate": 23,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": null,
                    "status": 0,
                    "customerFirstName": null,
                    "customerLastName": null,
                    "customerCompany": null,
                    "customerPhoneNum1": null,
                    "customerPhoneNum2": null,
                    "matterTitle": "title23",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-01-13 10:10:49",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": null,
                    "matterUrgency":null
                },
                {
                    "matterId": 22,
                    "matterNumber": "220113-000022",
                    "idPerDate": 22,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": null,
                    "status": 0,
                    "customerFirstName": null,
                    "customerLastName": null,
                    "customerCompany": null,
                    "customerPhoneNum1": null,
                    "customerPhoneNum2": null,
                    "matterTitle": "title22",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-01-13 10:16:47",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": null,
                    "matterUrgency":null
                },
                {
                    "matterId": 21,
                    "matterNumber": "220113-000021",
                    "idPerDate": 21,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": null,
                    "status": 0,
                    "customerFirstName": null,
                    "customerLastName": null,
                    "customerCompany": null,
                    "customerPhoneNum1": null,
                    "customerPhoneNum2": null,
                    "matterTitle": "title21",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-01-13 01:28:43",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": null,
                    "matterUrgency":null
                },
                {
                    "matterId": 20,
                    "matterNumber": "220113-000020",
                    "idPerDate": 20,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": null,
                    "status": 0,
                    "customerFirstName": null,
                    "customerLastName": null,
                    "customerCompany": null,
                    "customerPhoneNum1": null,
                    "customerPhoneNum2": null,
                    "matterTitle": "title20",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-01-13 01:28:43",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": null,
                    "matterUrgency":null
                },
                {
                    "matterId": 19,
                    "matterNumber": "220113-000019",
                    "idPerDate": 19,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": null,
                    "status": 0,
                    "customerFirstName": null,
                    "customerLastName": null,
                    "customerCompany": null,
                    "customerPhoneNum1": null,
                    "customerPhoneNum2": null,
                    "matterTitle": "title19",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-01-13 01:28:43",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": null,
                    "matterUrgency":null
                },
                {
                    "matterId": 5,
                    "matterNumber": "220113-000005",
                    "idPerDate": 5,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": null,
                    "status": 0,
                    "customerFirstName": "京助",
                    "customerLastName": "宮沢",
                    "customerCompany": "有限会社 若松",
                    "customerPhoneNum1": "0071-02-8235",
                    "customerPhoneNum2": "09-1883-6065",
                    "matterTitle": "title5",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-02-01 07:48:15",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": "宮沢 京助",
                    "matterUrgency":null
                },
                {
                    "matterId": 4,
                    "matterNumber": "220113-000004",
                    "idPerDate": 4,
                    "inquiryType": 0,
                    "inquiryDate": "2022/01/13",
                    "userId": null,
                    "status": 0,
                    "customerFirstName": "洋介",
                    "customerLastName": "中島",
                    "customerCompany": "有限会社 木村",
                    "customerPhoneNum1": "0800269241",
                    "customerPhoneNum2": "09010594275",
                    "matterTitle": "title4",
                    "createdAt": "2022-01-13 01:28:43",
                    "updatedAt": "2022-01-13 01:28:43",
                    "inquiryTypeName": "メール",
                    "statusName": "未対応",
                    "customerName": "中島 洋介",
                    "matterUrgency":null
                },
            ],
        }
    },
    methods: {
        getNdDisplayTelSearch(ndDisplayTel){
          this.ndDisplayTel = ndDisplayTel;
        },
        // タブとして保存
        tabAddHistory() {
            // storeの「inquiryHistorySearchResultList」に既に登録済の案件だった場合、追加処理は行わない
            const inquiryHistoryList = this.$store.getters.getInquiryHistorySearchResultList;
            for (let i = 0; i < inquiryHistoryList.length; i++) {
                if (inquiryHistoryList[i].historySearchFlg === this.historySearchFlg && inquiryHistoryList[i].historySearchKeyword === this.historySearchKeyword) {
                    console.log("既に登録済み");
                    return;
                }
            }
            // storeの「inquiryHistorySearchResultList」へ問い合わせ履歴の検索結果をpush
            let id = 1;
            if (inquiryHistoryList[inquiryHistoryList.length - 1]) {
                id = inquiryHistoryList[inquiryHistoryList.length - 1].id + 1;
            }
            const newHistorySearchTab = {
                id: id,
                historySearchFlg: this.historySearchFlg,
                historyIcon: this.historySearchIconList[this.historySearchFlg],
                historySearchKeyword: this.historySearchKeyword,
            };
            this.$store.dispatch('updateInquiryHistorySearchResultListAction', newHistorySearchTab);
        },
        // 問い合わせ情報取得
        getMatter: async function (matterId) {
            this.loadingCount++;
            try {
                const response = await getMatterService.getMatter(matterId);
                // 取得したデータを格納
                this.matterStatusData = response.data.data.basicInfo;
                this.matterCustomerData = response.data.data.customer;
                this.matterProductsData = response.data.data.product;
                this.matterInquiriesData = response.data.data.inquiries;
                this.getMatterCompletion = true;
            } catch (e) {
                console.log(e);
            }
            this.loadingCount--;
        },

        // 案件部分を表示
        matterCardOpen: async function ($event) {
            this.matterId = $event.matterId;
            // 案件取得
            await this.getMatter(this.matterId);
            this.$nextTick(function () {
                // 子コンポーネント(状態パネル)のreload関数を実行
                this.$refs.inquiryStatus.reloadInquiryStatus();
                // 子コンポーネント(お客様情報パネル)のreload関数を実行
                this.$refs.customerInformation.reloadCustomerInformation();
                // 子コンポーネント(商品/サービス情報パネル)のreload関数を実行
                this.$refs.productInformation.reloadProductInformation();
            });


            if (this.matterCardFlg === false) {
                this.matterCardFlg = true;
            }
        },
    },
}
</script>
<style scoped>
/* テーブルのヘッダーのフォントサイズ */
.v-data-table ::v-deep th {
    font-size: 18px !important;
    background-color: #90caf9;
}
/* テーブルデータのフォントサイズ */
.v-data-table ::v-deep td {
    /* font-size: 10px !important; */
    background-color: #e3f2fd;
}
.v-data-table ::v-deep tr:nth-child(odd) td {
    background: #fff;
}

.v-data-table ::v-deep tr:hover td {
    background-color: #eee;
}
::v-deep .v-data-table__empty-wrapper > td {
    font-size: 20px !important;
    /* font-family: sans-serif !important; */
}
</style>
