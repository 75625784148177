<template>
  <div>
    <v-card-title>
      <v-row align="center">
        <v-col cols="auto" class="py-0">
          <span class="text-h6">対応メモ</span>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-title>
    <v-divider class="mx-4"></v-divider>
    <v-card-text class="py-1">
      <v-row class="py-2">
        <v-col>
          <div
            v-if="matterStatusData"
            v-html="memoContentWithLinks"
            :style="{
            'background-color': getBackgroundColor(matterStatusData.memo, matterStatusData.oldMemo) || 'white',
            }"
            class="memo-content"
          >
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
import {getBackgroundColor} from '@/components/helpers/utils';

export default {
    props: {
      matterStatusData:{
        type: Object,
      },
      // 親コンポーネントよりmatterIdを取得
      matterId: {
        type: Number,
      },
    },
    data() {
      return {
        memoContent: ''
      };
    },
    methods: {
      getBackgroundColor,
    },
    computed: {
      memoContentWithLinks() {
        const memoContent = this.matterStatusData.memo || '';
        const urlRegex = /https?:\/\/\S+/gi;
        return memoContent.replace(urlRegex, (url) => {
          return `<a href="${url}" target="_blank">${url}</a>`;
        });
      }
    }
}
</script>
<style scoped>
  .memo-content {
    padding: 8px 12px 0;
    font-size: 16px;
    font-weight: 500;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    box-sizing: border-box;
    white-space: pre-wrap;
    line-height: 28px;
  }
</style>
