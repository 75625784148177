import http from '@/httpCommon.js';

export default {
  // 商品/サービス情報の検索結果取得
  async getProductSearchList(productCode, productNumber, productName, productNameKana, barcode, queryData) {
    let query = ''
    const key = Object.keys(queryData)
    const value = Object.values(queryData)

    for(let i=0; i < key.length; i++){
      if(i == 0 ) query += `?${key[i]}=${value[i]}`
      else query += `&${key[i]}=${value[i]}`
    }
    return http.get(`/product-search${query}`, {
      params: {
        productCode: productCode,
        productNumber: productNumber,
        productName: productName,
        productNameKana: productNameKana,
        barcode: barcode,
      },
    });
  },

  async getProductSearchListMethodTwo(productCode, productNumber, productName, productNameKana, barcode, queryData) {
    let query = ''
    const key = Object.keys(queryData)
    const value = Object.values(queryData)

    for(let i=0; i < key.length; i++){
      if(i == 0 ) query += `?${key[i]}=${value[i]}`
      else query += `&${key[i]}=${value[i]}`
    }
    return http.get(`/product-search-method-two${query}`, {
      params: {
        productCode: productCode,
        productNumber: productNumber,
        productName: productName,
        productNameKana: productNameKana,
        barcode: barcode,
      },
    });
  },

  async productAllMatterSearch(classification_l){
    return http.get(`/product-all-matter-search/${classification_l}`)
  },

  async getProducts(productIds) {
    return http.get(`/products`, {
      params: { productIds: productIds }
    });
  },
};
