<template>
    <div style="background-color: #E0E0E0; height: 100%; padding-top: 1px;" id="sIDMatterListSearch">
        <v-card color="grey lighten-5" class="ma-3">
            <v-card-title class="py-1">問い合わせ一覧</v-card-title>
            <v-divider class="mx-4"></v-divider>
            <v-row class="mt-1" align="center" no-gutters>
                <v-col cols="1" class="mt-1">
                    <p class="text-center text-subtitle-1 mb-0">状態</p>
                </v-col>
                <v-col cols="7">
                    <!-- 状態選択 -->
                    <v-chip-group v-model="selectedStatusFlgs" multiple>
                        <v-chip
                            v-for="status in statusList"
                            :key="status.statusFlg"
                            class="font-weight-bold px-4 hover-color"
                            style="font-size: 12px"
                            filter
                            :value="status.statusFlg"
                            :color="status.statusCheck ? 'indigo darken-4' : 'blue lighten-4'"
                            :text-color="status.statusCheck ? 'white' : 'blue accent-3'"
                            @click="status.statusCheck = !status.statusCheck"
                            @keypress.enter="status.statusCheck = !status.statusCheck; statusFlgsUpdate(status.statusFlg)"
                        >{{ status.statusName }}</v-chip>
                    </v-chip-group>
                </v-col>
                <v-col cols="4">
                    <!-- 担当者 -->
                    <v-row align="center" no-gutters>
                        <v-col cols="4">
                          <v-chip
                            v-model="selectedCheckedFlg"
                            class="font-weight-bold px-5 hover-color"
                            filter
                            style="font-size: 12px"
                            :value="selectedCheckedFlg"
                            :color="selectedCheckedFlg ? 'indigo darken-4' : 'blue lighten-4'"
                            :text-color="selectedCheckedFlg? 'white' : 'blue accent-3'"
                            @click="selectedCheckedFlg = !selectedCheckedFlg"
                          >チェック</v-chip>
                        </v-col>
                        <v-col cols="2">
                            <p class="text-subtitle-1 mb-0 align-center">担当者</p>
                        </v-col>
                        <v-col cols="4" style="margin-left: 5px">
                            <v-row align-content="center" align="center">
                                <!-- <v-col cols="auto" class="pr-0"> -->
                                <v-chip-group v-model="selectedUserList" multiple column>
                                    <!-- 担当者追加前の初期ボタン -->
                                    <v-chip
                                        v-if="loginUser.userAssignable"
                                        :key="loginUser.id"
                                        class="font-weight-bold px-5 thumb-up"
                                        style="font-size: 12px"
                                        filter
                                        :value="loginUser.id"
                                        :color="loginUser.userButton ? 'indigo darken-4' : 'blue lighten-4'"
                                        :text-color="loginUser.userButton ? 'white' : 'blue accent-3'"
                                        @click="loginUser.userButton = !loginUser.userButton"
                                        @keypress.enter="loginUser.userButton = !loginUser.userButton; userListUpdate(loginUser.id)"
                                    >{{ loginUser.userName }}</v-chip>
                                    <!-- 追加ボタン -->
                                    <v-chip
                                        v-for="user in userMasterList.filter((v) => v.userAddCheck)"
                                        :key="user.id"
                                        class="font-weight-bold px-5"
                                        style="font-size: 12px"
                                        filter
                                        :value="user.id"
                                        :color="user.userButton ? 'indigo darken-4' : 'blue lighten-4'"
                                        :text-color="user.userButton ? 'white' : 'blue accent-3'"
                                        @click="user.userButton = !user.userButton"
                                        @keypress.enter="user.userButton = !user.userButton; userListUpdate(user.id)"
                                    >{{ user.fullName }}</v-chip>
                                </v-chip-group>
                                <!-- 担当者追加ボタン -->
                                <v-dialog v-model="userAddModalFlg" scrollable max-width="300px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            fab
                                            dark
                                            x-small
                                            color="indigo darken-4"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            <v-icon dark>mdi-plus</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-title>担当者リスト</v-card-title>
                                        <v-divider></v-divider>
                                        <v-card-text style="height: 300px;">
                                            <v-checkbox
                                                v-for="user in userMasterList"
                                                :key="user.id"
                                                :label="user.fullName"
                                                :input-value="user.userAddCheck"
                                                color="primary"
                                                hide-details
                                                @click="user.userAddCheck = !user.userAddCheck; user.userButton = false"
                                            ></v-checkbox>
                                        </v-card-text>
                                        <v-divider></v-divider>
                                        <v-card-actions>
                                            <v-btn
                                                color="blue darken-1"
                                                text
                                                @click="userAddModalFlg = false"
                                            >閉じる</v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="mt-1"  align="center"  no-gutters>
                <v-col cols="6">
                    <!-- 表示期間選択 -->
                    <v-row class="mt-2" align="center" no-gutters>
                        <v-col cols="2">
                            <p class="text-center text-subtitle-1 mb-0">表示期間</p>
                        </v-col>
                        <!-- 開始日 -->
                        <v-col cols="4">
                            <v-text-field
                                type="date"
                                v-model="periodStartDate"
                                label="開始日"
                                class="pa-0 ma-0"
                                :disabled="noDateSpecified"
                                outlined
                                dense
                                hide-details
                                @keyup.enter="beforeSearchMatterList"
                            />
                        </v-col>
                        <!-- 「～」 -->
                        <v-col cols="2">
                            <p class="text-center text-h5 mb-0">～</p>
                        </v-col>
                        <!-- 終了日 -->
                        <v-col cols="4">
                            <v-text-field
                                type="date"
                                v-model="periodEndDate"
                                label="終了日"
                                class="pa-0 ma-0"
                                :disabled="noDateSpecified"
                                outlined
                                dense
                                hide-details
                                @keyup.enter="beforeSearchMatterList"
                            />
                        </v-col>
                    </v-row>
                    <!-- 表示期間が90日を超えた場合、アラートを表示 -->
                    <v-row
                        v-if="dateRangeCheck"
                        align-content="center"
                        class="mt-4"
                        align="center"
                        no-gutters
                    >
                        <v-col offset="4" cols="6">
                            <v-alert
                                dense
                                outlined
                                class="ma-0"
                                color="red lighten-2"
                                type="error"
                            >表示期間最大３ヶ月(90日間)</v-alert>
                        </v-col>
                    </v-row>
                    <!-- 不正な日付が選択された場合、アラートを表示 -->
                    <v-row
                        v-if="exactDateCheck"
                        align-content="center"
                        class="mt-4"
                        align="center"
                        no-gutters
                    >
                        <v-col offset="4" cols="6">
                            <v-alert
                                dense
                                outlined
                                class="ma-0 px-2"
                                color="red lighten-2"
                                type="error"
                            >選択された日付は有効な日付ではありません</v-alert>
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="4" class="ml-5">
                  <v-checkbox
                    ref="noDateSpecified"
                    v-model="noDateSpecified"
                    label="日付指定なし"
                    class="d-inline-flex mini-size"
                    dense
                  ></v-checkbox>
                </v-col>
            </v-row>

            <!-- お客様名 -->
            <v-row class="pt-3" align="center" no-gutters>
                <v-col cols="1">
                    <p class="text-center text-subtitle-1 mb-0">お客様名</p>
                </v-col>
                <v-col cols="4" class="pr-10">
                    <v-text-field
                        v-model="customerLastName"
                        label="姓名(漢字)"
                        outlined
                        hide-details
                        dense
                        clearable
                        @keyup.enter="beforeSearchMatterList"
                    ></v-text-field>
                </v-col>
                <v-col cols="2">
                    <p class="text-center text-subtitle-1 mb-0">お客様名（よみ）</p>
                </v-col>
                <v-col cols="4" class="pr-10">
                    <v-text-field
                        v-model="customerLastNameKana"
                        label="姓名(ひらがな)"
                        outlined
                        hide-details
                        dense
                        clearable
                        @keyup.enter="beforeSearchMatterList"
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row class="pt-3" align="center" no-gutters>
              <v-col cols="1">
                <p class="text-center text-subtitle-1 mb-0">会社名</p>
              </v-col>
              <v-col cols="4" class="pr-10">
                <v-text-field
                  v-model="companyName"
                  outlined
                  clearable
                  hide-details
                  dense
                  placeholder="株式会社〇〇"
                  @keyup.enter="beforeSearchMatterList"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <p class="text-center text-subtitle-1 mb-0">会社名（よみ）</p>
              </v-col>
              <v-col cols="4" class="pr-10">
                <v-text-field
                  v-model="companyNameKana"
                  label="会社名（よみ）"
                  outlined
                  hide-details
                  dense
                  clearable
                  @keyup.enter="beforeSearchMatterList"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row class="pt-3" align="center" no-gutters>
                <!-- 電話番号 -->
                <v-col cols="1">
                    <p class="text-center text-subtitle-1 mb-0">電話番号</p>
                </v-col>
                <v-col cols="4" class="pr-10">
                    <v-text-field
                        v-model="phoneNumberOrFax"
                        label="電話番号"
                        hint="(半角･ハイフンなし)"
                        outlined
                        hide-details="auto"
                        dense
                        clearable
                        @keyup.enter="beforeSearchMatterList"
                    ></v-text-field>
                </v-col>
                <v-col cols="2">
                  <p class="text-center text-subtitle-1 mb-0">受付手段</p>
                </v-col>
                <v-col cols="2" class="pr-10" >
                  <v-select
                    v-model="inquiryType"
                    :items="inquiryTypeList"
                    item-text="label"
                    item-value="value"
                    label="選択してください"
                    outlined
                    clearable
                    hide-details
                    dense
                    @keyup.enter="beforeSearchMatterList"
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-btn
                    rounded
                    color="primary"
                    class="px-8 text-subtitle-1 mr-2"
                    @click="searchMatterConditionsModalOpen"
                  >その他の検索条件
                  </v-btn>
                </v-col>
            </v-row>
            <!-- 検索ボタン -->
            <v-row no-gutters class="mt-4">
              <v-col cols="4" class="d-flex justify-center">
                <v-btn @click="prepareDownloading = true"
                       rounded
                       elevation="5"
                       width="200"
                       color="primary"
                       class="white--text custom-float"
                >一覧表示出力
                </v-btn>
              </v-col>
              <template>
                <v-row justify="center" style="position: absolute">
                  <v-dialog
                    v-model="prepareDownloading"
                    persistent
                    max-width="500"
                  >
                    <v-card>
                      <v-card-title class="text-h6">
                        選択文書ダウンロード予約
                      </v-card-title>
                      <v-text-field class="mx-5" v-model="fileName"
                                    label="ファイル名"
                                    placeholder="拡張子含め50文字まで。拡張子は自動付与されます。"
                                    outlined dense
                                    :hint="fileName.length > 0 ? fileName.replaceAll(' ','')+'.csv' : ''"
                      ></v-text-field>

                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          color="blue-grey darken-1"
                          rounded
                          dark
                          @click="closeDownloadCSV"
                        >
                          <v-icon dark>
                            mdi-close-octagon
                          </v-icon>
                          キャンセル
                        </v-btn>
                        <v-btn
                          color="success"
                          rounded
                          @click="confirmDownload"
                          :disabled="fileName.length <= 0 || fileName.length > 50"
                        >
                          <v-icon dark>
                            mdi-download
                          </v-icon>
                          はい
                        </v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-row>
              </template>

              <v-col cols="4" class="d-flex justify-center">
                <v-btn
                  :disabled="dateRangeCheck || exactDateCheck"
                  rounded
                  elevation="5"
                  width="200"
                  color="success"
                  class="white--text"
                  @click="beforeSearchMatterList"
                >
                  <v-icon left>mdi-magnify</v-icon>検索
                </v-btn>
              </v-col>
              <v-col cols="4" class="d-flex justify-end">
                <v-btn
                  rounded
                  elevation="5"
                  width="200"
                  color="warning"
                  class="white--text mr-8"
                  @click="resetDataSearch"
                >
                  検索条件クリア
                </v-btn>
              </v-col>
            </v-row>
            <v-divider class="mt-3 mx-4"></v-divider>
            <!-- 問い合せ一覧テーブル表示 -->
            <MatterListSearchTable :matter-list="matterList" :sort-param.sync="sortParam" :page-count="pageCount" :page="page" :itemsPerPage="itemsPerPage"
                                   @changePage="page = $event" @changePerPage="changeItemsPerPage($event)" @sortParam="searchMatterList" ref="matterListSearchTable"
                                   @updateMatterList="updateMatterList" :silent-load="silentLoad"
                                   @isEditing="isEditing"
            />
        </v-card>
        <v-card-actions style="padding: 1px">
          <v-row align="center" justify="center">
            <!-- 検索条件モーダル -->
            <v-dialog v-model="searchMatterConditionsModal" persistent max-width="50%" style="margin-top:70px;">
              <v-card>
                <v-toolbar color="primary" dark>
                  <span class="text-h5 ml-3">検索条件</span>
                  <v-spacer></v-spacer>
                  <v-btn icon @click="searchMatterConditionsModalClose">
                    <v-icon large>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <v-form ref="matterSearchform" @submit.prevent="onSearchMatterList">
                    <v-row align="center" class="mt-2">
                      <v-col cols="3">
                        <p class="mb-0 text-subtitle-1 ml-16">お客様名</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="customerLastName"
                          autofocus
                          outlined
                          clearable
                          hide-details
                          dense
                          placeholder="姓名(漢字)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" style="margin-top: 0">
                      <v-col cols="3">
                        <p class="mb-0 text-subtitle-1 ml-16">お客様名(よみ)</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="customerLastNameKana"
                          outlined
                          clearable
                          hide-details
                          dense
                          placeholder="姓名(ひらがな)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" style="margin-top: 0">
                      <v-col cols="3">
                        <p class="mb-0 text-subtitle-1 ml-16">会社名</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="companyName"
                          outlined
                          clearable
                          hide-details
                          dense
                          placeholder="株式会社〇〇"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" style="margin-top: 0">
                      <v-col cols="3">
                        <p class="mb-0 text-subtitle-1 ml-16">会社名（よみ）</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="companyNameKana"
                          outlined
                          clearable
                          hide-details
                          dense
                          placeholder="かぶしきかいしゃ〇〇"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" style="margin-top: 0">
                      <v-col cols="3">
                        <p class="mb-0 text-subtitle-1 ml-16">電話番号</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="phoneNumberOrFax"
                          outlined
                          clearable
                          hide-details
                          dense
                          placeholder="(半角･ハイフンなし)"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" style="margin-top: 0">
                      <v-col cols="3">
                        <p class="mb-0 text-subtitle-1 ml-16">メールアドレス</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="email"
                          outlined
                          clearable
                          hide-details
                          dense
                          placeholder="メールアドレスを入力"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" style="margin-top: 0">
                      <v-col cols="3">
                        <p class="mb-0 text-subtitle-1 ml-16">住所</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="address"
                          outlined
                          clearable
                          hide-details
                          dense
                          placeholder="例：〇〇市△△区〇〇町0-0"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" style="margin-top: 0">
                      <v-col cols="3">
                        <p class="mb-0 text-subtitle-1 ml-16">案件番号</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="matterNumber"
                          outlined
                          clearable
                          hide-details
                          dense
                          placeholder="案件番号"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" style="margin-top: 0">
                      <v-col cols="3">
                        <p class="ml-16 text-subtitle-1 mb-0">受付手段</p>
                      </v-col>
                      <v-col cols="8">
                        <v-select
                          v-model="inquiryType"
                          :items="inquiryTypeList"
                          item-text="label"
                          item-value="value"
                          label="選択してください"
                          outlined
                          clearable
                          hide-details
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row align="center" style="margin-top: 0">
                      <v-col cols="3">
                        <p class="ml-16 text-subtitle-1 mb-0">お客様感情</p>
                      </v-col>
                      <v-col cols="8">
                        <v-select
                          v-model="psychologicalState"
                          :items="psychologicalStateList"
                          item-text="label"
                          item-value="value"
                          label="お客様感情"
                          outlined
                          clearable
                          hide-details
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    <v-row align="center" style="margin-top: 0">
                      <v-col cols="3">
                        <p class="mb-0 text-subtitle-1 ml-16">商品コード</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="productCode"
                          outlined
                          clearable
                          hide-details
                          dense
                          placeholder="商品コード"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" style="margin-top: 0">
                      <v-col cols="3">
                        <p class="mb-0 text-subtitle-1 ml-16">品番</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="productNumber"
                          outlined
                          clearable
                          hide-details
                          dense
                          placeholder="品番"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" style="margin-top: 0">
                      <v-col cols="3">
                        <p class="mb-0 text-subtitle-1 ml-16">商品名</p>
                      </v-col>
                      <v-col cols="8">
                        <v-text-field
                          v-model="productName"
                          outlined
                          clearable
                          hide-details
                          dense
                          placeholder="商品名"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                    <v-row align="center" style="margin-top: 0">
                      <v-col cols="3">
                        <p class="ml-16 text-subtitle-1 mb-0">問合せ区分</p>
                      </v-col>
                      <v-col cols="8">
                        <v-select
                          v-model="category"
                          :items="categoryList"
                          item-text="label"
                          item-value="value"
                          label="問合せ区分"
                          outlined
                          clearable
                          hide-details
                          dense
                        ></v-select>
                      </v-col>
                    </v-row>
                    <!-- 検索ボタンと入力クリアボタン -->
                    <v-card-actions class="justify-center py-3">
                      <v-btn
                        rounded
                        color="success"
                        x-large
                        class="px-8 mr-10"
                        type="submit"
                      >入力した内容で検索
                      </v-btn>
                      <v-btn rounded color="warning" x-large class="px-12" @click="matterSearchReset">入力クリア</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card-text>
                <v-divider class="mx-4 mt-2"></v-divider>
              </v-card>
            </v-dialog>
          </v-row>
        </v-card-actions>

      <template>
        <v-row justify="center">
          <v-dialog
            v-model="isEditingDialog"
            persistent
            max-width="600"
          >
            <v-card v-if="editingBy">
              <v-card-title class="text-h5 justify-center">
                <span><span style="color: #fb8c00">{{editingBy}}</span>さんが編集しています。</span>
              </v-card-title>
              <v-divider></v-divider>

              <v-card-actions class="justify-center">
                <v-btn
                  rounded
                  large
                  color="warning"
                  @click="isEditingDialog = false"
                >
                  閉じる
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

      <!-- ローディング中にスピナーを表示させる -->
        <v-overlay :value="loadingCount > 0 && !silentLoad">
            <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>
    </div>
</template>

<script>
import MatterListSearchTable from '@/components/parts/MatterListSearchTable.vue';
import getMatterListService from '@/services/getMatterListService.js';
import constant from '@/components/helpers/const';
import { mapActions } from 'vuex'
import moment from 'moment';
import userSettingService from "@/services/userSettingService";
import constants from "@/components/helpers/const";

export default {
    components: {
        MatterListSearchTable,
    },
    // 画面表示時に行う処理
    beforeRouteEnter(to, from, next) {
        next(vm => {
            vm.storeHeaderTitleUpdate();
            next();
        });
    },
    data() {
        return {
            // 状態
            statusList: [
                {
                    statusFlg: 0,
                    statusName: '未対応',
                    statusCheck: false,
                },
                {
                    statusFlg: 1,
                    statusName: '対応中',
                    statusCheck: false,
                },
                {
                    statusFlg: 2,
                    statusName: '返送待ち',
                    statusCheck: false,
                },
                {
                    statusFlg: 3,
                    statusName: '商品手配中',
                    statusCheck: false,
                },
                {
                    statusFlg: 4,
                    statusName: '対応済み',
                },
                {
                    statusFlg: 5,
                    statusName: '未達完了',
                },
            ],
            // 表示期間_開始日
            periodStartDate: '',
            periodStartDateFlg: false,
            // 表示期間_終了日
            periodEndDate: '',
            periodEndDateFlg: false,

            // 担当者追加モーダルONOFF
            userAddModalFlg: false,

            // バックエンド連携
            // 状態格納配列
            selectedStatusFlgs: [],
            // 担当者格納配列
            selectedUserList: [],
            // テーブルに渡す案件リスト
            matterList: [],
            pageCount: null,
            page: 1,
            itemsPerPage: 10,
            sortParam:{
              sortBy: 'inquiryDate',
              sortDesc: [true]
            },
            // loading表示用
            loadingCount: 0,
            // 担当社追加ボタンに表示するユーザー一覧
            userMasterList: [],

            // 名前(姓)
            customerLastName: '',
            // よみ(姓)
            customerLastNameKana: '',
            // 電話番号
            phoneNumberOrFax: '',
            companyName: '',
            inquiryType: null,
            refreshMatterList: null,
            silentLoad: false,
            inquiryTypeList: constant.inquiryTypeList,
            companyNameKana: '',
            searchMatterConditionsModal: false,
            email: '',
            address: '',
            matterNumber:'',
            psychologicalStateList: ['喜び', '困り', '怒り', '普通', '粘質'],
            psychologicalState: null,
            categoryList: [
              {
                value: 1,
                label: 'お問合せ',
              },
              {
                value: 2,
                label: '苦情',
              },
              {
                value: 3,
                label: 'ご意見',
              },
              {
                value: 4,
                label: 'その他',
              },
            ],
            category: null,
            noDateSpecified: false,
            searchCondition: {
              selectedStatusFlgs: '',
              periodStartDate: '',
              periodEndDate: '',
              selectedUserList: '',
              customerLastName: '',
              customerLastNameKana: '',
              companyName: '',
              companyNameKana: '',
              email: '',
              phoneNumberOrFax: '',
              address: '',
              matterNumber: '',
              inquiryType: '',
              psychologicalState: '',
              category: '',
              productCode: '',
              productNumber: '',
              productName: '',
              checkedFlg: null,
              countEachCategory: false,
              serverFlag: constants.serverFlag.sid
            },
          //todo export matters
          checkedFlg: null,
          prepareDownloading: false,
          fileName: '',
          fileHeaders: [],

          //catch delete matter permission
          isEditingDialog: false,
          selectedCheckedFlg: false,
          editingBy: null,
          // 商品コード
          productCode: '',
          // 品番
          productNumber: '',
          // 商品名
          productName: '',
        }
    },
    computed: {
        // 担当者_自担当分(バックエンドよりログインユーザーのidを取得する)
        loginUser() {
            return {
                id: this.$store.getters['auth/authUser'].id,
                userName: '自分担当',
                userButton: false,
                userAssignable: false,
            };
        },
        // 表示期間の範囲を最大90日に設定
        dateRangeCheck() {
            if (90 < this.getDateRange(this.periodStartDate, this.periodEndDate)) {
                return true;
            } else {
                return false;
            }
        },
        // 選択された日付が不正な日付だった場合にアラートを表示
        exactDateCheck() {
            if (Number.isNaN(this.getDateRange(this.periodStartDate, this.periodEndDate))) {
                return true;
            } else {
                return false;
            }
        },
    },
    watch: {
      async page(){
        await this.searchMatterList();
        await this.savePageCookie();
      },
      selectedCheckedFlg(val){
        val == true ? this.checkedFlg = 1 : this.checkedFlg = null
      }
    },
    methods: {
        ...mapActions({
            activateSuccessNotification: 'snackbar/activateSuccessNotification',
            activateErrorNotification: 'snackbar/activateErrorNotification',
        }),
        // VuexのstateのheaderTitleを更新
        storeHeaderTitleUpdate() {
            this.$store.dispatch('headerTitleChangeAction', 1);
        },

        // 状態をエンターキーで選択(v-chip)した場合の処理
        statusFlgsUpdate(statusFlg) {
            if (this.selectedStatusFlgs.includes(statusFlg)) {
                this.selectedStatusFlgs = this.selectedStatusFlgs.filter(flg => flg !== statusFlg);
            } else {
                this.selectedStatusFlgs.push(statusFlg);
            }
        },
        // 担当者をエンターキーで選択(v-chip)した場合の処理
        userListUpdate(userId) {
            if (!Array.isArray(this.selectedUserList)){
              this.selectedUserList = [this.selectedUserList];
            }
            if (this.selectedUserList.includes(userId)) {
                this.selectedUserList = this.selectedUserList.filter(id => id !== userId);
            } else {
                this.selectedUserList.push(userId);
            }
        },

        // 表示期間の範囲を日付で取得
        getDateRange(periodStartDate, periodEndDate) {
            const startDate = new Date(periodStartDate);
            const endDate = new Date(periodEndDate);
            const dateRange = (endDate - startDate) / 86400000;
            return dateRange;
        },

        // 終了日の初期値（現在の日付）
        initialEndDate() {
            //現在の日付を取得
            let date = new Date();
            const year = date.getFullYear();
            const month = ("00" + (date.getMonth() + 1)).slice(-2);
            const day = ("00" + date.getDate()).slice(-2);
            const currentDate = year + '-' + month + '-' + day;
            this.periodEndDate = currentDate;
        },
        // 開始日の初期値（１週間前の日付）
        initialStartDate() {
            const dash2mttrmode = this.$store.getters.getDash2mattelst
            let date = new Date();
            let day
            // Dashboardのボタンクリックで来た時(当月)
            if (-1 != dash2mttrmode) {
                day = '01'
            } else {
                date.setDate(date.getDate() - 29);
                day = ("00" + date.getDate()).slice(-2);
            }
            let year = date.getFullYear();
            let month = ("00" + (date.getMonth() + 1)).slice(-2);
            let newDate = year + '-' + month + '-' + day;
            this.periodStartDate = newDate;
        },

        // バックエンドから取得した日付データから時分秒を切り捨てる(「yyyy-mm-dd 00:00:00」→「yyyy/mm/dd」)
        dateFormatChange(date) {
            const newDate = new Date(date);
            const year = newDate.getFullYear();
            const month = ("00" + (newDate.getMonth() + 1)).slice(-2);
            const day = ("00" + newDate.getDate()).slice(-2);

            return year + '/' + month + '/' + day
        },

        timeStampFormat(date) {
        const newDate = new Date(date);
        const year = newDate.getFullYear();
        const month = ("00" + (newDate.getMonth() + 1)).slice(-2);
        const day = ("00" + newDate.getDate()).slice(-2);
        const hour = ("00" + newDate.getHours()).slice(-2);
        const minute = ("00" + newDate.getMinutes()).slice(-2);
        const second = ("00" + newDate.getSeconds()).slice(-2);

        return year + '/' + month + '/' + day + " " + hour + ":" + minute + ':' + second;
        },

        // 状態ボタンにチェックを付ける(ダッシュボードで選択された条件で検索する時に使用)
        // statusFlg= 未実装:0,対応中:1,保留:2,対応済み:3
        updateStatusList(statusFlg) {
            for (let i = 0; i < this.statusList.length; i++) {
                if (i === statusFlg) {
                    this.selectedStatusFlgs.push(statusFlg);
                    this.statusList[i].statusCheck = true;
                }
            }
        },
        // 担当者の自分担当ボタンにチェックを付ける(ダッシュボードで選択された条件で検索する時に使用)
        updateSelectUserList() {

            this.selectedUserList.push(this.loginUser.id);
            this.loginUser.userButton = true;
        },

        // 検索処理
        searchMatterList: async function () {
          this.loadingCount++;
          try {
                const response = await getMatterListService.getMatterList(
                    this.searchCondition,
                    this.page,
                    this.itemsPerPage,
                    Array.isArray(this.sortParam.sortBy)?this.sortParam.sortBy[0]:this.sortParam.sortBy,
                    (!this.sortParam.sortDesc || !this.sortParam.sortDesc[0]) ? 'asc' : 'desc'
                );
                // 取得したデータを格納
                this.matterList = response.data.data.data;
                this.pageCount = response.data.data.last_page;
                // 回答期限の時分秒を切り捨てる
                for (let i = 0; i < this.matterList.length; i++) {
                    this.matterList[i].responseLimit = this.dateFormatChange(this.matterList[i].responseLimit);
                }
              this.searchMatterConditionsModal = false;
          } catch (e) {
                console.log(e);
            }
            this.loadingCount--;
        },

        // ユーザーマスタの取得
        getUserMaster: async function () {
            try {
                // 全ユーザー情報取得
                const response = await getMatterListService.getUserMaster();
                const users = response.data.data;
                // ログインユーザーのuserIdを取得
                const loginUserId = this.loginUser.id;
              // ログインユーザー以外のユーザー情報を格納
              this.userMasterList = users.filter(user => {
                  if(user.id !== loginUserId){
                    return user
                  }else{
                    this.loginUser.userAssignable = true;
                  }
                });
                // userMasterListにユーザー追加フラグとボタンのON,OFFのフラグを追加
                for (let i = 0; i < this.userMasterList.length; i++) {
                    this.userMasterList[i].userButton = false;
                    this.userMasterList[i].userAddCheck = false;
                }
                if (this.selectedUserList !=="" && this.selectedUserList !== null){
                  if (!Array.isArray(this.selectedUserList)){
                    this.selectedUserList = [this.selectedUserList];
                  }
                  this.selectedUserList.forEach(key => {
                    if (key === loginUserId){
                      this.loginUser.userButton = true;
                      this.loginUser.userAssignable = true;
                    }
                    const checkList = this.userMasterList.filter(item => item.id === key);
                    if (checkList.length > 0){
                      checkList[0].userButton = true;
                      checkList[0].userAddCheck = true;
                    }
                  })
                }
            } catch (e) {
                console.log(e);
            }
        },
        setSearchCondition(){
          this.searchCondition = {
            selectedStatusFlgs: this.selectedStatusFlgs,
            periodStartDate: this.noDateSpecified ? null : this.periodStartDate.split('-').join(''),
            periodEndDate: this.noDateSpecified ? null :this.periodEndDate.split('-').join(''),
            selectedUserList: this.selectedUserList,
            customerLastName: this.customerLastName,
            customerLastNameKana: this.customerLastNameKana,
            companyName: this.companyName,
            companyNameKana: this.companyNameKana,
            email: this.email,
            phoneNumberOrFax: this.phoneNumberOrFax,
            address: this.address,
            matterNumber: this.matterNumber,
            inquiryType: this.inquiryType,
            psychologicalState: this.psychologicalState,
            category: this.category,
            productCode: this.productCode,
            productNumber: this.productNumber,
            productName: this.productName,
            checkedFlg: this.checkedFlg,
            countEachCategory: false,
            serverFlag: constants.serverFlag.sid
          };
        },
        saveSearchCondition(){
          const expiresDate = moment().utcOffset(0).add(1, 'days').set({hour:0,minute:0,second:0,millisecond:0});
          expiresDate.toISOString();
          expiresDate.format();
          this.$cookies.set("sid_matter_list_start_date",this.periodStartDate,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_end_date",this.periodEndDate,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_user_ids",JSON.stringify(this.selectedUserList),  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_last_name",this.customerLastName,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_last_name_kana",this.customerLastNameKana,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_company_name",this.companyName,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_company_name_kana",this.companyNameKana,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_email",this.email,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_phone_or_fax",this.phoneNumberOrFax,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_address",this.address,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_product_code",this.productCode,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_product_number",this.productNumber,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_product_name",this.productName,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_matter_number",this.matterNumber,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_inquiry_type",this.inquiryType,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_psychological_state",this.psychologicalState,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_no_date_specified",this.noDateSpecified,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_selected_status_flgs",JSON.stringify(this.selectedStatusFlgs),  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_checked_flg",this.checkedFlg,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_category",this.category,  expiresDate.toDate());
        },

        savePageCookie () {
          const expiresDate = moment().utcOffset(0).add(1, 'days').set({hour:0,minute:0,second:0,millisecond:0});
          expiresDate.toISOString();
          expiresDate.format();
          this.$cookies.set("sid_matter_list_page",this.page,  expiresDate.toDate());
          this.$cookies.set("sid_matter_list_per_page",this.itemsPerPage,  expiresDate.toDate());
        },

        async onSearchMatterList(){
          this.page = 1;
          this.setSearchCondition();
          this.saveSearchCondition();
          this.searchMatterConditionsModal = false;
          await this.searchMatterList();
        },
        async searchMatterListOutside() {
          this.email = null;
          this.address = null;
          this.matterNumber = null;
          this.psychologicalState = null;
          this.category = null;
          this.productCode = null;
          this.productName = null;
          this.productNumber = null;
          await this.onSearchMatterList();
        },
        beforeSearchMatterList(){
          if (!this.dateRangeCheck && !this.exactDateCheck){
            if(this.silentLoad){
              this.activateErrorNotification({message: '取得処理が終わってからやり直してください'})
            }
            else this.searchMatterListOutside();
          }
        },
        async updateMatterList(){
          await this.searchMatterList();
        },
        searchMatterConditionsModalOpen() {
          this.searchMatterConditionsModal = true
        },
        async matterSearchReset() {
          await this.$refs.matterSearchform.reset();
        },
        searchMatterConditionsModalClose() {
          this.matterSearchReset();
          this.searchMatterConditionsModal = false
        },
        async resetDataSearch(){
            this.initialEndDate();
            this.initialStartDate();
            this.selectedStatusFlgs = '';
            this.selectedUserList= '';
            this.customerLastName= '';
            this.customerLastNameKana= '';
            this.companyName= '';
            this.companyNameKana= '';
            this.email= '';
            this.phoneNumberOrFax= '';
            this.address= '';
            this.productCode = '';
            this.productName = '';
            this.productNumber = '';
            this.matterNumber= '';
            this.inquiryType= '';
            this.noDateSpecified= false;
            this.psychologicalState= '';
            this.category= '';
            this.selectedCheckedFlg= false;
            let cookieParams = ['sid_matter_list_start_date','sid_matter_list_end_date','sid_matter_list_user_ids','sid_matter_list_last_name',
              'sid_matter_list_last_name_kana','sid_matter_list_company_name','sid_matter_list_company_name_kana','sid_matter_list_email',
              'sid_matter_list_phone_or_fax','sid_matter_list_address','sid_matter_list_matter_number','sid_matter_list_inquiry_type',
              'sid_matter_list_no_date_specified','sid_matter_list_selected_status_flgs','sid_matter_list_checked_flg',
              'sid_matter_list_psychological_state','sid_matter_list_product_code','sid_matter_list_product_number',
              'sid_matter_list_product_name','sid_matter_list_category','sid_matter_list_page','sid_matter_list_per_page',];
            cookieParams.forEach(item =>
              this.$cookies.remove(item),
            )
            this.loginUser.userButton = false;
            this.loginUser.userAssignable = false;
            this.getUserMaster();
            this.statusList.forEach(key => {
              key.statusCheck = false;
            }),
            this.setSearchCondition();
            await this.searchMatterList();
        },
      //todo download csv file
      closeDownloadCSV(){
        this.prepareDownloading = false;
        this.fileName = '';
      },
      async confirmDownload(){
        this.prepareDownloading = false;
        const fileName = this.fileName.replaceAll(' ','')+'.csv';
        this.loadingCount++;
        await getMatterListService.getMattersForCSV(
          this.searchCondition,
          fileName,
          this.fileHeaders,
          this.mattersFields,
          Array.isArray(this.sortParam.sortBy) ? this.sortParam.sortBy[0] : this.sortParam.sortBy,
          (!this.sortParam.sortDesc || !this.sortParam.sortDesc[0]) ? 'asc' : 'desc',)
        this.loadingCount--;
        this.fileName = '';
      },
      async changeItemsPerPage(event){
        this.itemsPerPage = event;
        if(this.page !== 1){
          this.page = 1;
        }else {
          await this.searchMatterList();
        }
        await this.savePageCookie();
      },
      isEditing(editingBy){
        this.editingBy = editingBy;
        this.isEditingDialog = true;
      }
    },
    async mounted() {
        const _this = this
        const dash2mttrmode = this.$store.getters.getDash2mattelst
        // 開始日と終了日の初期値設定
        if(this.$cookies.get("sid_matter_list_start_date")){
          this.periodStartDate = this.$cookies.get("sid_matter_list_start_date");
        }else{
          this.initialStartDate();
        }
        if(this.$cookies.get("sid_matter_list_end_date")){
          this.periodEndDate = this.$cookies.get("sid_matter_list_end_date");
        }else{
          this.initialEndDate();
        }
        if(this.$cookies.get("sid_matter_list_user_ids") !== 'null' && this.$cookies.get("sid_matter_list_user_ids")){
          if(Array.isArray(this.$cookies.get("sid_matter_list_user_ids"))){
            this.selectedUserList = this.$cookies.get("sid_matter_list_user_ids");
          }else{
            try{
              this.selectedUserList = JSON.parse(this.$cookies.get("sid_matter_list_user_ids"));
            } catch(e) {
              console.log('parse error')
            }
          }
        }
        if(this.$cookies.get("sid_matter_list_last_name") !== 'null' && this.$cookies.get("sid_matter_list_last_name")){
          this.customerLastName = this.$cookies.get("sid_matter_list_last_name");
        }
        if(this.$cookies.get("sid_matter_list_last_name_kana") !== 'null' && this.$cookies.get("sid_matter_list_last_name_kana")){
          this.customerLastNameKana = this.$cookies.get("sid_matter_list_last_name_kana");
        }
        if(this.$cookies.get("sid_matter_list_company_name") !== 'null' && this.$cookies.get("sid_matter_list_company_name")){
          this.companyName = this.$cookies.get("sid_matter_list_company_name");
        }
        if(this.$cookies.get("sid_matter_list_company_name_kana") !== 'null' && this.$cookies.get("sid_matter_list_company_name_kana")){
          this.companyNameKana = this.$cookies.get("sid_matter_list_company_name_kana");
        }
        if(this.$cookies.get("sid_matter_list_email") !== 'null' && this.$cookies.get("sid_matter_list_email")){
          this.email = this.$cookies.get("sid_matter_list_email");
        }
        if(this.$cookies.get("sid_matter_list_phone_or_fax") !== 'null' && this.$cookies.get("sid_matter_list_phone_or_fax")){
          this.phoneNumberOrFax = this.$cookies.get("sid_matter_list_phone_or_fax");
        }
        if(this.$cookies.get("sid_matter_list_address") !== 'null' && this.$cookies.get("sid_matter_list_address")){
          this.address = this.$cookies.get("sid_matter_list_address");
        }
        if(this.$cookies.get("sid_matter_list_matter_number") !== 'null' && this.$cookies.get("sid_matter_list_matter_number")){
          this.matterNumber = this.$cookies.get("sid_matter_list_matter_number");
        }
        if(this.$cookies.get("sid_matter_list_inquiry_type") !== 'null' && this.$cookies.get("sid_matter_list_inquiry_type") !== '' && this.$cookies.get("sid_matter_list_inquiry_type") !== null){
          this.inquiryType = Number(this.$cookies.get("sid_matter_list_inquiry_type"));
        }
        if(this.$cookies.get("sid_matter_list_psychological_state") !== 'null' && this.$cookies.get("sid_matter_list_psychological_state")){
          this.psychologicalState = this.$cookies.get("sid_matter_list_psychological_state");
        }
        if(this.$cookies.get("sid_matter_list_no_date_specified") === 'true' && this.$cookies.get("sid_matter_list_no_date_specified")){
          this.noDateSpecified = Boolean(this.$cookies.get("sid_matter_list_no_date_specified"));
        }
        if(this.$cookies.get("sid_matter_list_checked_flg") !== 'null' && this.$cookies.get("sid_matter_list_checked_flg")){
          this.$cookies.get("sid_matter_list_checked_flg") == 1 ? this.selectedCheckedFlg = true : this.selectedCheckedFlg = false;
        }
        if(this.$cookies.get("sid_matter_list_category") !== 'null' && this.$cookies.get("sid_matter_list_category")){
          this.category = Number(this.$cookies.get("sid_matter_list_category"));
        }
        if(this.$cookies.get("sid_matter_list_selected_status_flgs") !== 'null' && this.$cookies.get("sid_matter_list_selected_status_flgs")){
          if(Array.isArray(this.$cookies.get("sid_matter_list_selected_status_flgs"))){
            this.selectedStatusFlgs = this.$cookies.get("sid_matter_list_selected_status_flgs");
          }else{
            try{
              this.selectedStatusFlgs = JSON.parse(this.$cookies.get("sid_matter_list_selected_status_flgs"));
            } catch(e) {
              console.log('parse error');
            }
          }
          if (this.selectedStatusFlgs !== "" && this.selectedStatusFlgs !== null){
            this.selectedStatusFlgs.forEach(key => {
              const checkList = this.statusList.filter(item => item.statusFlg === key);
              if (checkList){
                checkList[0].statusCheck = true;
              }
            })
          }
        }
        if(this.$cookies.get("sid_matter_list_product_code") !== 'null' && this.$cookies.get("sid_matter_list_product_code")){
          this.productCode = this.$cookies.get("sid_matter_list_product_code");
        }
        if(this.$cookies.get("sid_matter_list_product_number") !== 'null' && this.$cookies.get("sid_matter_list_product_number")){
          this.productNumber = this.$cookies.get("sid_matter_list_product_number");
        }
        if(this.$cookies.get("sid_matter_list_product_name") !== 'null' && this.$cookies.get("sid_matter_list_product_name")){
          this.productName = this.$cookies.get("sid_matter_list_product_name");
        }
        if(this.$cookies.get("sid_matter_list_per_page") !== 'null' && this.$cookies.get("sid_matter_list_per_page")){
          this.itemsPerPage = Number(this.$cookies.get("sid_matter_list_per_page"));
        }
        // ユーザーマスタの取得処理
        await this.getUserMaster();
        // 検索処理
        if (-1 != dash2mttrmode) {
            // ダッシュボードからpushされた時
            if (5 >= dash2mttrmode) {
                this.updateStatusList(dash2mttrmode)
            }else if (dash2mttrmode == 6){
                this.checkedFlg = 1
                this.selectedCheckedFlg = true
            }else if (dash2mttrmode == 13){
                this.checkedFlg = 1
                this.selectedCheckedFlg = true
                this.updateSelectUserList()
            }else {
                this.updateStatusList(dash2mttrmode - 7)
                this.updateSelectUserList()
            }
            let now = moment();
            this.noDateSpecified = false;
            this.periodEndDate = now.format('YYYY-MM-DD');
            this.periodStartDate = now.subtract(1, 'months').format('YYYY-MM-DD')
            this.saveSearchCondition();
        }
        this.setSearchCondition();
        let pageCookies = this.$cookies.get("sid_matter_list_page");
        if(pageCookies !== 'null' && Number(pageCookies) > 0 && pageCookies != this.page){
          this.page = Number(pageCookies);
        }else {
          await this.searchMatterList();
        }
        this.$store.commit('setDash2mattelst', -1)

        this.refreshMatterList = setInterval( async function() {
            _this.silentLoad = true
            await _this.searchMatterList();

            const hasNewMatter = _this.matterList.some(element => {
                const createdAt = new Date(element.createdAt)
                const differentTime = Math.abs(new Date() - createdAt);
                return differentTime < 1000*10*60
            })

            if(hasNewMatter){
                _this.activateSuccessNotification({
                    message: '新規案件あり'
                })
            }
            _this.silentLoad = false
        }, 1000*10*60);
    },
   async created() {
    await userSettingService.getMyUserSettings().then(resp => {
      let settings = resp.data.data;
      if (!settings || !settings.field_display) {
        this.mattersFields = constants.defaultMatterFields;
        this.fileHeaders = constants.defaultMatterFileHeaders;
      } else {
        this.mattersFields = settings.field_display.split(';').filter(item => item !== '');
        this.fileHeaders = settings.header_display.split(';').filter(item => item !== '');
      }
    });
  },
  destroyed(){
        clearInterval(this.refreshMatterList);
    }

}
</script>
<style>
/* ボタンのホバー時の背景色を変える */
#sIDMatterListSearch .custom-float{
  position: absolute;
  left: 30px;
}
#sIDMatterListSearch .hover-color::before {
    background-color: #b11350;
}
#sIDMatterListSearch i.v-icon.notranslate.v-chip__filter.v-icon--left.mdi.mdi-check.theme--light{
  font-size: 16px;
}
</style>



