<template>
  <div style="background-color: #E0E0E0; height: 100%; padding-top: 1px;">
    <v-card color="grey lighten-5" class="ma-3" style="height: 97%">
      <v-card-title class="py-1">取り込み状況</v-card-title>
      <v-divider class="mx-4"></v-divider>
      <upload-list-table :product-upload-list="productUploadList" @deleteProductUpload="deleteProductUpload"/>
      <v-overlay :value="loadingCount > 0">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>
<script>

import UploadListTable from "@/components/parts/UploadListTable";
import productUploadService from "@/services/productUploadService";
import {timeStampFormat} from "@/components/helpers/utils";

export default {
  components: {
    UploadListTable,
  },
  // 画面表示時に行う処理
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.storeHeaderTitleUpdate();
      next();
    });
  },
  data() {
    return{
      loadingCount: 0,
      productUploadList: [],
    }
  },
  methods: {
    // VuexのstateのheaderTitleを更新
    storeHeaderTitleUpdate() {
      this.$store.dispatch('headerTitleChangeAction', 7);
    },
    async getProductUploadList() {
      this.loadingCount++;
      await productUploadService.getProductUploadList().then(response => {
        this.productUploadList = response.data.data;
        if(this.productUploadList.length > 0){
          this.productUploadList.forEach(item => {
            item.created_at = timeStampFormat(item.created_at);
          });
        }
      });
      this.loadingCount--;
    },
    deleteProductUpload() {
      this.getProductUploadList();
    }
  },
  mounted() {
    this.getProductUploadList();
  }
}
</script>
<style scoped>

</style>
