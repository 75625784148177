<template>
  <v-card class="ma-2 mt-5">
    <v-container>
      <v-row class="justify-center pt-5">
        <v-col cols="10">
          <v-row>
            <draggable tag="ul" :list="userList" @end="endDraging" @change="reOder">
              <transition-group>
                <v-col
                  class="list-group-item"
                  v-for="(user) in userList"
                  :key="user.id"
                >
                  <v-col>
                    <v-icon class="handle">mdi-drag</v-icon>

                    <v-col class="d-inline" cols="3">
                      <span>{{ user.last_name }} {{ user.first_name }}</span>
                    </v-col>
                  </v-col>
                </v-col>
              </transition-group>
            </draggable>
          </v-row>
        </v-col>

        <rawDisplayer class="col-3" :value="userList" title="List"/>
      </v-row>

    </v-container>
  </v-card>
</template>

<script>
import draggable from "vuedraggable";
import userService from "@/services/userService";

export default {
  components: {
    draggable
  },
  data() {
    return {
      matterAssignableOrder: 0,
      id: 0,
      userList: [],
      dragging: false,
    };
  },
  mounted() {
    userService.getMatterAssignableUsers().then(Response => {
      this.userList = Response.data.data;
    });
  },
  methods: {
    reOder() {
      const newUserList = [...this.userList].map((item, index) => {
        const newOrder = index + 1;
        item.hasChanged = item.sortOrder !== newOrder;
        if (item.hasChanged) {
          item.sortOrder = newOrder;
        }
        return item;
      });
      this.userList = newUserList;
    },
    async endDraging() {
      let params = this.userList;
      userService.updateMatterAssignableOrder(params).then(Response => {
        this.userList = Response.data.data;
      });
    },
    async reloadList() {
      userService.getMatterAssignableUsers().then(Response => {
        this.userList = Response.data.data;
      })
    },
  },

  watch: {
    userList() {
      if (this.userList.length == 0) {
        this.matterAssignableOrder = 0;
        this.id = 0;
      } else {
        this.matterAssignableOrder = Math.max.apply(Math, this.userList.map(function (userList) {
          return userList.matterAssignableOrder;
        }));

        this.id = Math.max.apply(Math, this.userList.map(function (userList) {
          return userList.id;
        }));
      }
    },
  }
};
</script>
<style scoped>

.handle {
  float: left;
}

input {
  display: inline-block;
  width: 50%;
}

.list-group-item {
  transition: transform 0.2s;
  display: flex;
  align-items: center;
  align-content: center;
  padding: 0px;
}

.list-group-item > div {
  padding: 6px;
}

.list-group-item:hover {
  background-color: #d29e1921;
}
</style>
