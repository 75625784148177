<script>
import { Pie } from "vue-chartjs";

export default {
  extends: Pie,
  props: {
    dataPieChart  : { type: [Object,Array], default: () => []  },
    label  : { type: [Object,Array], default: () => []  },
  },
  data () {
    return{
      dataChart:[],
    }
  },
  watch:{
    dataPieChart(val){
      if (val){
        this.renderPieChart();
      }
    }
  },
  methods:{
    renderPieChart(){
      this.renderChart(
        {
          labels: this.label,
          datasets: this.dataPieChart
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend:{
            labels: {
              boxWidth: 20,
              padding:20,
            },
            position: "bottom",
          },
          animation: {
            duration: 2000,
            easing: "easeInOutQuart",

          },
        }
      );
    },
  },
  created(){
    // this.reloadChart();
  }
};
</script>
