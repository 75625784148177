<template>
  <v-app>
    <div v-if="isAuthenticated">
      <!-- ナビゲーションバー -->
      <v-navigation-drawer app v-model="drawer">
        <v-sheet color="grey lighten-3" class="pa-4">
          <div class="blue--text text-h5 text-center">ShachihataDesk</div>
        </v-sheet>
        <v-divider></v-divider>
        <v-btn block large color="success" class="text-h6" v-if="!$store.state.auth.user.isViewer && !$store.state.auth.user.isGuest "  :disabled="disableAddNewMatter" @click="addNewMatter">
          <v-icon left class="text-h5">mdi-phone-in-talk</v-icon>新規受付
        </v-btn>
        <v-list nav dense>
          <!-- ダッシュボード -->
          <v-list-item to="/dashboard">
            <v-list-item-icon>
              <v-icon>mdi-clipboard-text-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>ダッシュボード</v-list-item-title>
          </v-list-item>
          <!-- お知らせ一覧 -->
          <v-list-item v-if="!$store.state.auth.user.isViewer && !$store.state.auth.user.isGuest" to="/notifications">
            <v-list-item-icon>
              <v-badge
                :content="notification"
                :value="notification"
                color="red"
                overlap
              >
                <v-icon>mdi-bell-outline</v-icon>
              </v-badge>
            </v-list-item-icon>
            <v-list-item-title>お知らせ一覧</v-list-item-title>
          </v-list-item>
          <!-- 全問い合わせ検索 -->
          <v-list-item to="/all-matter-search">
            <v-list-item-icon>
              <v-icon>mdi-email-search-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>お問い合わせ内容検索</v-list-item-title>
          </v-list-item>
          <!-- view file download -->
          <v-list-item to="/download">
            <v-list-item-icon>
              <v-icon>mdi-cloud-download-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>ダウンロード状況確認</v-list-item-title>
          </v-list-item>
          <!-- view product upload status -->
          <v-list-item to="/upload" v-if="$store.state.auth.user.isAdmin">
            <v-list-item-icon>
              <v-icon>mdi-cloud-upload-outline</v-icon>
            </v-list-item-icon>
            <v-list-item-title>取り込み状況</v-list-item-title>
          </v-list-item>
          <!-- 問い合わせ一覧 -->
          <v-list>
            <v-list-group class="title-group-menu-customer" v-if="webcsMatterList.length > 0" no-action :value="true" prepend-icon="mdi-headset">
              <template v-slot:activator>
                <v-list-item-title>お客様相談室</v-list-item-title>
              </template>
              <v-list-item
                v-for="(matter, index) in webcsMatterList"
                :key="index"
                class="pl-8"
                :to="matter.matterLink"
                @click="modalClose"
              >
                <v-list-item-icon class="mr-5">
                  <v-icon small v-if="!matter.matterPhone && matter.matterTitle !== '一覧表示'">mdi-email</v-icon>
                  <v-icon small v-if="matter.matterPhone && matter.matterTitle !== '一覧表示'">mdi-phone-in-talk</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  v-if="matter.matterTitle === '一覧表示'"
                  class="text-subtitle-2"
                  v-text="matter.matterTitle"
                />
                <v-list-item-title
                  :class="matter.matterUrgency === 2 ? 'text-color-orange' : (matter.matterUrgency === 1  ? 'text-color-red' : '')"
                  v-if="matter.matterTitle !== '一覧表示'"
                >{{ index }}. {{ matter.matterTitle }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list>
          <v-list>
            <v-list-group class="title-group-menu-customer" v-if="oShopMatterList.length > 0" no-action :value="true">
              <template v-slot:activator>
                <v-list-item-title class="ml-12">オフィシャルショップ</v-list-item-title>
              </template>
              <v-list-item
                v-for="(matter, index) in oShopMatterList"
                :key="index"
                class="pl-8"
                :to="matter.matterLink"
                @click="modalClose"
              >
                <v-list-item-icon class="mr-5">
                  <v-icon small v-if="!matter.matterPhone && matter.matterTitle !== '一覧表示'">mdi-email</v-icon>
                  <v-icon small v-if="matter.matterPhone && matter.matterTitle !== '一覧表示'">mdi-phone-in-talk</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  v-if="matter.matterTitle === '一覧表示'"
                  class="text-subtitle-2"
                  v-text="matter.matterTitle"
                />
                <v-list-item-title
                  :class="matter.matterUrgency === 2 ? 'text-color-orange' : (matter.matterUrgency === 1  ? 'text-color-red' : '')"
                  v-if="matter.matterTitle !== '一覧表示'"
                >{{ index }}. {{ matter.matterTitle }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list>
          <v-list>
            <v-list-group class="title-group-menu-customer" v-if="sidMatterList.length > 0" no-action :value="true">
              <template v-slot:activator>
                <v-list-item-title class="ml-12">Shachihata ID</v-list-item-title>
              </template>
              <v-list-item
                v-for="(matter, index) in sidMatterList"
                :key="index"
                class="pl-8"
                :to="matter.matterLink"
                @click="modalClose"
              >
                <v-list-item-icon class="mr-5">
                  <v-icon small v-if="!matter.matterPhone && matter.matterTitle !== '一覧表示'">mdi-email</v-icon>
                  <v-icon small v-if="matter.matterPhone && matter.matterTitle !== '一覧表示'">mdi-phone-in-talk</v-icon>
                </v-list-item-icon>
                <v-list-item-title
                  v-if="matter.matterTitle === '一覧表示'"
                  class="text-subtitle-2"
                  v-text="matter.matterTitle"
                />
                <v-list-item-title
                  :class="matter.matterUrgency === 2 ? 'text-color-orange' : (matter.matterUrgency === 1  ? 'text-color-red' : '')"
                  v-if="matter.matterTitle !== '一覧表示'"
                >{{ index }}. {{ matter.matterTitle }}</v-list-item-title>
              </v-list-item>
            </v-list-group>
          </v-list>
          <!-- 問い合わせ履歴一覧 -->
          <v-list-group
            v-if="inquiryHistorySearchResultList.length"
            no-action
            :value="trure"
            prepend-icon="mdi-email-search"
          >
            <template v-slot:activator>
              <v-list-item-title>問い合わせ履歴</v-list-item-title>
            </template>
            <v-list-item
              v-for="(history, index) in inquiryHistorySearchResultList"
              :key="index"
              link
              class="pl-8"
              @click="openHistoryTab(history.historySearchFlg, history.historySearchKeyword)"
            >
              <v-list-item-icon>
                <v-icon>{{ history.historyIcon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title v-text="history.historySearchKeyword"></v-list-item-title>
            </v-list-item>
          </v-list-group>
        </v-list>
      </v-navigation-drawer>

      <!-- ヘッダー -->
      <v-app-bar color="primary" dark app>
        <!-- ハンバーガーメニューアイコン -->
        <!-- 画面サイズがmd以下で表示される -->
        <v-app-bar-nav-icon @click="drawer = !drawer" class="hidden-lg-and-up" />
        <!-- タイトル -->
        <v-btn class="ml-5" icon>
          <v-icon large>{{ headerTitle.titleIcon }}</v-icon>
        </v-btn>
        <v-toolbar-title>{{ headerTitle.titleName }}</v-toolbar-title>

        <v-spacer></v-spacer>
        <!-- 履歴検索 -->

        <!-- 現在の日付・時刻 -->
        <v-toolbar-title>{{ date }}</v-toolbar-title>
        <v-toolbar-title>{{ time }}</v-toolbar-title>
        <!-- ログインユーザー -->
        <v-toolbar-title class="ml-15">{{ userName }}</v-toolbar-title>
        <!-- マスタ管理 -->
        <div v-show="masterShow()" class="ml-14" v-if="$store.state.auth.user.isAdmin" >
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                elevation="0"
                fab
                class="ml-2"
                color="primary"
                v-bind="attrs"
                v-on="on"
                @click="gotoMasterDataMng"
              >
                <v-icon large>mdi-database-outline</v-icon>
              </v-btn>
            </template>
            <span>マスタ管理</span>
          </v-tooltip>
        </div>
        <!-- ユーザーアイコン -->
        <v-menu offset-y>
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn v-bind="attrs" v-on="{ ...tooltip, ...menu }" class="ml-3" icon>
                  <v-icon large>mdi-account</v-icon>
                </v-btn>
              </template>
              <span>ログアウト</span>
            </v-tooltip>
          </template>
          <v-list class="py-0">
            <v-list-item
              v-for="(userInformation, index) in userInformationList"
              :key="index"
              @click="logout"
            >
              <v-list-item-icon class="mr-3">
                <v-icon>{{ userInformation.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ userInformation.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <!-- ユーザー設定アイコン -->
        <v-menu offset-y v-if="!$store.state.auth.user.isViewer && !$store.state.auth.user.isGuest ">
          <template v-slot:activator="{ on: menu, attrs }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on: tooltip }">
                <v-btn v-bind="attrs" v-on="{ ...tooltip, ...menu }" class="ml-3" icon>
                  <v-icon large>mdi-account-cog</v-icon>
                </v-btn>
              </template>
              <span>アカウント設定</span>
            </v-tooltip>
          </template>
          <v-list class="py-0">
            <v-list-item
              v-for="(userConfig, index) in userConfigList"
              :key="index"
              @click="showPassworDialog = true"
            >
              <v-list-item-icon class="mr-3">
                <v-icon>{{ userConfig.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ userConfig.title }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

      </v-app-bar>
      <ChangePasswordDialog @close="showPassworDialog = false" v-bind:changePasswordDialog="showPassworDialog" />
    </div>

    <v-main>
      <!-- <Header/> -->
      <router-view />
      <!-- 履歴検索結果モーダル -->
      <v-dialog v-model="historySearchResultModalFlg" scrollable max-width="90%">
        <HistorySearchResultModal
          @modal-close-button-click="modalClose()"
          @tab-delete-button-click="deleteHistoryTab"
          :historySearchFlg="historySearchFlg"
          :historySearchKeyword="historySearchKeyword"
          :historyTabFlg="historyTabFlg"
        ></HistorySearchResultModal>
      </v-dialog>
    </v-main>
    <SnackBar/>

  </v-app>
</template>

<script>
import router from "./router";
import { mapActions, mapGetters } from 'vuex';
import HistorySearchResultModal from '@/components/parts/HistorySearchResultModal.vue';
import SnackBar from './components/helpers/SnackBar.vue';
import ChangePasswordDialog from "@/components/parts/ChangePasswordDialog";
import notificationService from "@/services/notificationService";
import updateMatterService from "@/services/updateMatterService";
import constants from "@/components/helpers/const";

export default {
  name: 'App',

  components: {
    HistorySearchResultModal,
    SnackBar,
    ChangePasswordDialog,
  },

  data() {
    return {
      // ナビゲーションバー
      drawer: true,

      // 現在時刻の表示
      date: '',
      time: '',
      week: ['（日）', '（月）', '（火）', '（水）', '（木）', '（金）', '（土）'],

      userInformationList: [
        {
          title: 'ログアウト',
          icon: 'mdi-account-off',
        },
      ],
      userConfigList: [
        {
          title: 'パスワード再設定',
          icon: 'mdi-key-variant',
        },
      ],

      //change user password
      showPassworDialog: false,

      // // 問い合わせ一覧(タブ)
      // matterList: this.$store.getters.getMatterList,
      // 問い合わせ履歴一覧(タブ)
      // inquiryHistorySearchResultList: this.$store.getters.getInquiryHistorySearchResultList,

      // 履歴検索条件(検索対象)
      historySearchFlg: 0,
      // 履歴検索条件(キーワード)
      historySearchKeyword: '',
      // 履歴検索結果モーダルフラグ
      historySearchResultModalFlg: false,
      historyTabFlg: false,
      // 履歴検索
      historySearchList: [
        // お客様情報検索
        {
          searchFlg: 1,
          historyIcon: 'mdi-account-multiple',
          historyButtonFlg: false,
        },
        // 商品情報検索
        {
          searchFlg: 2,
          historyIcon: 'mdi-grease-pencil',
          historyButtonFlg: false,
        },
        // 問い合わせ内容検索
        {
          searchFlg: 3,
          historyIcon: 'mdi-forum-outline',
          historyButtonFlg: false,
        },
      ],

      // 履歴検索キーワードの検索履歴
      keywordHistoryList: [],
      // キーワード履歴の表示数上限
      LimitKeywordHistory: 8,
    }
  },

  mounted: function () {
    setInterval(this.updateTime, 1000);
    this.createOptionTag();
  },

  methods: {
    ...mapActions('auth', { logoutAction: 'logout'}),    // Login時は、メニューなし
    // Login時は、メニューなし
    masterShow: function () {
      if (location.pathname.toUpperCase() !== '/MASTERMNG') {
        return true
      }
      return false
    },
    // 現在の日付・時刻を表示させる関数
    updateTime: function () {
      //現在の日付・時刻を取得
      let currentDate = new Date();
      //「時：分：秒」に整形する
      this.time = this.degital(currentDate.getHours(), 2) + ':' + this.degital(currentDate.getMinutes(), 2) + ':' + this.degital(currentDate.getSeconds(), 2);
      // 「yyyy/mm/dd（曜日）」に整形する
      this.date = this.degital(currentDate.getFullYear(), 4) + '/' + this.degital(currentDate.getMonth() + 1, 2) + '/' + this.degital(currentDate.getDate(), 2) + this.week[currentDate.getDay()];
    },
    // 現在の日付・時刻に「0」を付ける。例「5月5日」→「05/05」
    degital: function (num, len) {
      let zero = '';
      for (let i = 0; i < len; i++) {
        zero += 0;
      }
      return (zero + num).slice(-len);
    },
    gotoMasterDataMng: function () {
      router.push('/mastermng')
    },
    //ログアウト
    async logout() {
      await updateMatterService.matterStatusLogoutUpdate();
      //ログアウト処理
      await this.logoutAction();
      sessionStorage.clear();
      let cookieParams = ['matter_list_start_date','matter_list_end_date','matter_list_user_ids','matter_list_last_name',
        'matter_list_last_name_kana','matter_list_company_name','matter_list_company_name_kana','matter_list_email',
        'matter_list_phone_or_fax','matter_list_address','matter_list_matter_number','matter_list_inquiry_type',
        'matter_list_psychological_state','matter_list_category','matter_list_no_date_specified','matter_list_selected_status_flgs',
        'matter_list_product_code','matter_list_product_number','matter_list_product_name','matter_list_checked_flg','matter_list_page','matter_list_per_page',
        'oshop_matter_list_start_date','oshop_matter_list_end_date','oshop_matter_list_user_ids','oshop_matter_list_last_name',
        'oshop_matter_list_last_name_kana','oshop_matter_list_company_name','oshop_matter_list_company_name_kana','oshop_matter_list_email',
        'oshop_matter_list_phone_or_fax','oshop_matter_list_address','oshop_matter_list_matter_number','oshop_matter_list_inquiry_type',
        'oshop_matter_list_psychological_state','oshop_matter_list_category','oshop_matter_list_no_date_specified','oshop_matter_list_selected_status_flgs',
        'oshop_matter_list_product_code','oshop_matter_list_product_number','oshop_matter_list_product_name','oshop_matter_list_checked_flg','oshop_matter_list_page','oshop_matter_list_per_page',
        'sid_matter_list_start_date','sid_matter_list_end_date','sid_matter_list_user_ids','sid_matter_list_last_name',
        'sid_matter_list_last_name_kana','sid_matter_list_company_name','sid_matter_list_company_name_kana','sid_matter_list_email',
        'sid_matter_list_phone_or_fax','sid_matter_list_address','sid_matter_list_matter_number','sid_matter_list_inquiry_type',
        'sid_matter_list_psychological_state','sid_matter_list_category','sid_matter_list_no_date_specified','sid_matter_list_selected_status_flgs',
        'sid_matter_list_product_code','sid_matter_list_product_number','sid_matter_list_product_name','sid_matter_list_checked_flg','sid_matter_list_page','sid_matter_list_per_page',
        'all_matter_search_no_date_specified','all_matter_search_subtype_id','all_matter_search_server_flag','all_matter_search_end_date',
        'all_matter_search_classification_s_code','all_matter_search_classification_m_code','all_matter_search_classification_l_code',
        'all_matter_search_start_date','all_matter_search_product_list_code','all_matter_search_category_flg', 'all_matter_search_page','all_matter_search_per_page'];
      cookieParams.forEach(item =>
        this.$cookies.remove(item),
      )
      location.href = "/login"
    },
    // 新規案件追加
    addNewMatter: function () {
      let today = new Date()
      let strNow = today.getFullYear() + "/" + this.degital(today.getMonth() + 1, 2) + "/" + this.degital(today.getDate(), 2) + " " + this.degital(today.getHours(), 2) + ":" + this.degital(today.getMinutes(), 2) + ":" + this.degital(today.getSeconds(), 2);
      const item = {
        'matterId': -(today.getTime()),
        'matterTitle': '着信' + strNow
      }

      // 画面遷移先のpath
      const path = `/inquiryhandle/${item.matterId}`;
      // Vuexの「navbarMatterLists」に既に登録済の案件だった場合、追加処理は行わない
      // for(let i=0; i<this.$store.state.navbarMatterLists.length; i++) {
      //   if(this.$store.state.navbarMatterLists[i].matterLink === path) {
      //     // 対応画面へ画面遷移
      //     this.$router.push(path);
      //     return;
      //   }
      // }

      // Vuexの「navbarMatterLists」へ対応ページの情報をpush
      this.$store.state.navbarMatterLists.push({
        matterId: item.matterId,
        matterTitle: item.matterTitle,
        matterLink: path,
        matterPhone: true,
        serverFlag: constants.serverFlag.webcs,
      });
      // 対応画面へ画面遷移
      this.$router.push(path);
      console.log('Call addNewMatter function')
    },
    // 履歴検索
    historySearch() {
      // 検索キーワードを「localStorage」に保存
      if (localStorage.historySearchKeywords) {
        let keywordList = localStorage.getItem('historySearchKeywords');
        keywordList = JSON.parse(keywordList);
        // 既にlocalStorageに保存されているキーワードは追加しない
        if (keywordList.indexOf(this.historySearchKeyword) === -1) {
          // キーワード履歴の表示数を設定
          if (keywordList.length >= this.LimitKeywordHistory) {
            keywordList.splice(0, 1);
          }
          keywordList.push(this.historySearchKeyword);
          localStorage.setItem('historySearchKeywords', JSON.stringify(keywordList));
          this.keywordHistoryList = keywordList;
          console.log("localStorageに追加");
        }
      } else {
        let keywordList = [this.historySearchKeyword];
        localStorage.setItem('historySearchKeywords', JSON.stringify(keywordList));
        this.keywordHistoryList = keywordList;
        console.log("localStorageに初回登録");
      }
      this.historyTabFlg = false;
      this.historySearchResultModalFlg = true;
    },
    nextFocus() {
      document.getElementById('test').focus();
    },
    // 履歴検索結果モーダルを閉じる
    modalClose() {
      this.historySearchResultModalFlg = false;
    },
    // 履歴タブを開く
    openHistoryTab(historySearchFlg, historySearchKeyword) {
      this.historySearchFlg = historySearchFlg;
      this.historySearchKeyword = historySearchKeyword;
      this.historyTabFlg = true;
      this.historySearchResultModalFlg = true;
    },
    // 履歴タブを削除
    deleteHistoryTab(...args) {
      const [historySearchFlg, historySearchKeyword] = args;
      const inquiryHistoryList = this.$store.getters.getInquiryHistorySearchResultList;
      const deleteHistorySearch = inquiryHistoryList.filter(history => {
        if (history.historySearchFlg === historySearchFlg && history.historySearchKeyword === historySearchKeyword) {
          return true;
        }
      });
      const newHistorySearchList = inquiryHistoryList.filter(history => history.id !== deleteHistorySearch[0].id);
      this.$store.dispatch('deleteInquiryHistorySearchResultTabAction', newHistorySearchList);
      this.historySearchResultModalFlg = false;
    },
    // マウント時に検索履歴のoptionタグを生成
    createOptionTag() {
      const datalist = document.getElementById('historySearchList');
      let keywordList = localStorage.getItem('historySearchKeywords');
      if (keywordList){
        keywordList = JSON.parse(keywordList);
        for (let i = keywordList.length - 1; i >= 0; i--) {
          //option要素を新しく作る
          const option = document.createElement('option');
          option.value = keywordList[i];
          //select要素にoption要素を追加する
          datalist.appendChild(option);
        }
      }
    },
    async checkUnreadNotification(){
      let response = await notificationService.countUnreadNotifications();
      this.$store.commit('updateUnreadNotify',response.data.amount);
    }
  },
  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    notification() {
      return this.$store.state.unreadNotify;
    },
    userName() {
      // ログインユーザー名
      return this.$store.getters['auth/authUser'].fullName;
    },
    headerTitle() {
      // mapState内では、state === this.$store.state となる
      return this.$store.getters.getHeaderTitle;
    },
    // 履歴検索のボタンカラー変更
    historyButtonColor() {
      return function (key) {
        if (key === this.historySearchFlg) {
          return 'indigo darken-4';
        } else {
          return 'blue lighten-4';
        }
      };
    },
    historyButtonIconColor() {
      return function (key) {
        if (key === this.historySearchFlg) {
          return 'white';
        } else {
          return 'blue accent-3';
        }
      };
    },
    // 問い合わせ一覧(タブ)
    webcsMatterList() {
      return this.$store.getters.getMatterList;
    },
    oShopMatterList(){
      return this.$store.getters.getOShopMatterList;
    },
    sidMatterList(){
      return this.$store.getters.getSIDMatterList;
    },
    // 問い合わせ履歴一覧(タブ)
    inquiryHistorySearchResultList() {
      return this.$store.getters.getInquiryHistorySearchResultList;
    },
    //
    disableAddNewMatter(){
      return this.$store.state.navbarMatterLists.filter(item => item.matterId == 0).length > 0
    }
  },
  watch: {
    keywordHistoryList: function (newValue) {
      // datalist要素を取得
      const datalist = document.getElementById('historySearchList');
      // 子要素の確認と削除
      if (datalist.hasChildNodes()) {
        while (datalist.lastChild) {
          datalist.removeChild(datalist.lastChild);
        }
      }
      for (let i = newValue.length - 1; i >= 0; i--) {
        //option要素を新しく作る
        const option = document.createElement('option');
        option.value = newValue[i];
        //select要素にoption要素を追加する
        datalist.appendChild(option);
      }
    },
    '$route': {
      handler: function(val) {
        if (val.fullPath !== '/login'){
          if (this.$store.state.auth.user && !this.$store.state.auth.user.isViewer && !this.$store.state.auth.user.isGuest){
            this.checkUnreadNotification();
          }
        }
      },
      deep: true,
      immediate: true
    },
  },
  created(){
    if ((this.$store.state.auth.user && this.$store.state.auth.user.isViewer) ||
      (this.$store.state.auth.user &&  this.$store.state.auth.user.isGuest)){
      this.$store.dispatch('removeAllMatterListAction');
    }
    const keys = Object.keys(sessionStorage).filter(key => key.indexOf('matterTitle_') != -1)
    keys.forEach(key => sessionStorage.removeItem(key))
  }
}
</script>
<style scoped>
/* 履歴検索テキストカラー */
.history-text-color >>> .v-text-field__slot input {
  color: #1565c0;
}
.text-color-red{
  color: red;
}

.text-color-orange{
  color: orange;
}
</style>
