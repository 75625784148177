<template>
  <div style="background-color: #E0E0E0; height: 100%; padding-top: 1px;">
    <v-card color="grey lighten-5" class="ma-3" style="height: 97%">
      <v-card-title class="py-1">ダウンロード状況確認</v-card-title>
      <v-divider class="mx-4"></v-divider>
      <!-- 問い合せ一覧テーブル表示 -->
      <download-list-table :down-load-file-list="downloadList" @deleteFile="getDownloadList" @retryDownload="getDownloadList"/>

      <v-overlay :value="loadingCount > 0">
        <v-progress-circular indeterminate></v-progress-circular>
      </v-overlay>

    </v-card>
  </div>
</template>

<script>

import DownloadListTable from "@/components/parts/DownloadListTable";
import downloadService from "@/services/downloadService";
import {timeStampFormat} from "@/components/helpers/utils";

export default {
  components: {
    DownloadListTable,
  },
  // 画面表示時に行う処理
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.storeHeaderTitleUpdate();
      next();
    });
  },
  data() {
    return{
      loadingCount: 0,
      downloadList: [],
    }
  },

  methods: {
    // VuexのstateのheaderTitleを更新
    storeHeaderTitleUpdate() {
      this.$store.dispatch('headerTitleChangeAction', 6);
    },

    async getDownloadList(){
      this.loadingCount++;
      await downloadService.getDownloadList().then(response => {
        this.downloadList = response.data.data;
        if(this.downloadList.length > 0){
          this.downloadList.forEach(item => {
            item.created_at = timeStampFormat(item.created_at);
            item.download_period = item.download_period ? timeStampFormat(item.download_period) : null;
          });
        }
      });
      this.loadingCount--;
    }
  },
  async mounted() {
    await this.getDownloadList();
  }
}
</script>
<style scoped>

</style>
