<template>
<div>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="800"
    >
      <v-card>
        <v-toolbar color="primary" dark>
          <span class="text-h5 ml-3">パスワード再設定</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="resetValidation">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-form ref="form" @submit.prevent="changePassword">
            <v-row class="mt-4 d-flex justify-center">
              <v-alert class="col-12 text-center" v-if="passwordErrorResponse.flag"
                       outlined prominent
                       color="red">
                {{passwordErrorResponse.message}}
              </v-alert>
              <span  class="red--text"></span>
            </v-row>
            <v-row align="center" class="mt-4">
              <v-col cols="4">
                <p class="mb-0 text-subtitle-1 ml-16">旧パスワード</p>
              </v-col>
              <v-col cols="6">
                <v-text-field tabindex="1"
                              v-model="oldPassword"
                              :rules="[oldPwRequire]"
                              :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show1 ? 'text' : 'password'"
                              outlined
                              dense
                              @click:append="show1 = !show1"
                ></v-text-field>

              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="4">
                <p class="mb-0 text-subtitle-1 ml-16">新バスワード</p>
              </v-col>
              <v-col cols="6">
                <v-text-field tabindex="2"
                              v-model="newPassword"
                              :rules="[newPwReuire]"
                              :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show2 ? 'text' : 'password'"
                              outlined
                              dense
                              @click:append="show2 = !show2"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row align="center">
              <v-col cols="4">
                <p class="mb-0 text-subtitle-1 ml-16">新バスワード（確認）</p>
              </v-col>
              <v-col cols="6">
                <v-text-field tabindex="3"
                              v-model="newPasswordConfirm"
                              :rules="[confirmPwRequire,confirmPwMatch]"
                              :append-icon="show3 ? 'mdi-eye' : 'mdi-eye-off'"
                              :type="show3 ? 'text' : 'password'"
                              outlined
                              dense
                              @click:append="show3 = !show3"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-divider class="mx-4 mt-2"></v-divider>
            <v-card-actions class="justify-center py-3">
              <v-btn
                :disabled="!validPassword"
                rounded
                color="primary"
                x-large
                type="submit"
                class="px-8 mr-10 col-3"
              >変更</v-btn>
              <v-btn rounded color="warning" x-large class="px-12 col-3" @click="resetValidation">閉じる</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
      <v-dialog v-model="changePasswordLoading">
        <v-overlay>
          <v-progress-circular
            indeterminate
            size="64"
          ></v-progress-circular>
        </v-overlay>
      </v-dialog>
    </v-dialog>
  </v-row>
  <v-snackbar v-model="passwordChangeSuccess"
              :timeout="3000"
              absolute
              bottom
              multi-line
              color="success"
              right>
    <strong>パスワード変更が完了しました</strong>
  </v-snackbar>
</div>


</template>

<script>

import userService from "@/services/userService";

export default {
  props:["changePasswordDialog"],
  data(){
    return{
      // change user password
      dialog: false,
      changePasswordLoading: false,
      oldPassword: '',
      newPassword: '',
      show1: false,
      show2:false,
      show3: false,
      newPasswordConfirm: '',
      passwordChangeSuccess: false,
      validPassword: false,
      passwordErrorResponse: {
        flag: false,
        message: '',
      },
      passwordData: {},

      oldPwRequire: value => !! value || "旧パスワードを入力してください。",
      newPwReuire: v => !! v || "新パスワードを入力してください。",
      confirmPwRequire: v => !! v || "新パスワード（確認）を再入力してください。",
      confirmPwMatch: v => v == this.newPassword || "パスワードと確認パスワードが一致しません。",
    }
  },

  methods:{
    // change user password
    validate () {
      this.$refs.form.validate()
    },
    resetValidation () {
      this.oldPassword = '';
      this.newPassword = '';
      this.newPasswordConfirm = '';
      this.show1= false;
      this.show2=false;
      this.show3= false;
      this.passwordErrorResponse.flag = false;
      this.passwordErrorResponse.message = "";
      this.$refs.form.resetValidation()
      this.$emit('close');
      this.dialog = false;
    },
    async changePassword(){
      this.changePasswordLoading = true;
      this.passwordData.oldPassword = this.oldPassword;
      this.passwordData.newPassword = this.newPassword;
      this.passwordData.newPassword_confirmation = this.newPasswordConfirm;

      await userService.changeUserPassword(this.passwordData)
        .then(response => {
          if(response.status == 200){
            this.changePasswordLoading = false;
            this.passwordChangeSuccess = true;
            this.resetValidation();
          }
        })
        .catch(Error =>{
          // this.oldPassword = '';
          // this.newPassword ='';
          // this.newPasswordConfirm = '';
          this.passwordChangeSuccess = false;
          this.changePasswordLoading = false;
          this.passwordErrorResponse.flag = true;
          if(Error.response.status == 422){
            this.passwordErrorResponse.message = Error.response.data.message;
          } else{
            this.passwordErrorResponse.message = "エラーが発生しました。もう一度お試しください";
          }
        });


    },
    enableChangePass(){
      if (this.oldPassword != '' && this.newPassword != '' && this.newPassword == this.newPasswordConfirm){
        this.validPassword = true;
      } else this.validPassword = false;
    },
  },

  watch:{
    newPasswordConfirm:function (){
      this.enableChangePass();
    },
    oldPassword:function (){
      this.changePasswordLoading = false;
      this.enableChangePass();
    },

    newPassword:function (){
      this.enableChangePass();
    },

    // passwordChangeSuccess:function (){
    //   console.log()
    //   if()
    // },

    changePasswordDialog:{
      immediate:true,
      handler:function (value){
        if(value){
          this.dialog = true;
        }
      },
    }
  }
}
</script>

<style scoped>

</style>
