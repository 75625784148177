import http from '@/httpCommon.js';

// function sleep(ms) {
//   return new Promise((resolve) => {
//     setTimeout(resolve, ms);
//   });
// }

export default {
  async getMatterCount( userIds, serverFlag ) {
    if (serverFlag) {
      return http.get( '/get-matter-count/' + userIds + '?server_flg=' + serverFlag, {
      });

    } else {
      return http.get( '/get-matter-count/' + userIds, {
      });
    }
    // await sleep(500);
  },
};
