<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <span class="text-h5 ml-3">問い合わせ内容</span>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-row class="py-2">
        <v-col cols="12">
          <v-textarea
            v-if="inquiryContent.content_type"
            v-model="inquiryContent.content"
            outlined
            auto-grow
            solo
            flat
            hide-details
            row-height="80"
            placeholder="内容を入力してください"
            :background-color="getBackgroundColor(inquiryContent.content, inquiryContent.oldContent)"
          ></v-textarea>
          <quill-editor
              v-else id="edit-inquiryContent"
              v-model="inquiryTextContent"
              class="input textarea"
              :options="editorOption"
              :class ="getBackgroundColor(inquiryTextContent, inquiryContent.oldContent)"
              @focus="isFocusInquiryText = true"
          />
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn rounded color="primary" class="px-12 mx-5"
                 :disabled="inquiryContent.content_type ? !inquiryContent.content : !inquiryTextContent"
                 @click="saveCardTelRecodeModal">保存
          </v-btn>
          <v-btn
            rounded
            color="warning"
            class="px-10 mx-5"
            @click="$emit('InquiryContentsCardTelRecodeModalClose')"
          >閉じる
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import {getBackgroundColor} from '@/components/helpers/utils';
import { quillEditor } from "vue-quill-editor";

export default {
  computed: {
    backgroundColor() {
      return  this.getBackgroundColor(this.inquiryTextContent, this.inquiryContent.oldContent);
    },
  },
  components: {
    quillEditor
  },
  props: {
    // 問い合わせ履歴モーダル
    historyModalFlg: {
      type: Boolean,
    },
    inquiryContent: {
      type: Object,
    },
    // 親コンポーネントよりmatterIdを取得
    matterId: {
      type: Number,
    },
    // 親コンポーネントよりmatterIdを取得
    InquiryContentsCardTelRecodeModal: {
      type: Boolean,
    },
  },
  data() {
    return {
      // 最小化の表示を切り替えるflg
      minimizeFlg: false,
      editorOption: {
        modules: {
          toolbar: false
        },
      },
      inquiryTextContent:"",
      className:'',
      isFocusInquiryText: false,
    }
  },
  watch:{
    InquiryContentsCardTelRecodeModal:{
      immediate:true,
      handler(val){
        if (val == false){
          this.inquiryContent.content = this.inquiryContent.oldContent;
          this.inquiryTextContent = this.inquiryContent.content;
          this.$store.commit('updateBackgroundColor', '');
        }else if(val == true){
          if(this.inquiryContent && !this.inquiryContent.content_type ) {
            this.inquiryTextContent = this.inquiryContent.content;
          }
        }
      }
    },
    inquiryTextContent: {
      handler(){
        if(this.isFocusInquiryText && this.InquiryContentsCardTelRecodeModal){
          this.$emit('changeBackground', this.inquiryTextContent);
          this.$store.commit('updateBackgroundColor', this.backgroundColor);
        }
      }
    },
  },
  methods: {
    getBackgroundColor,
    async saveCardTelRecodeModal() {
      if(this.inquiryContent && !this.inquiryContent.content_type ) {
         this.inquiryContent.content = this.inquiryTextContent;
          this.inquiryTextContent = this.inquiryContent.oldContent;
      }
      this.$emit('saveCardTelRecode',this.matterId,this.inquiryContent)
      this.$store.commit('updateBackgroundColor', '');
    },
  },
}
</script>
<style lang="scss">
 #edit-inquiryContent {
   .ql-editor {
     min-height: 400px;
     padding: 5px 10px;
     border: 1px solid #1a202c;
     overflow-y: auto;
     outline: none;
      p {
        margin: 0;
        color: rgba(0, 0, 0, 0.87);
        font-size: 16px;
      }
   }
   .ql-clipboard {
     display: none;
   }
 }
</style>
