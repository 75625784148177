<template>
    <v-card class="elevation-4" color="blue lighten-4">
        <v-card-text class="py-3">
            <v-container fluid>
<!--                <v-row>
                    <v-col cols="auto">件名：</v-col>
                    <v-col>
                        <v-text-field
                            ma-0
                            pa-0
                            hide-details
                            flat
                            dense
                            v-model="inquiryContent.subject"
                            style="background: rgba(0,0,0,0);"
                            placeholder="件名を入力してください"
                        ></v-text-field>
                    </v-col>
                </v-row>-->
                <v-row>
                    <v-col cols="auto">内容：</v-col>
                    <v-col>
                        <v-textarea
                            v-if="inquiryContent.content_type"
                            ma-0
                            pa-0
                            hide-details
                            flat
                            dense
                            v-model="inquiryContent.content"
                            :disabled="historyModalFlg"
                            auto-grow
                            rows="5"
                            row-height="0"
                            placeholder="内容を入力してください"
                            :background-color="getBackgroundColor(inquiryContent.content, inquiryContent.oldContent)"
                        ></v-textarea>
                      <quill-editor v-else id="inquiryContent"  v-model="inquiryContent.content" :options="editorOption"
                                    :disabled="historyModalFlg && !InquiryContentsCardTelRecodeModal"
                                    :class="$store.state.classBackgrourdColorChangeForm || getBackgroundColor(inquiryContent.content, inquiryContent.oldContent)"
                      />
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>
<script>
import {getBackgroundColor} from '@/components/helpers/utils';
import { quillEditor } from "vue-quill-editor";

export default {
    components: {
      quillEditor
    },
    props: {
        inquiryContent: {
          type: Object,
        },
        // 親コンポーネントよりmatterIdを取得
        matterId: {
          type: Number,
        },
        historyModalFlg:{
            type: Boolean,
        },
        // 親コンポーネントよりmatterIdを取得
        InquiryContentsCardTelRecodeModal: {
          type: Boolean,
        },
    },
    data() {
        return {
            // 最小化の表示を切り替えるflg
            minimizeFlg: false,
            mountCompletion: false,
            editorOption: {
              modules: {
                toolbar: false
              },
              placeholder: ''
            },
        }
    },
    watch:{
      inquiryContent: {
        handler(val){
          if (this.mountCompletion){
            this.saveState(val.content)
          }
        },
        deep: true
      },
      $route() {
        this.mountCompletion = true;
        this.restoreState();
      },
      matterId: function(){
        this.mountCompletion = false;
      },
    },
    methods: {
      getBackgroundColor,
      restoreState() {
        let matterState = this.$store.getters.getMatterState(this.matterStateKey);
        if (typeof matterState === 'string')  this.inquiryContent.content = matterState
        else if (this.matterId <= 0) this.inquiryContent.content = '';
      },
      saveState(data) {
        this.$store.dispatch('saveMatterStateAction', { matterId: this.matterId, key: this.matterStateKey, data })
      },
    },
    computed: {
      matterStateKey(){
        return `matter_state_${this.matterId}_inquiry_first`;
      }
    },
    mounted() {
      this.mountCompletion = true;
      this.restoreState();
    }
}
</script>
<style lang="scss">
  #inquiryContent {
    .ql-editor {
      padding: 5px 10px;
      overflow-y: auto;
      outline: none;
      p {
        margin: 0;
        color: rgba(0, 0, 0, 0.87);
        font-size: 16px;
      }
    }
    .ql-clipboard {
      display: none;
    }
  }

</style>
