<template>
  <div>
    <v-card-title>
      <v-row align="center">
        <v-col cols="auto" class="py-0">
          <span class="text-h6">対応メモ</span>
        </v-col>
        <v-spacer></v-spacer>
      </v-row>
    </v-card-title>
    <v-divider class="mx-4"></v-divider>
    <v-card-text class="py-1">
      <v-row class="py-2">
        <v-col>
          <v-textarea
            v-if="matterMemoData"
            v-model="matterMemoData.memo"
            disabled
            outlined
            auto-grow
            solo
            flat
            hide-details
            row-height="35"
            :background-color="getBackgroundColor(matterMemoData.memo, matterMemoData.oldMemo)"
          ></v-textarea>
        </v-col>
      </v-row>
    </v-card-text>
  </div>
</template>
<script>
import {getBackgroundColor} from '@/components/helpers/utils';

export default {
    props: {
      matterMemoData:{
        type: [String, Number, Object]
      },
      // 親コンポーネントよりmatterIdを取得
      matterId: {
        type: Number,
      },
    },
    methods: {
      getBackgroundColor,
    },
}
</script>
<style scoped></style>
