import authService from "@/services/authService.js";

const state = {
  user: null,
};

const getters = {
  isAuthenticated: (state) => !!state.user,
  authUser: (state) => state.user,
};

const actions = {
  async login({ commit }, { loginId, password }) {
    await authService.login(loginId, password);
    const response = await authService.getMyInfo();
    commit("setUser", response.data.data);
  },
  async logout({ commit }) {
    await authService.logout();
    commit("setUser", null);
  },
};

const mutations = {
  setUser(state, user) {
    state.user = user;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
