<template>
  <v-card>
    <v-toolbar color="purple darken-3" dark>
      <span class="text-h5 ml-3">対応内容</span>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-row class="py-2">
        <v-col cols="12">
          <v-textarea
            v-model="correspondence"
            style="padding-top: 15px"
            outlined
            auto-grow
            solo
            flat
            hide-details
            row-height="80"
            placeholder="内容を入力してください"
            :background-color="!correspondence?'':'red lighten-5'"
          ></v-textarea>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-btn rounded color="primary" class="px-12 mx-5" :disabled="!correspondence"
                 @click="saveCorrespondenceModal">保存
          </v-btn>
          <v-btn
            rounded
            color="warning"
            class="px-10 mx-5"
            @click="$emit('InquiryContentsCorrespondenceInfoModalClose')"
          >閉じる
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  props: {
    // 親コンポーネントよりmatterIdを取得
    matterId: {
      type: Number,
    },
    matterStatusData: {
      type: Object,
    },
    InquiryContentsCorrespondenceInfoModal: {
      type: Boolean,
    }
  },
  data() {
    return {
      correspondence: ''
    }
  },
  watch: {
    InquiryContentsCorrespondenceInfoModal: {
      immediate: true,
      handler(val) {
        if(val) {
          this.correspondence = ''
        }
      }
    }
  },
  methods: {
    async saveCorrespondenceModal() {
      this.$emit('saveCorrespondence',this.matterId,this.correspondence)
    },
  },
}
</script>
<style scoped></style>
