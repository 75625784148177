<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <span class="text-h5 ml-3">検索条件</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeModal">
        <v-icon large>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-form ref="productSearchform" @submit.prevent="searchProductList(1)">
        <v-row align="center" class="mt-4">
          <v-col cols="4">
            <p class="mb-0 text-subtitle-1 ml-16">商品コード</p>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="productCode" autofocus outlined clearable hide-details dense></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4">
            <p class="mb-0 text-subtitle-1 ml-16">品番</p>
          </v-col>
          <v-col cols="4">
            <v-text-field v-model="productNumber" outlined clearable hide-details dense></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4">
            <p class="mb-0 text-subtitle-1 ml-16">商品名</p>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="productName" outlined clearable hide-details dense></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4">
            <p class="mb-0 text-subtitle-1 ml-16">商品名(カナ)</p>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="productNameKana" outlined clearable hide-details dense></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="4">
            <p class="mb-0 text-subtitle-1 ml-16">単品バーコード</p>
          </v-col>
          <v-col cols="6">
            <v-text-field v-model="barcode" outlined clearable hide-details dense></v-text-field>
          </v-col>
        </v-row>
        <!-- 検索ボタンと入力クリアボタン -->
        <v-card-actions class="justify-center py-3">
          <v-btn rounded color="success" x-large type="submit" class="px-8 mr-10">入力した内容で検索</v-btn>
          <v-btn rounded color="warning" x-large class="px-12" @click="productSearchReset">入力クリア</v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
    <v-divider class="mx-4 mt-2"></v-divider>
    <!-- 検索結果モーダル -->
    <v-dialog v-model="searchResulDialog" persistent max-width="95%" scrollable>
      <v-card>
        <v-toolbar color="primary" dark>
          <span class="text-h5 ml-3">検索結果（全件チェックを入れる時は検索結果の最下部までスクロールしてください）</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="clearData">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <!-- 検索結果の商品一覧テーブル -->
        <v-data-table 
          height="765px" 
          :items-per-page="-1" 
          :headers="searchResultHeaders" 
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc" 
          fixed-header 
          :items="scrollDataList" 
          :loading="loadingCount > 0" 
          loading-text="読み込み中"
          hide-default-footer 
          no-data-text="検索条件に一致する商品はありません" 
          class="elevation-3 mx-4 mb-2 mt-0"
          show-select
          v-model="productsSelected" 
        >
          <template v-slot:foot>
            <v-row v-intersect="infiniteScrolling"></v-row>
          </template>
        </v-data-table>
        
        <v-card-text style="height: 60px; overflow-y: hidden;">
          <v-card-actions class="justify-end" v-show="isShowButtonLoadMoreData">
            <v-btn rounded color="primary" x-medium class="px-4" style="pointer-events: none;">検索結果が複数あります ↓</v-btn>
          </v-card-actions>
        </v-card-text>

      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import getProductService from '@/services/getProductService.js';

export default {
  props: {
    arrayProductSelected: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      // 検索結果テーブルのページネーション関係
      sortBy: 'id',
      sortDesc: [true],
      page: 1,
      pageCount: null,
      // 1ページに表示する行数の指定
      itemsPerPage: 30,
      // 商品コード
      productCode: '',
      // 品番
      productNumber: '',
      // 商品名
      productName: '',
      // 商品名(カナ)
      productNameKana: '',
      // 単品バーコード
      barcode: '',
      // 検索結果テーブルのヘッダー
      searchResultHeaders: [
        { text: '商品コード', value: 'product_code' },
        { text: '品番', value: 'product_number' },
        { text: '商品名', value: 'product_name' },
        { text: '商品名(カナ)', value: 'product_name_kana' },
        { text: '単品バーコード', value: 'barcode' },
        // { text: '追加', value: 'addition', sortable: false },
      ],
      // 検索結果モーダルflg
      searchResulDialog: false,
      // loading時間
      loadingCount: 0,
      // scroll loading
      scrollDataList: [],
      productsSelected: [],
      isCheckedFromFormSearch1: false, 
      isShowButtonLoadMoreData: true,
    }
  },
  mounted() {
    this.isCheckedFromFormSearch1 = true
    this.productsSelected = this.arrayProductSelected
  },
  methods: {
    // 入力クリアボタンイベント 
    productSearchReset() {
      this.$refs.productSearchform.reset();
    },
    closeModal() {
      this.productSearchReset();
      this.$emit('modal-close');
    },
    clearData() {
      this.loadingCount = 0;
      this.scrollDataList = [];
      this.page = 1;
      this.pageCount = 0;
      this.searchResulDialog = false
    },
    // 商品/サービス情報の検索結果取得
    searchProductList: async function (page = null) {
      this.isShowButtonLoadMoreData = true;
      // 検索結果モーダルを開く
      if (page) this.page = page
      this.searchResulDialog = true;
      this.loadingCount++;
      try {
        const response = await getProductService.getProductSearchListMethodTwo(
          this.productCode,
          this.productNumber,
          this.productName,
          this.productNameKana,
          this.barcode,
          {
            limit: this.itemsPerPage,
            page: this.page,
            orderBy: !this.sortBy || this.sortBy.length == 0 ? 'id' : this.sortBy,
            orderDir: !this.sortDesc[0] ? 'asc' : 'desc'
          }
        );
        // 取得したデータを格納
        let searchResulProductList = response.data.data.data;
        this.pageCount = response.data.data.last_page
        if (searchResulProductList.length > 0) {
          searchResulProductList.forEach(item => {
            this.scrollDataList.push(item);
          });
        }
        if(this.page == this.pageCount) {
          this.isShowButtonLoadMoreData = false;
        }
      } catch (e) {
        console.log(e);
      }
      this.loadingCount--;
    },

    //scroll to load
    async infiniteScrolling() {
      if (this.page < this.pageCount) {
        this.page++
        await this.searchProductList()
      }
    },
  },
  watch: {
    async sortBy() {
      this.clearData()
      await this.searchProductList()
    },
    async sortDesc() {
      this.clearData()
      await this.searchProductList()
    },
    productsSelected(newVal, oldVal) {
      if(!this.isCheckedFromFormSearch1) {
        let productJustAdded = []
        let productJustRemoved = []
        let arrayEmit = []
        productJustAdded = newVal.filter(x => !oldVal.includes(x));
        productJustRemoved = oldVal.filter(x => !newVal.includes(x));
        if(productJustAdded.length) {
          arrayEmit = [...this.arrayProductSelected, ...productJustAdded]
        } else if(productJustRemoved.length) {
          arrayEmit = this.arrayProductSelected.filter(product => !productJustRemoved.includes(product))
        }
        this.$emit('products-selected-method-two-changed', arrayEmit)
      }
      this.isCheckedFromFormSearch1 = false
    },
  }
}
</script>
<style scoped>
/* テーブルのヘッダーのフォントサイズ */
.v-data-table ::v-deep th {
  font-size: 18px !important;
  background-color: #bdbdbd;
}

/* 検索結果が「０」の場合に表示するテキストのフォントサイズ */
::v-deep .v-data-table__empty-wrapper>td {
  font-size: 20px !important;
}
</style>
