import http from '@/httpCommon.js';

export default {
  async searchNotifications(conditions) {
    return http.get('/notification-search', { params: conditions });
  },
  async getNotificationsForDashboard() {
    return http.get('/notifications-for-dashboard');
  },
  async createNotification(notification) {
    return http.post(`/notifications`, notification);
  },
  async updateNotification(notificationId, notification) {
    return http.put(`/notifications/${notificationId}`, notification);
  },
  async deleteNotifications(notifications) {
    const ids = notifications.map((notification) => notification.id);
    return http.delete(`/notifications`, { params: { id: ids } });
  },
  async createNotificationReader(notification) {
    return http.post(`/notifications-reader`, notification);
  },
  async countUnreadNotifications() {
    return http.get(`/count-unread-notifications`);
  },
};
