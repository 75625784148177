import http from "@/httpCommon.js";

export default {
  async login(loginId, password) {
    await http.get("/sanctum/csrf-cookie");
    return http.post("/login", {
      login_id: loginId,
      password: password,
    });
  },
  async logout() {
    return http.post("/logout");
  },
  async getMyInfo() {
    return http.get("/my-info");
  },
};
