<template>
    <v-card>
        <v-toolbar color="primary" dark dense>
            <span class="text-h6 ml-3">商品情報</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="$emit('modal-close')">
                <v-icon large>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>

        <v-divider class="mx-4 mb-2"></v-divider>

        <v-card-text>
            <v-row align="center" class="pt-3">
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">商品/サービス種別</p>
                </v-col>
                <v-col cols="4" class="py-0">
                    <!-- <v-select
                            :items="productTypeList"
                            v-model="productType"
                            solo
                            outlined
                            hide-details
                            dense
                            flat
                            readonly
                    ></v-select>-->
                    <v-text-field v-model="productType" outlined hide-details dense readonly></v-text-field>
                </v-col>
            </v-row>

            <v-row align="center" class="pt-3">
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">商品コード</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="productCode" outlined hide-details dense readonly></v-text-field>
                </v-col>
                <v-col offset="2" cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">統一分類(大分類)</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <!-- <v-select
                            :items="unifiedClassificationLList"
                            v-model="unifiedClassificationL"
                            solo
                            outlined
                            hide-details
                            dense
                            flat
                            readonly
                    ></v-select>-->
                    <v-text-field
                        v-model="unifiedClassificationL"
                        outlined
                        hide-details
                        dense
                        readonly
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="pt-3">
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">品番</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="productNumber" outlined hide-details dense readonly></v-text-field>
                </v-col>
                <v-col offset="2" cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">統一分類(中分類)</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <!-- <v-select
                            :items="unifiedClassificationMList"
                            v-model="unifiedClassificationM"
                            solo
                            outlined
                            hide-details
                            dense
                            flat
                            readonly
                    ></v-select>-->
                    <v-text-field
                        v-model="unifiedClassificationM"
                        outlined
                        hide-details
                        dense
                        readonly
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="pt-3">
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">商品名</p>
                </v-col>
                <v-col cols="4" class="py-0">
                    <v-text-field v-model="productName" outlined hide-details dense readonly></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">統一分類(小分類)</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <!-- <v-select
                            :items="unifiedClassificationSList"
                            v-model="unifiedClassificationS"
                            solo
                            outlined
                            hide-details
                            dense
                            flat
                            readonly
                    ></v-select>-->
                    <v-text-field
                        v-model="unifiedClassificationS"
                        outlined
                        hide-details
                        dense
                        readonly
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="pt-3">
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">商品名(カナ)</p>
                </v-col>
                <v-col cols="4" class="py-0">
                    <v-text-field v-model="productNameKana" outlined hide-details dense readonly></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="pt-3">
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">単品バーコード</p>
                </v-col>
                <v-col cols="4" class="py-0">
                    <v-text-field v-model="barcode" outlined hide-details dense readonly></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">小売価格</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="retailPrice" outlined hide-details dense readonly></v-text-field>
                </v-col>
            </v-row>

            <v-row align="center" class="pt-3">
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">色名</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="colorName" outlined hide-details dense readonly></v-text-field>
                </v-col>
                <v-col offset="2" cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">オープン価格区分</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <!-- <v-select
                            :items="openPriceTypeList"
                            v-model="openPriceType"
                            solo
                            outlined
                            hide-details
                            dense
                            flat
                            readonly
                    ></v-select>-->
                    <v-text-field v-model="openPriceType" outlined hide-details dense readonly></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="pt-3">
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">製品サイズ:幅</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="sizeWidth" outlined hide-details dense readonly></v-text-field>
                </v-col>
                <v-col offset="2" cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">参考価格A</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="referencePriceA" outlined hide-details dense readonly></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="pt-3">
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">製品サイズ:奥行き</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="sizeDepth" outlined hide-details dense readonly></v-text-field>
                </v-col>
                <v-col offset="2" cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">代替品番</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field
                        v-model="alternativeProductNumber"
                        outlined
                        hide-details
                        dense
                        readonly
                    ></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="pt-3">
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">製品サイズ:高さ</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="sizeHeight" outlined hide-details dense readonly></v-text-field>
                </v-col>
                <v-col offset="2" cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">発売年月日</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="releaseDate" outlined hide-details dense readonly></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="pt-3">
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">製品サイズ:重量</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="sizeWeight" outlined hide-details dense readonly></v-text-field>
                </v-col>
                <v-col offset="2" cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">カタログ掲載終了</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="catalogEndDate" outlined hide-details dense readonly></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="pt-3">
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">中箱１入数</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="middleBoxQuantity" outlined hide-details dense readonly></v-text-field>
                </v-col>
                <v-col offset="2" cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">環境対応フラグ</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="envSupportFlg" outlined hide-details dense readonly></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="pt-3">
                <v-col cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">梱箱入数</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="packingBoxQuantity" outlined hide-details dense readonly></v-text-field>
                </v-col>
                <v-col offset="2" cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">エコマークフラグ</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="ecoMarkFlg" outlined hide-details dense readonly></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="pt-3">
                <v-col offset="6" cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">エコマーク再生樹脂比率</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="ecoMarkRate" outlined hide-details dense readonly></v-text-field>
                </v-col>
            </v-row>
            <v-row align="center" class="pt-3 pb-2">
                <v-col offset="6" cols="2" class="py-0">
                    <p class="mb-0 text-subtitle-1 ml-16">グリーン購入法</p>
                </v-col>
                <v-col cols="2" class="py-0">
                    <v-text-field v-model="greenPurchaseLaw" outlined hide-details dense readonly></v-text-field>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    props: {
        // 親コンポーネントよりダブルクリックされた商品情報の取得
        productInfo: {
            type: [String, Number]
        },
    },
    data() {
        return {
            // 商品/サービス種別一覧
            // productTypeList: ['商品マスタ','商品マスタ1'],
            // 商品/サービス種別
            productType: '商品マスタ1',
            // 商品コード
            productCode: '00000',
            // 品番
            productNumber: '0000',
            // 商品名
            productName: 'TEST',
            // 商品名(カナ)
            productNameKana: 'テスト',
            // 統一分類(大分類)一覧
            // unifiedClassificationLList: ['大分類テスト', '大分類1', '大分類2', '大分類3', '大分類4'],
            // 統一分類(大分類)
            unifiedClassificationL: '大分類テスト',
            // 統一分類(中分類)一覧
            // unifiedClassificationMList: ['中分類テスト', '中分類1', '中分類2', '中分類3', '中分類4'],
            // 統一分類(中分類)
            unifiedClassificationM: '中分類テスト',
            // 統一分類(中分類)一覧
            // unifiedClassificationSList: ['小分類テスト', '小分類1', '小分類2', '小分類3', '小分類4'],
            // 統一分類(小分類)
            unifiedClassificationS: '小分類テスト',
            // 単品バーコード
            barcode: '4912345678904',
            // 小売価格
            retailPrice: '000',
            // 色名
            colorName: '赤',
            // 製品サイズ:幅
            sizeWidth: '000',
            // 製品サイズ:奥行き
            sizeDepth: '000',
            // 製品サイズ:高さ
            sizeHeight: '000',
            // 製品サイズ:重量
            sizeWeight: '000',
            // 中箱１入数
            middleBoxQuantity: '00',
            // 梱箱入数
            packingBoxQuantity: '00',
            // オープン価格区分一覧
            // openPriceTypeList: ['オープン価格区分テスト', 'オープン価格区分1', 'オープン価格区分2', 'オープン価格区分3', 'オープン価格区分4'],
            // オープン価格区分
            openPriceType: 'オープン価格区分テスト',
            // 参考価格A
            referencePriceA: '000',
            // 代替品番
            alternativeProductNumber: '000',
            // 発売年月日
            releaseDate: '2022/11/11',
            // カタログ掲載終了
            catalogEndDate: 'test',
            // 環境対応フラグ
            envSupportFlg: 'test',
            // エコマークフラグ
            ecoMarkFlg: 'test',
            // エコマーク再生樹脂比率
            ecoMarkRate: 'test',
            // グリーン購入法
            greenPurchaseLaw: 'test',

            // 最小化の表示を切り替えるflg
            minimizeFlg: false,
        }
    },
    methods: {
        // 親コンポーネントから取得した商品情報を各変数に代入
        productDataAssign(productInfo) {
            this.productType = productInfo.productType;
            this.productCode = productInfo.productCode;
            this.productNumber = productInfo.productNumber;
            this.productName = productInfo.productName;
            this.productNameKana = productInfo.productNameKana;
            this.unifiedClassificationL = productInfo.unifiedClassificationL;
            this.unifiedClassificationM = productInfo.unifiedClassificationM;
            this.unifiedClassificationS = productInfo.unifiedClassificationS;
            this.barcode = productInfo.barcode;
            this.retailPrice = productInfo.retailPrice;
            this.colorName = productInfo.colorName;
            this.sizeWidth = productInfo.sizeWidth;
            this.sizeDepth = productInfo.sizeDepth;
            this.sizeHeight = productInfo.sizeHeight;
            this.sizeWeight = productInfo.sizeWeight;
            this.middleBoxQuantity = productInfo.middleBoxQuantity;
            this.packingBoxQuantity = productInfo.packingBoxQuantity;
            this.openPriceType = productInfo.openPriceType;
            this.referencePriceA = productInfo.referencePriceA;
            this.alternativeProductNumber = productInfo.alternativeProductNumber;
            this.releaseDate = productInfo.releaseDate;
            this.catalogEndDate = productInfo.catalogEndDate;
            this.envSupportFlg = productInfo.envSupportFlg;
            this.ecoMarkFlg = productInfo.ecoMarkFlg;
            this.ecoMarkRate = productInfo.ecoMarkRate;
            this.greenPurchaseLaw = productInfo.greenPurchaseLaw;
        },
    },
    mounted() {
        // ダブルクリックされた商品データを各変数に代入
        this.productDataAssign(this.productInfo);
    }
}
</script>
<style scoped>
</style>