<template>
  <v-card class="grey lighten-2" height="100%">
    <div style="background-color: #E0E0E0;" class="py-1">
      <!-- {{ this.$route.params.id }} -->
      <div id="inquiryStatusContainer" class="grey lighten-2" style="position: fixed;top: 61px;right: 0;left: 256px;z-index: 2;padding-top: 4px">
        <InquiryStatus style="height: 295px !important;"
          v-if="mountCompletion"
          ref="inquiryStatus"
          :matterId="matterId"
          @closeEdit="closeEditing()"
          :matterCustomerData="matterCustomerData"
          :matterProductData="matterProductsData"
          :customerPhoneNumber1="customerPhoneNumber1"
          :readonlyFlg="readonlyFlg"
          :has-shipping-data="hasShippingData"
          v-bind:matterStatusData.sync="matterStatusData"
          v-bind:customerDifferentDataList.sync="customerDifferentDataList"
          v-bind:matterInquiriesData.sync="matterInquiriesData"
          @update:matterStatus="saveAllMatter"
          @openShippingModal="openShippingModal"
          @nd-display-tel-search="getNdDisplayTelSearch"
          @copy-nd-display-tel="copyNdDisplayTel"
          @unannouncedSwitchEvent="unannouncedSwitchEvent"
          @isEditing="showEditingDialog"
        />
      </div>
      <div id="customerInformationContainer" :class="[customerMinimizeMode?'customer-sticky':'']" :style="{top: customerMinimizeMode?`${inquiryStatusHeight+60}px`:'inherit' }">
        <CustomerInformation
          :style="{marginTop: customerMinimizeMode ? '': `${inquiryStatusHeight}px!important`}"
          v-if="mountCompletion && !$store.state.auth.user.isViewer && !$store.state.auth.user.isGuest"
          ref="customerInformation"
          :matterId="matterId"
          :ndDisplayTelSearch="ndDisplayTel"
          :readonlyFlg="readonlyFlg"
          v-bind:matterCustomerData.sync="matterCustomerData"
          v-bind:customerDifferentDataList.sync="customerDifferentDataList"
          @changePhoneNumber1="changePhoneNumber1"
          @create:matterCustomer="saveMatter"
          @isEditing="showEditingDialog"
          @changeMinimizeMode="changeCustomerMinimizeMode"
        />
      </div>
      <div id="productInformationContainer"
          :class="[($store.state.auth.user.isViewer || $store.state.auth.user.isGuest || customerMinimizeMode) && productMinimizeMode?(($store.state.auth.user.isViewer || $store.state.auth.user.isGuest)?'product-viewer-sticky':'product-sticky'):'']"
          :style="{top: ($store.state.auth.user.isViewer || $store.state.auth.user.isGuest || customerMinimizeMode) && productMinimizeMode?(($store.state.auth.user.isViewer || $store.state.auth.user.isGuest)?`${inquiryStatusHeight+60}px`:`${inquiryStatusHeight + customerInformationHeight+60}px`):'auto' }">
        <ProductInformation
            :style="{marginTop: (($store.state.auth.user.isViewer || $store.state.auth.user.isGuest || customerMinimizeMode) && !productMinimizeMode)? (($store.state.auth.user.isViewer || $store.state.auth.user.isGuest )?`${inquiryStatusHeight-5}px!important`:`${customerInformationHeight + inquiryStatusHeight -5}px!important`) : ''}"
            v-if="mountCompletion"
            ref="productInformation"
            :matterId="matterId"
            :readonlyFlg="readonlyFlg"
            v-bind:matterStatusData.sync="matterStatusData"
            v-bind:matterProductsData.sync="matterProductsData"
            @create:matterProduct="saveMatter"
            @isEditing="showEditingDialog"
            @changeMinimizeMode="changeProductMinimizeMode"
        />
      </div>
      <InquiryContents
        :style="{marginTop: (($store.state.auth.user.isViewer || $store.state.auth.user.isGuest || customerMinimizeMode) && productMinimizeMode)? (($store.state.auth.user.isViewer || $store.state.auth.user.isGuest )?`${inquiryStatusHeight + productInformationHeight}px!important`:`${inquiryStatusHeight + customerInformationHeight + productInformationHeight}px!important`) : ''}"
        v-if="mountCompletion"
        ref="inquiryContents"
        :historyModalFlg="false"
        :readonlyFlg="readonlyFlg"
        :matterId="matterId"
        :exMatterNumber="matterData.exMatterNumber"
        :sidMatterNumber="matterData.sidMatterNumber"
        :matterCustomerData="matterCustomerData"
        :matterProductData="matterProductsData"
        v-bind:matterStatusData.sync="matterStatusData"
        v-bind:matterInquiriesData.sync="matterInquiriesData"
        v-bind:matterShipmentData.sync="matterShipmentData"
        @reloadMatterShipmentData="reloadShipmentData"
        :has-shipping-data="hasShippingData"
        @create:matterInquiry="saveMatter"
        :shippingModal="shippingModal"
        @closeShippingModal="closeShippingModal"
        @isEditing="showEditingDialog"
      />

      <template>
        <v-row justify="center">
          <v-dialog
            v-model="isEditingDialog"
            persistent
            max-width="600"
          >
            <v-card v-if="editingBy">
              <v-card-title class="text-h5 justify-center">
                <span><span style="color: #fb8c00">{{editingBy}}</span>さんが編集しています。</span>
              </v-card-title>
              <v-divider></v-divider>

               <v-card-actions class="justify-center">
                 <v-btn
                   rounded
                   large
                   color="warning"
                   @click="isEditingDialog = false"
                 >
                   閉じる
                 </v-btn>
               </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>

      <!-- 他の方が対応中の案件を開いた時に表示するモーダル -->
      <!-- <v-dialog v-model="matterWorkFlg" scrollable max-width="95%">
      <v-card>
        <v-toolbar color="primary" dark>
            <span class="text-h5 ml-3">{{}}さんが対応中の問い合せ</span>
            <v-spacer></v-spacer>
            <v-btn icon @click="matterWorkModalClose">
                <v-icon large>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
      </v-card>
      </v-dialog>-->
    </div>

    <!-- ローディング中にスピナーを表示させる -->
    <v-overlay :value="loadingCount > 0" >
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import InquiryStatus from '../components/parts/InquiryStatus.vue'
import CustomerInformation from '../components/parts/CustomerInformation.vue'
import ProductInformation from '../components/parts/ProductInformation.vue'
import InquiryContents from '../components/parts/InquiryContents.vue'
import getMatterService from '@/services/getMatterService.js';
import moment from 'moment';
import updateMatterService from "@/services/updateMatterService";

export default {
  name: 'InquiryHandle',

  // 画面表示時に行う処理
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.storeHeaderTitleUpdate();
      next();
    });
  },
  data() {
    return {
      // 問い合せ一覧で指定された問い合せのidを取得
      matterId: Number(this.$route.params.id),
      //
      // loading時間
      loadingCount: 0,
      // 問い合せ内容格納
      matterData: '',
      // 問い合せ内容(状態パネル)格納
      matterStatusData: '',

      // 問い合せ内容(お客様情報パネル)格納
      matterCustomerData: '',
      // 問い合せ内容(商品情報パネル)格納__複数1つのみ想定
      matterProductsData: '',
      // 問い合せ内容(問い合せ内容パネル)格納
      matterInquiriesData: [],
      //
      //
      matterShipmentData: [],
      // mount処理完了フラグ
      mountCompletion: false,

      // 他の方が対応中の案件を表示する時のモーダルフラグ
      matterWorkFlg: false,

      // お客様情報パネルから取得した保存されていない変更項目リスト
      customerDifferentDataList: [],

      // 読み取り専用で開く場合のフラグ
      readonlyFlg: false,

      //
      shippingModal: false,
      ndDisplayTel: null,

      //todo check const current editing

      isEditingDialog: false,
      viewingBy: this.$store.getters['auth/authUser'].id,
      editingBy: null,
      customerPhoneNumber1: '',
      customerMinimizeMode: false,
      productMinimizeMode: false,
      customerInformationHeight: 0,
      inquiryStatusHeight: 0,
      productInformationHeight: 0,
    }
  },
  components: {
    InquiryStatus,
    CustomerInformation,
    ProductInformation,
    InquiryContents,
  },
  computed:{
    hasShippingData(){
      return this.matterShipmentData && this.matterShipmentData.length > 0;
    },
  },
  methods: {
    observeHeight() {
      let root = this;
      const resizeObserver = new ResizeObserver(entries => {
        entries.forEach(entry => {
          const rect = entry.contentRect;
          if (entry.target && entry.target.id == 'customerInformationContainer'){
            root.customerInformationHeight = rect.height - 5;
          }else if (entry.target && entry.target.id == 'inquiryStatusContainer'){
            root.inquiryStatusHeight = rect.height - 3;
          }else if (entry.target && entry.target.id == 'productInformationContainer'){
            root.productInformationHeight = rect.height - 8;
          }
        })
      });

      if(!this.$store.state.auth.user.isViewer && !this.$store.state.auth.user.isGuest ){
        resizeObserver.observe(document.getElementById('customerInformationContainer'));
      }
      resizeObserver.observe(document.getElementById('inquiryStatusContainer'));
      resizeObserver.observe(document.getElementById('productInformationContainer'));
    },
    changePhoneNumber1(phoneNumber1){
      this.customerPhoneNumber1 = phoneNumber1
    },
    unannouncedSwitchEvent(unannounced){
      if (unannounced === true){
        this.$refs.customerInformation.phoneNumber1 = '非通知'
      }else {
        this.$refs.customerInformation.phoneNumber1 = ''
      }
    },
    // VuexのstateのheaderTitleを更新
    storeHeaderTitleUpdate() {
      this.$store.dispatch('headerTitleChangeAction', 2);
    },
    getNdDisplayTelSearch(ndDisplayTel){
      this.ndDisplayTel = ndDisplayTel;
    },
    copyNdDisplayTel(ndDisplayTel){
      this.$refs.customerInformation.phoneNumber1 = ndDisplayTel;
    },
    async saveAllMatter(matter){
      if (matter.matterId < 0){
        matter.matterId = 0;
      }
      this.loadingCount++;
      await this.$refs.customerInformation.saveAllMatter(matter.matterId);
      await this.$refs.inquiryContents.saveAllMatter(matter.matterId);
      this.loadingCount--;
      await this.saveMatter(matter);
    },
    async saveMatter(matter){
      if (this.matterId <= 0) {
        // Vuexの「navbarMatterLists」へ対応ページの情報をpush
        const path = `/inquiryhandle/${matter.matterId}`;

        let matterTitle = matter.basicInfo.title;
        if (!matterTitle){
          for (let i = 0; i < this.$store.state.navbarMatterLists.length; i++) {
            if (this.$store.state.navbarMatterLists[i].matterId == this.matterId) {
              matterTitle = this.$store.state.navbarMatterLists[i].matterTitle
              break
            }
          }
        }

        const newNavbarMatter = {
          matterId: matter.matterId,
          matterTitle: matterTitle,
          matterLink: path,
          matterPhone: matter.inquiries[0].inquiryType ? true : false,
          matterUrgency:  matter.basicInfo.urgencyFlg,
          serverFlag: matter.basicInfo.server_flg,
        };
        await this.$store.dispatch('removeMatterListAction', this.matterId);

        await this.$store.dispatch('updateNavbarMatterListAction', newNavbarMatter);

        await this.$store.dispatch('updateMatterStateKeyAction', {oldMatterId: this.matterId, newMatterId: matter.matterId});
        sessionStorage.removeItem(`matterTitle_` + this.matterId);

        // 対応画面へ画面遷移
        await this.$router.push(path);
      }
    },

    // 問い合わせ情報取得
    getMatter: async function (matterId) {
      this.loadingCount++;
      let response = {};
      // matterId is 新規案件. バックエンドから貰ったふりをする。
      if (0 > matterId) {
        // 読み取り専用で開く場合のフラグ
        this.readonlyFlg = false

        let matterTitle = ""
        for (let i = 0; i < this.$store.state.navbarMatterLists.length; i++) {
          if (this.$store.state.navbarMatterLists[i].matterId == matterId) {
            matterTitle = this.$store.state.navbarMatterLists[i].matterTitle
            break
          }
        }
        let now = moment(Math.abs(matterId));
        const response = {
          "matterId": matterId,
          "basicInfo": {
            "matterNumber": "",
            "idPerDate": 1,
            "title": matterTitle,
            "memo": null,
            "productLot": null,
            "oldProductLot": null,
            "oldMemo": null,
            "statusFlg": 1,
            "userId": this.$store.state.auth.user.id,
            "call_end_time": null,
            "inquiryType": 1,
            "firstInquiryTime": now.format('YYYY-MM-DDTHH:mm:ss.SSSSSSZ'),
            "lastInquiryTime": null
          },
          "customer": {
            "id": null,
            "lastName": null,
            "firstName": null,
            "lastNameKana": null,
            "firstNameKana": null,
            "email1": "",
            "email2": null,
            "phoneNumber1": null,
            "phoneNumber2": null,
            "customerTypeFlg": null,
            "companyName": null,
            "companyNameKana": null,
            "gender": null,
            "industryName": null,
            "departmentName": null,
            "postalCode": null,
            "prefectureName": null,
            "address": null,
            "faxNumber": null,
            "retailerName": null,
            "remarks": null,
            "createdAt": null,
            "updatedAt": null,
            "updatedBy": null,
            "updaterName": null
          },
          "product": {
            "id": null,
            "productType": null,
            "productCode": null,
            "productNumber": null,
            "productName": null,
            "productNameKana": null,
            "unifiedClassificationL": null,
            "unifiedClassificationM": null,
            "unifiedClassificationS": null,
            "barcode": null,
            "retailPrice": null,
            "colorName": null,
            "sizeWidth": null,
            "sizeDepth": null,
            "sizeHeight": null,
            "sizeWeight": null,
            "middleBoxQuantity": null,
            "packingBoxQuantity": null,
            "openPriceType": null,
            "referencePriceA": null,
            "alternativeProductNumber": null,
            "releaseDate": null,
            "catalogEndDate": null,
            "envSupportFlg": null,
            "ecoMarkFlg": null,
            "ecoMarkRate": null,
            "greenPurchaseLaw": null,
            "createdAt": null,
            "updatedAt": null
          },
          "productsForMatter": [],
          "shippingHistories": [],
          "inquiries": [
            {
              "id": null,
              "userId": null,
              "receiveSendFlg": 0,
              "inquiryType": 1,
              "receiveMailId": null,
              "sendMailId": null,
              "receiveTime": new Date().toISOString(),
              "sendTime": null,
              "subject": "",
              "content": "",
              "content_type": 1,
              "oldContent": "",
              "createdAt":  null,
              "updatedAt":  null
            }
          ]
        }
        this.matterData = response
        this.matterStatusData = response.basicInfo;
        this.matterCustomerData = response.customer;
        this.matterProductsData = response.product;
        this.matterInquiriesData = response.inquiries;
        this.mountCompletion = true;
        this.loadingCount--;
        return;
      }
      try {
        /*         const response = await getMatterService.getMatter(matterId); */
        response = await getMatterService.getMatter(matterId);
        // 取得したデータを格納
        this.matterData = response.data.data;
        this.matterStatusData = response.data.data.basicInfo;
        this.matterStatusData.oldMemo = this.matterStatusData.memo;
        this.matterStatusData.oldProductLot = this.matterStatusData.productLot;
        this.matterCustomerData = response.data.data.customer;
        // this.matterProductsData = response.data.data.productsForMatter; // 商品を複数追加可能
        this.matterProductsData = response.data.data.product; // 商品は1つのみ
        // this.matterInquiriesData = response.data.data.inquiries; //apiの作成が完了したらこちらのコメントアウトを解除する

        // console.log('案件データ一式:', this.matterData);
        // console.log('状態:', this.matterStatusData);
        // console.log('お客様情報:', this.matterCustomerData);
        // console.log('商品情報:', this.matterProductsData);
        // console.log('問い合わせ内容:', this.matterInquiriesData);
        this.mountCompletion = true;
      } catch (e) {
        console.log(e);
      }
      this.loadingCount--;
    },
    async reloadShipmentData(){
      let shippingResponse = await getMatterService.getShippings(this.matterId);
      this.matterShipmentData = shippingResponse.data.data;
    },
    // APIの作成が完了したらAPIよりデータを取得するため、下の処理は消してよい
    // aMatterInquiriesDatにテストデータを代入
    async updateMatterInquiriesData(matterId) {
      let correspondences = [];
      let inquiries = [];
      this.matterShipmentData = [];
      if (matterId > 0){
        let correspondenceResponse = await getMatterService.getCorrespondence(matterId);
        let shippingResponse = await getMatterService.getShippings(matterId);
        let inquiryResponse = await getMatterService.getInquiry(matterId);

        correspondences = correspondenceResponse.data.data;
        inquiries = inquiryResponse.data.data;

        this.matterShipmentData = shippingResponse.data.data;
      }
      this.matterInquiriesData = [];

      if (inquiries && inquiries.length > 0){
        inquiries.forEach(element => {
          this.matterInquiriesData.push({
            "id": element.id,
            "userId": element.userId,
            "userName": element.createdBy,
            "receiveSendFlg": element.receiveSendFlg,
            "inquiryType": element.inquiryType,
            "receiveMailId": element.receiveMailId,
            "sendMailId": element.sendMailId,
            "receiveTime": element.receiveTime,
            "sendTime": element.sendTime,
            "subject": element.subject,
            "content": element.content,
            "content_type": element.content_type,
            "oldContent": element.content,
            "correspondenceInfo": null,
            "shippingHistoryId": null,
            "createdAt": element.sendTime,
            "updatedAt": element.updatedAt
          })
        });
      }
      if (correspondences && correspondences.length > 0){
        correspondences.forEach(element => {
            this.matterInquiriesData.push({
            "id": `corr-${element.id}`,
            "userId": null,
            "userName": element.created_by,
            "receiveSendFlg": null,
            "inquiryType": null,
            "receiveMailId": null,
            "sendMailId": null,
            "receiveTime": null,
            "sendTime": null,
            "subject": null,
            "content": null,
            "correspondenceInfo": element.correspondence_content,
            "oldCorrespondenceInfo": element.correspondence_content,
            "shippingHistoryId": null,
            "createdAt": element.created_at,
            "updatedAt": element.created_at
          })
        });
      }
      if (this.matterInquiriesData.length > 0){
        this.matterInquiriesData.sort((a, b) => a.createdAt > (b.createdAt));
      }
    },
    openShippingModal() {
      this.shippingModal = true
    },
    closeShippingModal() {
      this.shippingModal = false
    },

    //set const current actions
    async updateMatterEditingStatus(matterId,editing = true){
      await updateMatterService.updateMatterEditingStatus(matterId,{editing: editing}).catch(error => {
        if(error.response.status === 403 && editing){
          this.showEditingDialog(error.response.data.message.editing_by);
        }
      });
    },

    async closeEditing(){
      if(!this.readonlyFlg){
        await this.updateMatterEditingStatus(this.matterId,false);
      }
    },

    showEditingDialog(editingBy){
      this.isEditingDialog = true;
      this.editingBy = editingBy;
      this.readonlyFlg = true;
    },
    changeCustomerMinimizeMode(val){
      this.customerMinimizeMode = val;
    },
    changeProductMinimizeMode(val){
      this.productMinimizeMode = val;
    }
    //
  },

  async mounted() {
    this.observeHeight();
    // 問い合わせ内容にテストデータを代入
    await this.updateMatterInquiriesData(this.matterId); //api作成完了したらこの処理は不要
    // 問い合せ情報取得
    await this.getMatter(this.matterId);
    if(this.matterId > 0 && !this.readonlyFlg){
      await this.updateMatterEditingStatus(this.matterId, true);
    }
  },

  created(){
    this.$store.state.auth.user.isGuest ? this.readonlyFlg = this.$store.state.auth.user.isGuest : this.readonlyFlg = this.$store.state.auth.user.isViewer;
  },

  // URL の id が変わったときに子コンポーネントのreload関数を呼び出す
  async beforeRouteUpdate(to, from, next) {
    // matterIdを更新
    this.matterId = Number(to.params.id);
    // 問い合わせ内容にテストデータを代入
    await this.updateMatterInquiriesData(this.matterId); //api作成完了したらこの処理は不要
    // 問い合わせ情報取得
    await this.getMatter(this.matterId);
    // 子コンポーネント(状態パネル)のreload関数を実行
    await this.$refs.inquiryStatus.reloadInquiryStatus();
    // 子コンポーネント(お客様情報パネル)のreload関数を実行
    if(!this.$store.state.auth.user.isViewer && !this.$store.state.auth.user.isGuest){
      await this.$refs.customerInformation.reloadCustomerInformation();
    }
    // 子コンポーネント(商品/サービス情報パネル)のreload関数を実行
    await this.$refs.productInformation.reloadProductInformation();
    // 子コンポーネント(問い合わせ内容パネル)のreload関数を実行
    this.$refs.inquiryContents.reloadInquiryContents();

    next();
  }
}
</script>
<style>
.customer-sticky{
  position: fixed;right: 0;left: 256px;z-index: 1
}
.product-sticky{
  position: fixed;right: 0;left: 256px;z-index: 1
}
.product-viewer-sticky{
  position: fixed;right: 0;left: 256px;z-index: 1
}
</style>
