<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <span class="text-h6 ml-3">背景設定</span>
      <v-spacer></v-spacer>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-card-text>
      <v-row class="py-2">
        <v-col cols="12">
          <v-color-picker
            v-model="newBackgroundColor"
            dot-size="25"
            mode="hexa"
            show-swatches
            hide-mode-switch
            swatches-max-height="200"
          ></v-color-picker>
        </v-col>
        <v-col cols="12" class="text-center">
          <v-row>
            <v-col cols="6">
              <v-btn
                rounded
                color="success"
                class="px-8 mx-5"
                @click="resetBackgroundColor"
              >リセット
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn
                rounded
                color="warning"
                class="px-8 mx-5"
                @click="closeColorPickerBackgroundModal"
              >閉じる
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

export default {
  props: {
    backgroundColor: String,
    defaultBackgroundColor: String,
  },
  data() {
    return {
      newBackgroundColor: ''
    }
  },
  watch: {
    newBackgroundColor: {
      immediate: true,
      handler(val) {
        if (val == null || val == '') return
        if (typeof val === "object") {this.updateBackgroundColor(val.hexa)}
        else {this.updateBackgroundColor(val)}
      }
    },
  },
  methods: {
    async updateBackgroundColor(newBackgroundColorInquiryContent) {
      this.$emit('updateBackgroundColor', newBackgroundColorInquiryContent);
    },
    async resetBackgroundColor() {
      this.newBackgroundColor = this.defaultBackgroundColor;
      this.$emit('resetBackgroundColor');
    },
    async closeColorPickerBackgroundModal() {
        this.$emit('closeColorPickerBackgroundModal');
    },
  },
  created() {
    this.newBackgroundColor = this.backgroundColor
  }
}
</script>
<style scoped></style>
