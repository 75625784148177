import http from '@/httpCommon.js';


export default {
  //
  async createMatterStatusData(basicInfo) {
    const json = {basicInfo};
    // const json = {basicInfo: basicInfo};
    // console.log(json);
    return http.post(`/matters`, json,);
  },

  //
  async createMatterCustomerData(customer) {
    const customerData = {customer};
    return http.post(`/matters/`, customerData);
  },

  //
  async createMatterProductData(productId) {
    // console.log(matterId);
    // console.log(productId);
    const productData = {product: {id: productId}};
    return http.post(`/matters/`, productData);
  },

  //
  async createMatterCorrespondenceData(correspondence) {
    // console.log(correspondence);
    const correspondenceData = {correspondence: correspondence};
    return http.post(`/matters/`, correspondenceData);
  },

  //
  async createMatterInquiryData(inquiry) {
    // console.log(inquiry);
    const inquiryData = {inquiry: inquiry};
    return http.post(`/matters/`, inquiryData);
  },

  // 問い合せ対応(状態パネル)の更新
  async updateMatterStatusData(matterId, basicInfo) {
    const json = {basicInfo};
    // const json = {basicInfo: basicInfo};
    // console.log(json);
    return http.put(`/matters/${matterId}`, json,);
  },

  // 問い合せ対応(お客様情報パネル)の更新
  async updateMatterCustomerData(matterId, customer) {
    const customerData = {customer};
    return http.put(`/matters/${matterId}`, customerData);
  },

  // 問い合せ対応(商品情報パネル)の商品追加・削除__商品複数想定
  // async updateProductsForMatterData(matterId, productsForMatter) {
  //   const productsForMatterData = {productsForMatter: productsForMatter};
  //   console.log(productsForMatterData);
  //   return http.put(`/matters/${matterId}`, productsForMatterData);
  // },

  // 問い合せ対応(商品情報パネル)の商品変更・削除__商品は1つのみを想定
  async updateProductData(matterId, productId) {
    // console.log(matterId);
    // console.log(productId);
    const productData = {product: {id: productId}};
    return http.put(`/matters/${matterId}`, productData);
  },

  // Inquiry response (correspondence information panel) create correspondence
  async createCorrespondenceData(matterId, correspondenceData) {
    // console.log(matterId);
    // console.log(correspondenceData);
    return http.post(`/matters/${matterId}/correspondence`, correspondenceData);
  },

  // Inquiry response (correspondence information panel) update correspondence
  async updateCorrespondenceData(matterId, correspondenceId, correspondenceData) {
    // console.log(matterId);
    // console.log(correspondenceData);
    return http.put(`/matters/${matterId}/correspondence/${correspondenceId}`, correspondenceData);
  },

  // Inquiry response (correspondence information panel) delete correspondence
  async deleteCorrespondenceData(matterId, correspondenceId) {
    // console.log(matterId);
    // console.log(correspondenceData);
    return http.delete(`/matters/${matterId}/correspondence/${correspondenceId}`);
  },

  // Inquiry response (shipping information panel) create shipping
  async createShippingData(matterId, shippingData) {
    // console.log(matterId);
    // console.log(shippingData);
    return http.post(`/matters/${matterId}/shipping`, shippingData);
  },

  // Inquiry response (shipping information panel) update shipping status
  async updateShippingStatusData(matterId, shippingId, statusData) {
    // console.log(matterId);
    // console.log(statusData);
    return http.put(`/matters/${matterId}/shipping/${shippingId}/status`, statusData);
  },

  // Inquiry response (shipping information panel) delete shipping
  async deleteShippingData(matterId, shippingId, statusData) {
    // console.log(matterId);
    // console.log(statusData);
    return http.delete(`/matters/${matterId}/shipping/${shippingId}`, statusData);
  },

  //
  async createInquiryData(matterId, inquiryData) {
    // console.log(matterId);
    // console.log(inquiryData);
    return http.post(`/matters/${matterId}/inquiry`, inquiryData);
  },

  //
  async updateInquiryData(matterId, inquiryId, inquiryData) {
    // console.log(matterId);
    // console.log(inquiryData);
    return http.put(`/matters/${matterId}/inquiry/${inquiryId}`, inquiryData);
  },

  async deleteMatter(matterId) {
    return http.delete(`/matters/${matterId}`, );
  },

  //update const current edit
  async updateMatterEditingStatus(matterId,status){
    return http.put(`/matters/editing-status/${matterId}`,status);
  },

  async matterStatusLogoutUpdate(){
    return http.get(`/matters/logout-status`);
  }
};
