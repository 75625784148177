import http from '@/httpCommon.js';

export default {
  // 問い合せ一覧の取得
  async getMatter(matterId) {
    // await sleep(500);
    return http.get(`/matters/${matterId}`);
  },

  // ユーザーマスタの取得
  async getUserMaster() {
    return http.get('/user-search', {params: { matter_assignable: 1,orderBy:'matter_assignable_order',orderDir:'asc' }});
  },

  // 指定したuserIdの情報を取得
  async getUserInfo(userId) {
    return http.get(`/users/${userId}`);
  },

  // get list correspondence of matter
  async getCorrespondence(matterId) {
    return http.get(`/matters/${matterId}/correspondence`);
  },

  // get list shipping of matter
  async getShippings(matterId) {
    return http.get(`/matters/${matterId}/shipping`);
  },

  // get one shipping of matter
  async getShipping(matterId, shippingId) {
    return http.get(`/matters/${matterId}/shipping/${shippingId}`);
  },

  // get list inquiry of matter
  async getInquiry(matterId) {
    return http.get(`/matters/${matterId}/inquiry`);
  },

  async getInfoMatterListSearch(matterId) {
    // await sleep(500);
    return http.get(`/matters-list-search/${matterId}/info-matter-list`);
  },

  // get pac 16 inquiry id
  async getPac16InquiryId(matterId) {
    return http.get(`/matters/${matterId}/receive_mail/inquiry_id`);
  },
};
