<template>
    <v-sheet rounded style="border: 2px solid #64B5F6;">
        <v-card class="elevation-4" color="blue lighten-4">
            <v-card-title class="py-3">
                <v-container fluid>
                    <v-row align="center">
                      <!--   最小化による表示の切り替え-->
<!--                        <v-col v-show="!minimizeFlg" cols="10">
                            <v-row align="center">
                                <v-col cols="auto" class="text-truncate pa-0">
                                    <span class="text-subtitle-1 ml-4">件名：</span>
                                    <span class="text-subtitle-1 ml-5">{{ subject }}</span>
                                </v-col>
                            </v-row>
                        </v-col>-->
                        <v-col v-show="minimizeFlg" cols="10" class="py-4">
                            <v-row align="center">
<!--                                <v-col cols="6" class="text-truncate pa-0">
                                    <span class="text-subtitle-1 ml-4">件名：</span>
                                    <span class="text-subtitle-1">{{ subject }}</span>
                                </v-col>-->
                                <v-col cols="12" class="text-truncate pa-0">
                                    <span class="text-subtitle-1">内容：</span>
                                    <span class="text-subtitle-1">{{ content }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-spacer></v-spacer>
                        <!-- 最小化による表示の切り替え -->
                        <v-btn
                            v-show="!minimizeFlg"
                            rounded
                            small
                            color="grey darken-1"
                            dark
                            class="px-5 mr-4"
                            @click="minimizeFlg = !minimizeFlg"
                        >最小化</v-btn>
                        <v-btn
                            v-show="minimizeFlg"
                            rounded
                            small
                            color="grey darken-1"
                            class="white--text px-5 mr-4"
                            @click="minimizeFlg = !minimizeFlg"
                        >開く</v-btn>
                    </v-row>
                </v-container>
            </v-card-title>
            <!-- 最小化状態なら非表示 -->
            <div v-show="!minimizeFlg">
                <v-divider class="mx-4" style="border-width: 1px;" />
                <v-card-text class="py-3">
                    <v-row align="start">
                        <v-col cols="1" class="pa-0">
                            <span class="text-subtitle-1 ml-4">内容：</span>
                        </v-col>
                        <v-col cols="11" class="pa-0">
                            <span
                                class="text-subtitle-1"
                                style="white-space: pre-line;"
                            >{{ content }}</span>
                        </v-col>
                    </v-row>
                </v-card-text>
            </div>
        </v-card>
    </v-sheet>
</template>
<script>
export default {
    props: {
        subject: {
            type: String,
        },
        content: {
            type: String,
        },
    },
    data() {
        return {
            // 最小化の表示を切り替えるflg
            minimizeFlg: true,
        }
    },
}
</script>
<style scoped></style>
