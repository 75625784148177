<template>
    <v-card class="elevation-4" :color="getBackgroundColor" id="shipping-modals">
        <div id="printable" ref="printable">
            <!-- 最小化状態なら非表示 -->
            <v-card-text class="py-1">
                <v-row class="pt-3 d-none d-print-block pr-3">
                  <v-col cols="2" class="py-0">
                  </v-col>
                  <v-col cols align="center">
                    <p class="mb-0 headline text-decoration-underline">発送票</p>
                  </v-col>
                  <v-col cols="2" align="right" class="ma-0 pa-0" style="height: 100px; padding-left: 0; padding-right: 0">
                    <p class="ma-0 pa-0 text-subtitle-1">受付印</p>
                  </v-col>
                </v-row>
                <v-row align="center" class="pt-3">
                  <v-col cols="6" class="py-0">
                    <p class="mb-0 text-subtitle-1 ">案件番号</p>
                    <v-text-field
                      flat
                      outlined
                      hide-details
                      dense
                      background-color="grey lighten-2"
                      v-model="matterStatusData.matterNumber"
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-spacer></v-spacer>
                </v-row>
                <v-row align="center" class="pt-3">
                    <v-col cols="6" class="py-0">
                        <p class="mb-0 text-subtitle-1 ">会社名</p>
                        <v-text-field
                            v-model="companyName"
                            placeholder="株式会社〇〇"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            :disabled="historyModalFlg"
                            :readonly="readonlyFlg"
                            :background-color="historyModalFlg?'grey lighten-2':null"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="6" class="py-0">
                        <p class="mb-0 text-subtitle-1 ">部署名</p>
                        <v-text-field
                            v-model="departmentName"
                            placeholder="部署名"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            :readonly="readonlyFlg"
                            :disabled="historyModalFlg"
                            :background-color="historyModalFlg?'grey lighten-2':null"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row align="center" class="pt-3">
                  <v-col cols="12" class="py-0">
                    <p class="mb-0 text-subtitle-1">お客様名</p>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                        v-model="customerName"
                        placeholder="田中 太郎"
                        solo
                        flat
                        outlined
                        hide-details
                        dense
                        :readonly="readonlyFlg"
                        :disabled="historyModalFlg"
                        :background-color="historyModalFlg?'grey lighten-2':null"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row align="center" class="pt-3">
                    <v-col cols="12" class="py-0">
                      <p class="mb-0 text-subtitle-1">住所</p>
                    </v-col>
                    <v-col cols="3" class="py-0">
                      <v-text-field
                          v-model="postalCode"
                          placeholder="(〒)"
                          solo
                          flat
                          outlined
                          hide-details
                          dense
                          @input="changePostalCode"
                          :readonly="readonlyFlg"
                          :disabled="historyModalFlg"
                          :background-color="historyModalFlg?'grey lighten-2':null"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="py-0" style="margin-top: -1px">
                        <v-text-field
                            v-model="address"
                            placeholder="〇〇県〇〇市〇〇区〇〇町〇丁目0-0"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            :readonly="readonlyFlg"
                            :disabled="historyModalFlg"
                            :background-color="historyModalFlg?'grey lighten-2':null"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <!-- テーブル -->
                <v-row align="center" class="pt-3" v-for="item in shippingProductData" :key="item.productNumber">
                  <v-col cols="3" class="py-0">
                    <p class="mb-0 text-subtitle-1">品番</p>
                    <v-text-field
                        v-model="item.productNumber"
                        solo
                        flat
                        outlined
                        hide-details
                        dense
                        :readonly="readonlyFlg"
                        :disabled="historyModalFlg"
                        :background-color="historyModalFlg?'grey lighten-2':null"
                        class="py-1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <p class="mb-0 text-subtitle-1">商品名</p>
                    <v-text-field
                        v-model="item.productName"
                        solo
                        flat
                        outlined
                        hide-details
                        dense
                        :readonly="readonlyFlg"
                        :disabled="historyModalFlg"
                        :background-color="historyModalFlg?'grey lighten-2':null"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="1" class="pa-0 mx-0">
                    <p class="mb-0 text-subtitle-1">数量</p>
                    <v-text-field
                        class="text-field-no-padding pa-0 ma-0"
                        v-model="item.quantity"
                        ref="quantity"
                        type="Number"
                        min="1"
                        solo
                        flat
                        outlined
                        hide-details
                        dense
                        :readonly="readonlyFlg"
                        :disabled="historyModalFlg"
                        :background-color="historyModalFlg?'grey lighten-2':null"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <p class="mb-0 text-subtitle-1">備考</p>
                    <v-text-field
                        v-model="item.remarks"
                        solo
                        flat
                        outlined
                        hide-details
                        dense
                        :readonly="readonlyFlg"
                        :disabled="historyModalFlg"
                        :background-color="historyModalFlg?'grey lighten-2':null"
                    ></v-text-field>
                  </v-col>
                </v-row>

                <!-- 郵送代金立替等 -->
                <v-row align="center" class="pt-3">
                  <v-col cols="4" class="py-0">
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <p class="mb-0 text-subtitle-1">立替郵送料返却</p>
                        </v-col>
                      </v-row>
                      <v-row justify="center" align="center">
                        <v-col cols="1" class="py-0 pl-2">
                          <v-checkbox
                              v-model="mailingPriceFlg"
                              ref="mailingPriceFlg"
                              hide-details
                              class="ma-0 d-print-none"
                              :disabled="readonlyFlg || historyModalFlg"
                              @change="changedCheckBox(mailingPriceFlg, '郵送')"
                          ></v-checkbox>
                          <input v-if="mailingPriceFlg" checked type="checkbox" class="ml-1  d-none d-print-block">
                          <input v-else type="checkbox" class="ml-1 d-none d-print-block">
                        </v-col>
                        <v-col cols class="py-0">
                          <v-text-field
                              v-model="mailingPrice"
                              solo
                              flat
                              outlined
                              hide-details
                              dense
                              class="right-input"
                              :disabled="readonlyFlg || historyModalFlg || !mailingPriceFlg"
                              :background-color="historyModalFlg || !mailingPriceFlg?'grey lighten-2':null"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" class="py-0 pl-0" >円分</v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <p class="mb-0 text-subtitle-1">返信用封筒同封</p>
                        </v-col>
                      </v-row>
                      <v-row justify="center" align="center">
                        <v-col cols="1" class="py-0 pl-2">
                          <v-checkbox
                              v-model="transportPriceFlg"
                              ref="transportPriceFlg"
                              hide-details
                              class="ma-0 d-print-none"
                              :disabled="readonlyFlg || historyModalFlg"
                              @change="changedCheckBox(transportPriceFlg, '輸送')"
                          ></v-checkbox>
                          <input v-if="transportPriceFlg" checked type="checkbox" class="ma-0 d-none d-print-block">
                          <input v-else type="checkbox" class="ma-0 d-none d-print-block">
                        </v-col>
                        <v-col cols class="py-0">
                          <v-text-field
                              v-model="transportPrice"
                              solo
                              flat
                              outlined
                              hide-details
                              dense
                              class="right-input"
                              :disabled="readonlyFlg || historyModalFlg || !transportPriceFlg"
                              :background-color="historyModalFlg || !transportPriceFlg?'grey lighten-2':null"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="3" class="py-0 pl-0" >円分</v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                  <v-col cols="4" class="py-0">
                    <v-container>
                      <v-row>
                        <v-col cols="12" class="py-1">
                          <p class="mb-0 text-subtitle-1">切手明細</p>
                        </v-col>
                      </v-row>
                      <v-row justify="center" align="center">
                        <v-col cols="1" class="py-2">
                          <span class="text-subtitle-1">(</span>
                        </v-col>
                        <v-col col class="py-0"></v-col>
                        <v-col cols="1" class="py-2">
                          <span class="text-subtitle-1">)</span>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-col>
                </v-row>

                <v-row class="pt-3">
                    <v-col cols="12">
                      <p class="mb-0 text-subtitle-1">連絡事項</p>
                      <v-textarea
                          v-model="information"
                          ref="information"
                          outlined
                          auto-grow
                          solo
                          flat
                          :clearable="!historyModalFlg"
                          hide-details
                          row-height="15"
                          :disabled="historyModalFlg"
                          :readonly="readonlyFlg"
                          :background-color="historyModalFlg?'grey lighten-2':null"
                      ></v-textarea>
                    </v-col>
                </v-row>

                <v-row align="center">
                    <v-col cols="4" class="py-0">
                      <p class="mb-0 text-subtitle-1">発送日</p>
                        <v-text-field
                            type="date"
                            v-model="shippingDate"
                            solo
                            flat
                            outlined
                            hide-details
                            dense
                            :disabled="historyModalFlg"
                            :readonly="readonlyFlg"
                            :background-color="historyModalFlg?'grey lighten-2':null"
                        ></v-text-field>
                    </v-col>
                </v-row>

                <v-row class="pt-3 d-none d-print-block">
                  <v-col cols="12">
                    <p class="mb-0 text-subtitle-1">捺印・筆記状態</p>
                  </v-col>
                </v-row>
                <v-divider class="mx-0 d-none d-print-block" />
            </v-card-text>
            <v-divider class="mx-4 mt-5 mr-5 d-print-none" />
            <v-card-actions v-show="!historyModalFlg">
                <template v-if="false">
                    <v-row align="center" class="d-print-none" justify="space-around" style="height:70px;">
                        <v-btn
                            rounded
                            color="success"
                            class="px-10 text-subtitle-1"
                            :disabled="shippingStatusFlg === 1 || shippingStatusFlg === 2"
                            v-if="!readonlyFlg"
                            @click="shipmentCompletion"
                        >発送完了</v-btn>
                        <v-btn
                            rounded
                            color="primary"
                            class="px-14 text-subtitle-1"
                            :disabled="shippingStatusFlg === 2"
                            @click="printer"
                        >印刷</v-btn>
                        <v-btn
                            rounded
                            color="warning"
                            class="px-7 text-subtitle-1"
                            :disabled="shippingStatusFlg === 1 || shippingStatusFlg === 2"
                            v-if="!readonlyFlg"
                            @click="shipmentCancel"
                        >発送取り消し</v-btn>
                    </v-row>
                </template>
                <template v-else>
                    <v-row align="center" class="pl-4 d-print-none" style="height:65px; margin:0">
                      <v-col cols="3">
                        <v-btn
                            v-show="shippingHistoryId"
                            rounded
                            color="primary"
                            class="px-5 text-subtitle-1"
                            :disabled="shippingStatusFlg === 2"
                            @click="printer"
                        >&nbsp;&nbsp;印刷&nbsp;&nbsp;</v-btn>
                      </v-col>
                      <v-col offset="2" cols="2">
                          <v-btn
                              rounded
                              color="primary"
                              class="px-5"
                              @click="saveShipping"
                              :disabled="!(matterProductData && matterProductData.id) || !(matterCustomerData && matterCustomerData.id)"
                              v-if="!readonlyFlg"
                          >&nbsp;&nbsp;保存&nbsp;&nbsp;</v-btn>
                      </v-col>
                      <v-col offset="1" cols="2">
                        <v-btn
                            rounded
                            v-show="shippingHistoryId"
                            class="px-5"
                            color="warning"
                            @click="shipmentCancel"
                            v-if="!readonlyFlg"
                        >発送取消</v-btn>
                      </v-col>
                      <v-col cols="2">
                          <v-btn
                              rounded
                              class="px-5"
                              color="warning"
                              @click="modalClose"
                          >閉じる</v-btn>
                      </v-col>
                    </v-row>
                </template>
            </v-card-actions>

            <div class="d-none d-print-block" style="position: fixed;bottom: 10px;right: 0;left: 0;">
              <v-row align="bottom" justify="bottom" class="pt-3" style="height: 260px">
                <v-col cols>
                </v-col>
                <v-col cols="2" align="right" class="pa-0 mb-3" style="margin-top: 140px; padding-left: 0; padding-right: 30px">
                  <p class="ma-0 pa-0 text-subtitle-1">完了印</p>
                </v-col>
              </v-row>

              <v-divider class="mx-4 mt-5 mr-5 d-none d-print-block" />
            </div>
        </div>
        <!-- ローディング中にスピナーを表示させる -->
        <v-overlay :value="loadingCount > 0">
          <v-progress-circular indeterminate></v-progress-circular>
        </v-overlay>
    </v-card>
</template>
<script>
import updateMatterService from "@/services/updateMatterService";
import getMatterService from "@/services/getMatterService";
import postal_code from "japan-postal-code";

export default {
    props: {
        matterId: {
          type: Number,
        },
        shippingHistoryId: {
            type: Number
        },
        // 問い合わせ履歴モーダル
        historyModalFlg: {
            type: Boolean,
        },
        matterCustomerData: {
          type: Object
        },
        matterProductData: {
          type: Object
        },
        matterStatusData: {
          type: Object
        },
        matterShipmentData: {
          type: [Array]
        },
        showModal: {
          type: Boolean,
        },
        readonlyFlg: {
          type: Boolean,
        },
    },
    data() {
        return {
            // 会社名／販売店名
            companyName: '',
            // お客様名
            customerName: '',
            //
            departmentName: '',
            // 郵便番号
            postalCode: '',
            // 住所
            address: '',
            loadingCount: 0,
            // 商品情報
            shippingProductData: [
                {
                    productNumber: 0,
                    productName: '',
                    colorName: '',
                    quantity: 1,
                    remarks: ''
                }
            ],
            // 郵送代金
            mailingPriceFlg: false,
            mailingPrice: null,
            // 輸送代金
            transportPriceFlg: false,
            transportPrice: null,
            // 返信用封筒
            returnEnvelopeFlg: false,
            // 連絡事項
            information: '',
            // 発送日
            shippingDate: '',

            // 発送状態（0：未完了 1：キャンセル 2：発送完了）
            shippingStatusFlg: 0,

            // api取得データ(shippingHistoryIdを使用して取得予定)
            shippingHistoryData: {},
        }
    },
    methods: {
        async loadShippingData(){
          if (typeof this.shippingHistoryId === 'undefined') {
            // 発送履歴を入力画面の場合
            // apiより取得した「matterCustomerData」と「matterProductData」を変数に代入
            this.matterCustomerProductDataAssign(this.matterCustomerData, this.matterProductData);
            // 現在の日時と次の日を取得
            this.shippingDate = this.getCurrentDate(0);
          } else {
            // スレッドに表示する場合
            // apiより取得した「matterCustomerData」と「matterProductData」を変数に代入
            this.matterCustomerProductDataAssign(this.matterCustomerData, this.matterProductData);
            // apiより取得した「shippingHistoryData」を変数に代入
            this.loadingCount++;
            await getMatterService.getShipping(this.matterId, this.shippingHistoryId)
              .then(response => {
                this.shippingHistoryData = response.data.data;
                this.shippingStatusFlg = this.shippingHistoryData.status;
                this.shippingHistoryDataAssign(this.shippingHistoryData);
              }).catch(error => {
                if(error.response.status === 403){
                  const editing_by = error.response.data.message.editing_by;
                  this.$emit('isEditing',editing_by);
                }
                this.reset();
                this.$emit('modal-close');
              });
            this.loadingCount--;
          }
        },
        modalClose(){
          this.reset();
          this.$emit('modal-close');
        },
       reset(){
         this.shippingDate = this.getCurrentDate(0);
         Object.assign(this.shippingProductData[0], {
           quantity: 1,
           remarks: ''
         })
         this.mailingPrice=0;
         this.transportPrice=0;
         this.$refs.information.reset();
         this.$refs.mailingPriceFlg.reset();
         this.$refs.transportPriceFlg.reset();
       },
        // checkbox
        changedCheckBox(flg, label) {
            if (flg === false) {
                if (label === '郵送') {
                    this.mailingPrice = 0;
                } else if (label === '輸送') {
                    this.transportPrice = 0;
                }
            }
        },
        // 発送日(現在の日付)
        getCurrentDate(addDay) {
            let date = new Date();
            date.setDate(date.getDate() + addDay);
            const year = date.getFullYear();
            const month = ("00" + (date.getMonth() + 1)).slice(-2);
            const day = ("00" + date.getDate()).slice(-2);
            const currentDate = year + '-' + month + '-' + day;
            return currentDate;
        },
        // apiより取得した「matterCustomerData」と「matterProductData」を変数に代入
        matterCustomerProductDataAssign(matterCustomerData, matterProductData) {
            // CustomerData
            if (matterCustomerData){
              this.companyName = matterCustomerData.companyName;
              this.departmentName = matterCustomerData.departmentName;
              this.customerName = (matterCustomerData.lastName?matterCustomerData.lastName:'') + ' ' + (matterCustomerData.firstName?matterCustomerData.firstName:'');
              this.postalCode = matterCustomerData.postalCode;
              this.address = (matterCustomerData.prefectureName?matterCustomerData.prefectureName:'') + (matterCustomerData.address?matterCustomerData.address:'');
            }
            // ProductData
            if (matterProductData){
              this.shippingProductData[0].productNumber = matterProductData.productNumber;
              this.shippingProductData[0].productName = matterProductData.productName;
              this.shippingProductData[0].colorName = matterProductData.colorName;
            }
        },

        // apiより取得した「shippingHistoryData」を変数に代入
        shippingHistoryDataAssign(shippingHistoryData) {
            if (shippingHistoryData.shipping_customer_name)
              this.customerName = shippingHistoryData.shipping_customer_name;
            if (shippingHistoryData.shipping_department_name)
              this.departmentName = shippingHistoryData.shipping_department_name;
            if (shippingHistoryData.shipping_company_name)
              this.companyName = shippingHistoryData.shipping_company_name;
            if (shippingHistoryData.shipping_postal_code)
              this.postalCode = shippingHistoryData.shipping_postal_code;
            if (shippingHistoryData.shipping_address)
              this.address = shippingHistoryData.shipping_address;
            if (shippingHistoryData.shipping_product_name)
              this.shippingProductData[0].productName = shippingHistoryData.shipping_product_name;
            if (shippingHistoryData.shipping_product_number)
              this.shippingProductData[0].productNumber = shippingHistoryData.shipping_product_number;
            this.shippingProductData[0].quantity = shippingHistoryData.quantity;
            this.shippingProductData[0].remarks = shippingHistoryData.remarks;
            this.mailingPriceFlg = shippingHistoryData.mailingPriceFlg;
            this.mailingPrice = shippingHistoryData.mailingPrice;
            this.transportPriceFlg = shippingHistoryData.transportPriceFlg;
            this.transportPrice = shippingHistoryData.transportPrice;
            this.information = shippingHistoryData.information;
            this.shippingDate = shippingHistoryData.shippingDate;
        },

        async saveShipping(){
          // DB更新(create shipping)
          this.loadingCount++;
          await updateMatterService.createShippingData(
              this.matterId,
              {
                shipping_company_name: this.companyName,
                shipping_department_name: this.departmentName,
                shipping_customer_name: this.customerName,
                shipping_postal_code: this.postalCode,
                shipping_address: this.address,
                shipping_product_number: this.shippingProductData[0].productNumber,
                shipping_product_name: this.shippingProductData[0].productName,
                shipping_quantity: this.shippingProductData[0].quantity,
                shipping_remark: this.shippingProductData[0].remarks ,
                mailing_charge: this.mailingPriceFlg?this.mailingPrice:'',
                transportation_fee: this.transportPriceFlg?this.transportPrice:'',
                informative_matter: this.information,
                shipping_date: this.shippingDate,
              },
          ).then(shippingResponse => {
            let newShipping = shippingResponse.data.data;
            if (newShipping){
              this.matterShipmentData.length = 0;
              this.matterShipmentData.push({
                "id": `ship-${newShipping.id}`,
                "userId": null,
                "userName": newShipping.created_by,
                "receiveSendFlg": null,
                "inquiryType": null,
                "receiveMailId": null,
                "sendMailId": 1,
                "receiveTime": null,
                "sendTime": null,
                "subject": null,
                "content": null,
                "correspondenceInfo": null,
                "shippingHistoryId": newShipping.id,
                "createdAt": newShipping.created_at,
                "updatedAt": newShipping.created_at
              })
            }
          }).catch(error => {
              if(error.response.status === 403){
                const editing_by = error.response.data.message.editing_by;
                this.$emit('isEditing',editing_by);
              }
            });
          this.loadingCount--;
          this.reset();
          this.$emit('modal-close');
        },

        // 発送完了処理
        async shipmentCompletion() {
            // DB更新処理を記述
            await updateMatterService.updateShippingStatusData(this.matterId, this.shippingHistoryId, {status:2});
            this.shippingStatusFlg = 2;
        },
        changePostalCode(){
          this.address = '';
          let root = this;
          postal_code.get(this.postalCode, function(address) {
            if (address){
              let ad = '';
              if (address.prefecture) ad += address.prefecture
              if (address.city) ad += address.city;
              if (address.area) ad += address.area;
              if (address.street) ad += address.street;
              root.address = ad;
            }
          });
        },
        // 発送取り消し処理
        async shipmentCancel() {
            // DB更新処理を記述
            this.loadingCount++;
            await updateMatterService.deleteShippingData(this.matterId, this.shippingHistoryId)
              .catch(error =>{
                if(error.response.status === 403){
                  const editing_by = error.response.data.message.editing_by;
                  this.$emit('isEditing',editing_by);
                }
            });
            this.matterShipmentData.length = 0;
            this.loadingCount--;

            this.$emit('update:matterShipmentData', this.matterShipmentData);
            this.modalClose();
        },

        // 印刷処理
        printer() {
            // 1)印刷したい範囲以外の要素をDOMから全て除外
            let inputs = this.$refs.printable.getElementsByTagName("input");
            for (let input of inputs) {
              input.setAttribute("value", input.value)
              input.setAttribute("store_placeholder", input.placeholder)
              input.setAttribute("placeholder", '')
            }
            let textAreas = this.$refs.printable.getElementsByTagName("textarea");
            for (let input of textAreas) {
              input.innerHTML = input.value
            }
            let printData = document.getElementById('printable').innerHTML;

            for (let input of inputs) {
              input.setAttribute("placeholder", input.getAttribute('store_placeholder'))
              input.setAttribute("store_placeholder", '')
            }
            let css = "", styleTags = document.getElementsByTagName("style");

            //loop over all the style tags
            for(let i = 0; i < styleTags.length; i++)
            {
              css += styleTags[i].innerHTML; //extract the css in the current style tag
            }

            //loop over all the external style tags
            const styleSheets = Array.from(document.styleSheets).filter(
                (styleSheet) => !styleSheet.href || styleSheet.href.startsWith(window.location.origin)
            );
            for (let style of styleSheets) {
              if (style instanceof CSSStyleSheet && style.cssRules) {
                for(let e = 0; e < style.cssRules.length; e++)
                {
                  if (style.cssRules[e]){

                    css += style.cssRules[e].cssText; //extract all the styling rules
                  }
                }
              }
            }
            css += ".d-print-none{display:none!important} .py-0{padding-top: 0; padding-bottom: 0} .pt-3{padding-top: 8px !important;} .pl-0{padding-left: 0} " +
                " .pl-2 {padding-left: 8px !important;} .align-center {align-items: center !important;} .justify-center {justify-content: center !important;} " +
                " .text-decoration-underline {text-decoration: underline !important;} .ml-1{margin-left: 5px !important;}" +
                " .headline {font-size: 1.5rem !important;font-weight: 400;line-height: 2rem;letter-spacing: normal !important;font-family: \"Roboto\", sans-serif !important;}" +
                " input::-webkit-outer-spin-button,input::-webkit-inner-spin-button {-webkit-appearance: none;margin: 0;} .pr-3{padding-right:16px !important}" +
                " .mr-5 {margin-right:5px !important};"+
                " #shipping-modals .theme--light.v-input--is-disabled input{color: rgba(0, 0, 0, 0.87)};"
            let printWindow = window.open('', '印刷', "fullscreen");
            printWindow.document.write('<html><head><title>印刷</title></head><style>'+ css +'</style><body id="shipping-modals">' + printData + '</body></html>');
            printWindow.print();
            printWindow.close();
        }
    },
    computed: {
        // 発送状態によって色を変更する
        getBackgroundColor() {
            if (this.shippingStatusFlg === 2) {
                return "green lighten-3"
            } else if (this.shippingStatusFlg === 1) {
                return "red lighten-3"
            } else {
                return "grey lighten-3"
            }
        },
        // 発送状態（0：未完了 1：キャンセル 2：発送完了）
        shippingStatusName() {
            if (this.shippingStatusFlg === 2) {
                return "発送完了";
            } else if (this.shippingStatusFlg === 1) {
                return "発送取り消し";
            } else {
                return "発送未完了";
            }
        },
    },
    watch: {
      showModal: {
        handler(val) {
          if (val) {
            this.loadShippingData();
          }
        },
      },
      async matterCustomerData() {
        this.matterCustomerProductDataAssign(this.matterCustomerData, this.matterProductData);
      },
      async matterProductData() {
        this.matterCustomerProductDataAssign(this.matterCustomerData, this.matterProductData);
      },
    },
    mounted() {
      this.loadShippingData();
    }
}
</script>
<style scoped>
/* v-text-fieldを右寄せで表示 */
.right-input /deep/ input {
    text-align: right;
}
</style>
<style>
.text-field-no-padding .v-input__slot{
  padding: 1px !important;
}
#shipping-modals .theme--light.v-input--is-disabled input{
  color: rgba(0, 0, 0, 0.87);
}
</style>
