<template>
  <div>
    <!-- 管理者用(ダブルクリックで編集ダイアログ表示) -->
    <v-data-table
      :headers="headers"
      :items="notifications"
      :items-per-page="itemsPerPage"
      :sort-by="['updatedAt']"
      :sort-desc="[true]"
      show-select
      v-model="selectedNotifications"
      @dblclick:row="updateNotification"
      item-key="id"
      no-data-text="検索条件に一致するお知らせはありません"
      hide-default-footer
      class="elevation-2"
      :item-class="itemRowColorText"
      dense
      @input="itemSelected"
    >
      <template v-slot:item.data-table-select="{ item, isSelected, select, on }">
        <div v-on='on'>
          <v-simple-checkbox
            v-show="!item.deleteAt"
            :value="isSelected"
            @input="select($event)"
            color="primary"
          >
          </v-simple-checkbox>
        </div>
      </template>
      <template #[`item.createdAt`]="props">
        <span :style="{fontWeight: props.item.read_status == Valid_Read_Status ? '400' : 'bold'}">{{
            props.item.createdAt.slice(0, -3)
          }}</span>
      </template>
      <template #[`item.updatedAt`]="props">
        <span :style="{fontWeight: props.item.read_status == Valid_Read_Status ? '400' : 'bold'}">
          {{ props.item.updatedAt.slice(0, -3) }}
        </span>
      </template>
      <template #[`item.title`]="props">
        <a :href="'#'" @click.prevent="viewNotificationDetails(props.item)" @dblclick.stop>
          <span :style="{fontWeight: props.item.read_status == Valid_Read_Status ? '400' : 'bold'}">
            {{ props.item.title }}
          </span>
        </a>
      </template>
      <template #[`item.relatedProducts`]="props">
          <span :style="{fontWeight: props.item.read_status == Valid_Read_Status ? '400' : 'bold'}">
            {{ props.item.relatedProducts }}
          </span>
      </template>
      <template #[`item.postPeriod`]="props">
        <span
          v-show="props.item.notificationStartDate === null"
          style="display: inline-block; width: 72px"
        ></span>
        <span :style="{fontWeight: props.item.read_status == Valid_Read_Status ? '400' : 'bold'}">
          {{ props.item.postPeriod }}
        </span>
        <span
          v-show="props.item.notificationEndDate === null"
          style="display: inline-block; width: 72px"
        ></span>
      </template>
      <template #[`item.createdBy`]="props">
          <span :style="{fontWeight: props.item.read_status == Valid_Read_Status ? '400' : 'bold'}">
            {{ props.item.createdBy }}
          </span>
      </template>
      <template #[`item.updatedBy`]="props">
          <span :style="{fontWeight: props.item.read_status == Valid_Read_Status ? '400' : 'bold'}">
            {{ props.item.updatedBy }}
          </span>
      </template>
    </v-data-table>
    <div v-if="!isDashboardScreen" class="py-3">
      <v-btn color="primary mr-3" height="32px" @click="createNotification">新規作成</v-btn>
      <v-btn
        height="32px"
        color="error"
        :disabled="!selectedNotifications.length || !dataSelect"
        @click="deleteNotifications"
      >削除
      </v-btn
      >
      <v-btn
        class="ml-3"
        height="32px"
        color="success"
        :disabled="!selectedNotifications.length || !dataSelect"
        @click="readAllNotifications"
      >一括既読
      </v-btn
      >
      <div class="text-center py-1" v-show="notifications.length > 0">
        <v-pagination v-model="page" :length="pageCount" total-visible="7"></v-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import Ripple from 'vuetify/lib/directives/ripple';
import notificationService from "@/services/notificationService";

export default {
  props: {
    notifications: Array,
    isDashboardScreen: Boolean,
    pageCount: Number,
    isSearch: Boolean
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      headers: [
        {text: 'id', value: 'id', align: ' d-none'},
        {text: '投稿日時', value: 'createdAt'},
        {text: '更新日時', value: 'updatedAt'},
        {text: 'タイトル', value: 'title'},
        {text: '関連商品', value: 'relatedProducts'},
        {text: '掲載期間', value: 'postPeriod'},
        {text: '投稿者', value: 'createdBy'},
        {text: '更新者', value: 'updatedBy'},
      ],
      page: 1,
      itemsPerPage: 10,
      selectedNotifications: [],
      dataSelect: false,
      Valid_Read_Status: 1,
    };
  },
  watch: {
    page: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.$emit('curentPage', newVal);
          this.$emit('clearIsSearch');
        }
      }
    },
    isSearch: {
      immediate: true,
      handler: function (newVal) {
        if (newVal) {
          this.page = 1;
        }
      }
    }
  },
  methods: {
    itemRowColorText: function (item) {
      return item.matterUrgency === 2 ? 'text-color-orange' : (item.matterUrgency === 1 ? 'text-color-red' : '');
    },
    async viewNotificationDetails(item) {
      item.read_status = this.Valid_Read_Status;
      const conditions = {
        notificationId: item.id,
        readStatus: 1
      };
      try {
        await notificationService.createNotificationReader(conditions);
        this.$emit('open-dialog-for-viewing', item);
        this.updateUnreadNotify();
      } catch (e) {
        console.log(e)
      }
    },
    async updateUnreadNotify() {
      let response = await notificationService.countUnreadNotifications();
      this.$store.commit('updateUnreadNotify', response.data.amount);
    },
    createNotification() {
      this.$emit('open-dialog-for-creating');
    },
    async updateNotification(event, {item}) {
      item.read_status = this.Valid_Read_Status;
      const conditions = {
        notificationId: item.id,
        readStatus: 1
      };
      try {
        await notificationService.createNotificationReader(conditions);
        this.$emit('open-dialog-for-editing', item);
        this.updateUnreadNotify();
      } catch (e) {
        console.log(e)
      }
    },
    deleteNotifications() {
      this.$emit('open-dialog-for-deleting', this.selectedNotifications);
    },
    readAllNotifications() {
      console.log(this.selectedNotifications)
      this.$emit('readAllNotifications', this.selectedNotifications);
    },
    clearData() {
      this.page = 1;
      this.pageCount = 0;
    },
    itemSelected(list) {
      let data = list.filter(item => !item.deleteAt)
      if (data.length == 0) {
        this.dataSelect = false
      } else {
        this.dataSelect = true
      }
      return data
    },
  },
};
</script>
<style scoped>
/* テーブルのヘッダーのフォントサイズ */
.v-data-table ::v-deep th {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
  font-size: 18px !important;
  background-color: #90caf9;
}

/* テーブルデータのフォントサイズ */
.v-data-table ::v-deep td {
  background-color: #e3f2fd;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.v-data-table ::v-deep tr:nth-child(odd) td {
  background: #fff;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.v-data-table ::v-deep tr:hover td {
  background-color: #eee;
}

::v-deep .v-data-table__empty-wrapper > td {
  font-size: 20px !important;
  /* font-family: sans-serif !important; */
}

>>> tr.text-color-red {
  color: red;
}

>>> tr.text-color-orange {
  color: orange;
}
</style>
