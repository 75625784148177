<template>
  <v-card color="grey lighten-5" class="my-3" style="padding-bottom: 58px">
      <v-row class="px-5 py-0" style="margin-bottom: 0">
        <v-col cols="6">
          <span class="text-h6" style="color: black">問い合わせ履歴</span>
        </v-col>
        <v-col cols="6">
          <span class="text-h6" style="color: black">{{this.total}}件</span>
        </v-col>
      </v-row>
    <!-- 問い合わせ一覧 -->
    <v-data-table
      height="525px"
      v-model="selected"
      :headers="headers"
      :items="matterList"
      :page.sync="page"
      :items-per-page="-1"
      :single-select="singleSelect"
      item-key="matterId"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      hide-default-footer
      class="elevation-4 mx-3"
      no-data-text="検索条件に一致するお問い合わせはありません"
      style="cursor: pointer"
      @dblclick:row="inquiryContents">
      <template v-slot:foot>
        <div v-if="matterList.length" v-observe-visibility="infiniteScrolling"></div>
      </template>
    </v-data-table>
    <!-- 検索ボタン -->
    <v-dialog v-model="customerInquiryContentsModal"
              persistent
              scrollable
              max-width="70%">
      <CustomerInquiryContentsModals-component
        :historyModalFlg="historyModalFlg"
        v-bind:matterMemoData.sync="matterMemoData"
        v-bind:matterDetailInquiriesData.sync="matterDetailInquiriesData"
        @modal-close="customerInquiryContentsModalClose"
      ></CustomerInquiryContentsModals-component>
    </v-dialog>
    <v-overlay :value="loadingCount > 0">
      <v-progress-circular indeterminate></v-progress-circular>
    </v-overlay>
  </v-card>
</template>

<script>
import CustomerInquiryContentsModals from './CustomerInquiryContentsModals'
import getMatterService from "@/services/getMatterService";
import Vue from 'vue'
import VueObserveVisibility from 'vue-observe-visibility'
Vue.use(VueObserveVisibility)
export default {
  props: {
    // テーブルに表示するデータ(バックエンドから取得)
    total:Number,
    customerId:Number,
    matterList: Array,
    itemCount: Number,
    inquiryHistoryFlg: Boolean,
    pageCount: Number,
    sortParam: Object,
    silentLoad: Boolean,
    matterCustomerData: {
      type: [String, Number, Object]
    },
  },
  components :{
    'CustomerInquiryContentsModals-component':CustomerInquiryContentsModals
  },
  data() {
    return {
      // ページネーション
      page: 1,
      loadingCount: 0,
      sortBy: null,
      sortDesc: null,
      singleSelect: false,
      selected: [],
      ids: [],
      // テーブルのヘッダー
      headers: [
        { text: '問い合わせ日時', value: 'inquiryDate' },
        { text: 'タイトル', value: 'matterTitle' },
      ],
      // 問い合わせ履歴の案件フラグ
      inquiryHistoryMatterFlg: false,
      customerInquiryContentsModal : false,
      matterMemoData: '',
      matterDetailInquiriesData:[],
      historyModalFlg: true,
    }
  },
  methods: {
    inquiryContents: async function (event,{item}) {
      this.loadingCount++;
      try {
        if (item){
          this.$emit('inquiryContentsModalOpen',item.matterId)
          let response = {};
          try {
            this.mountCompletion = true;
            response = await getMatterService.getInfoMatterListSearch(item.matterId);
            let correspondences = response.data.data.correspondence;
            let inquiries = response.data.data.inquiry;
            this.pushArrInquiriesData(this.matterDetailInquiriesData,inquiries,correspondences)
            // 取得したデータを格納
            this.matterMemoData = response.data.data.basicInfo;
            this.matterMemoData.oldMemo = this.matterMemoData.memo;
          } catch (e) {
            console.log(e);
          }
        }
      } catch (e) {
        console.log(e);
      }
      this.customerInquiryContentsModalOpen()
      this.loadingCount--;
    },
    pushArrInquiriesData(array , inquiries,correspondences){
      if (inquiries && inquiries.length > 0){
        inquiries.forEach(element => {
          array.push({
            "id": element.id,
            "userId": element.userId,
            "userName": element.createdBy,
            "receiveSendFlg": element.receiveSendFlg,
            "inquiryType": element.inquiryType,
            "receiveMailId": element.receiveMailId,
            "sendMailId": element.sendMailId,
            "receiveTime": element.receiveTime,
            "sendTime": element.sendTime,
            "subject": element.subject,
            "content_type": 1,
            "content": element.content,
            "oldContent": element.content,
            "correspondenceInfo": null,
            "shippingHistoryId": null,
            "createdAt": element.sendTime,
            "updatedAt": element.updatedAt
          })
        });
      }
      if (correspondences && correspondences.length > 0){
        correspondences.forEach(element => {
          array.push({
            "id": `corr-${element.id}`,
            "userId": null,
            "userName": element.created_by,
            "receiveSendFlg": null,
            "inquiryType": null,
            "receiveMailId": null,
            "sendMailId": null,
            "receiveTime": null,
            "sendTime": null,
            "subject": null,
            "content_type": 1,
            "content": null,
            "correspondenceInfo": element.correspondence_content,
            "oldCorrespondenceInfo": element.correspondence_content,
            "shippingHistoryId": null,
            "createdAt": element.created_at,
            "updatedAt": element.created_at
          })
        });
      }
      if (this.matterDetailInquiriesData.length > 0){
        this.matterDetailInquiriesData.sort((a, b) => a.createdAt > (b.createdAt));
      }
    },
    customerInquiryContentsModalOpen(){
      this.customerInquiryContentsModal = true
    },
    customerInquiryContentsModalClose(){
      this.customerInquiryContentsModal = false
      this.matterDetailInquiriesData = []
      this.matterMemoData = ''
    },
    //scroll to load
    infiniteScrolling(isVisible) {
      if (!isVisible){return}
      if(this.page >= this.pageCount){return}
      this.page++
      this.$emit('updateMatterList',this.page)
    },
  },
  computed: {
  },
  watch: {
    customerId(val){
      if(val) this.page = 1
    },
    async page(){
      this.$emit('curentPage', this.page);
    },
    async sortParam(){
      this.sortBy = this.sortParam.sortBy;
      this.sortDesc = this.sortParam.sortDesc;
    },
    async sortBy(){
      this.sortParam.sortBy = this.sortBy;
      this.$emit('sortParam', this.sortParam);
    },
    async sortDesc(){
      this.sortParam.sortDesc = this.sortDesc;
      this.$emit('sortParam', this.sortParam);
    },
  },
  mounted() {
  }
}
</script>
<style scoped>
/* テーブルのヘッダーのフォントサイズ */
.v-data-table ::v-deep th {
  font-size: 18px !important;
  background-color: #90caf9;
}
/* テーブルデータのフォントサイズ */
.v-data-table ::v-deep td {
  /* font-size: 10px !important; */
  background-color: #e3f2fd;
}
.v-data-table ::v-deep tr:nth-child(odd) td {
  background: #fff;
}

.v-data-table ::v-deep tr:hover td {
  background-color: #eee;
}
::v-deep .v-data-table__empty-wrapper > td {
  font-size: 20px !important;
  /* font-family: sans-serif !important; */
}
>>>tr.text-color-red{
  color: red;
}

>>>tr.text-color-orange{
  color: orange;
}
</style>
