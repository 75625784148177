<template>
    <v-sheet rounded style="border: 2px solid #FBC02D;">
        <v-card class="elevation-4" color="yellow lighten-4">
            <v-card-title class="py-3">
                <v-container fluid>
                    <v-row align="center">
                        <!-- 最小化による表示の切り替え -->
                        <v-col v-show="!minimizeFlg" cols="10">
                            <v-row align="center">
                                <v-col cols="auto" class="text-truncate pa-0">
                                    <span class="text-subtitle-1 ml-4">対応内容</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col v-show="minimizeFlg" cols="10" class="py-4">
                            <v-row align="center">
                                <v-col cols="auto" class="text-truncate pa-0">
                                    <span class="text-subtitle-1 ml-4">対応内容：</span>
                                    <span class="text-subtitle-1">{{ inquiryContent.correspondenceInfo }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-spacer></v-spacer>
                        <!-- 最小化による表示の切り替え -->
                        <v-btn
                            v-show="!minimizeFlg"
                            rounded
                            small
                            color="grey darken-1"
                            dark
                            class="px-5 mr-4"
                            @click="minimizeFlg = !minimizeFlg"
                        >最小化</v-btn>
                        <v-btn
                            v-show="minimizeFlg"
                            rounded
                            small
                            color="grey darken-1"
                            class="white--text px-5 mr-4"
                            @click="minimizeFlg = !minimizeFlg"
                        >開く</v-btn>
                    </v-row>
                </v-container>
            </v-card-title>
            <!-- 最小化状態なら非表示 -->
            <div v-show="!minimizeFlg">
                <v-divider class="mx-4" style="border-width: 1px;"></v-divider>
                <v-card-text class="py-3">
                    <v-row align="start">
                      <div
                          id="editor-container"
                          @click="setFocusToTextarea()"
                      >
                        <v-textarea
                            ma-0
                            pa-0
                            hide-details
                            flat
                            dense
                            v-model="inquiryContent.correspondenceInfo"
                            :readonly="historyModalFlg"
                            class="text-subtitle-1"
                            row-height="0"
                            placeholder="対応内容"
                            :background-color="getBackgroundColor(inquiryContent.correspondenceInfo, inquiryContent.oldCorrespondenceInfo)"
                            no-resize
                            loading="false"
                            style="float: left; width: 100%; overflow-y: hidden;padding-left: 10px"
                            :rows="getNumberOfLines(inquiryContent.correspondenceInfo)"
                        ></v-textarea>
                      </div>
                    </v-row>
                </v-card-text>
            </div>
        </v-card>
    </v-sheet>
</template>
<script>
import {getBackgroundColor} from '@/components/helpers/utils';
export default {
    props: {
      // 問い合わせ履歴モーダル
      historyModalFlg: {
        type: Boolean,
      },
      inquiryContent: {
            type: Object,
      },
      // 親コンポーネントよりmatterIdを取得
      matterId: {
        type: Number,
      },
    },
    data() {
        return {
          // 最小化の表示を切り替えるflg
          minimizeFlg: false,
          mountCompletion: false,
          textWidth: 0,
        }
    },
    watch:{
      inquiryContent: {
        handler(val){
          if (this.mountCompletion){
            this.saveState(val.correspondenceInfo)
          }
        },
        deep: true
      },
      $route() {
        this.mountCompletion = true;
        this.restoreState();
      },
      matterId: function(){
        this.mountCompletion = false;
      },
    },
    methods: {
      getBackgroundColor,
      restoreState() {
        let matterState = this.$store.getters.getMatterState(this.matterStateKey);
        if (typeof matterState === 'string')  this.inquiryContent.correspondenceInfo = matterState
        else if (this.matterId <= 0) this.inquiryContent.correspondenceInfo = '';
      },
      saveState(data) {
        this.$store.dispatch('saveMatterStateAction', { matterId: this.matterId, key: this.matterStateKey, data })
      },
      getNumberOfLines (text) {
        /** replacement for the 'auto-grow' property of v-textarea
         *  as this feature has issue with maintaining the scroll
         *  position of the textarea.
         *  Source: https://github.com/vuetifyjs/vuetify/issues/5314
         */
        let countLine = 1;
        if (text && typeof text === 'string') {
          // replace makes sure, that this works with line breaks of different OS
          let lines = text.replace(/\r\n/g, '\n').split('\n');
          let numCharPerLine = Math.round(this.textWidth/15.15);
          if (numCharPerLine > 0){
              lines.forEach(line => {
                countLine += Math.ceil(line.length/numCharPerLine);
              })
            }else{
            countLine = lines.length;
          }
          if (this.historyModalFlg && countLine > 10){
            countLine = 10
          }
        }
        return countLine;
      },
      setFocusToTextarea () { /* [1] */
        const textLength = this.$el.querySelector('textarea').value.length
        if (this.$el.querySelector('textarea') !== document.activeElement) {
          this.$el.querySelector('textarea').focus()
          this.$el.querySelector('textarea').setSelectionRange(textLength, textLength)
        }
      },
      windowResizeHandler() {
        this.textWidth = this.$el.querySelector('textarea').clientWidth;
      }
    },
    computed: {
      matterStateKey(){
        return `matter_state_${this.matterId}_correspondence_${this.inquiryContent.id}`;
      }
    },
    created() {
      window.addEventListener("resize", this.windowResizeHandler);
    },
    destroyed() {
      window.removeEventListener("resize", this.windowResizeHandler);
    },
    mounted() {
      this.mountCompletion = true;
      this.restoreState();
      this.$nextTick(() => {
        this.textWidth = this.$el.querySelector('textarea').clientWidth;
      })
    }
}
</script>
<style scoped>
#editor-container {
  height: 100%;
  float: left;
  width: 100%;
  overflow-y: auto;
  cursor: text;
}
</style>
