<template>
    <v-card class="ma-1" :style="{backgroundColor: this.$store.state.backgroundColorInquiryContent ? this.$store.state.backgroundColorInquiryContent : this.defaultBackgroundColor}">
        <v-card-title>
            <v-row align="center">
                <v-col cols="7">
                  <v-row>
                    <v-col cols="3">
                      <span class="text-h6 ml-3">問い合わせ内容<span style="color: red;">*</span></span>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        rounded
                        color="primary"
                        style="color: white"
                        large
                        class="text-subtitle-1 my-auto"
                        v-if="!readonlyFlg"
                        @click="InquiryContentsCardTelRecodeModalOpen"
                      >問い合わせ内容を入力</v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        rounded
                        color="purple darken-3"
                        style="color: white"
                        large
                        class="text-subtitle-1 px-11"
                        v-if="!readonlyFlg"
                        @click="InquiryContentsCorrespondenceInfoModalOpen"
                      >対応内容を入力</v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        rounded
                        color="lime darken-3 px-11"
                        style="color: white"
                        large
                        class="text-subtitle-1"
                        v-if="!readonlyFlg"
                        @click="InquiryContentsMemoInfoModalOpen"
                      >対応メモを入力</v-btn>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="5" class="py-0">
                  <v-row>
                    <v-col cols="3">
                      <v-btn
                        rounded
                        color="primary"
                        style="color: white; float: right"
                        large
                        class="text-subtitle-1 px-6 mr-1"
                        v-if="!readonlyFlg"
                        @click="RequestForImprovementModalOpen"
                      >要望事項</v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                          rounded
                          color="primary"
                          style="color: white; float: right"
                          large
                          class="text-subtitle-1 px-6 mr-1"
                          v-if="!readonlyFlg"
                          @click="replyMail"
                      >メール作成</v-btn>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                          rounded
                          color="primary"
                          style="color: white; float: right"
                          large
                          class="text-subtitle-1 px-3"
                          v-if="!readonlyFlg"
                          @click="openMail"
                      >テキストコピー</v-btn>
                    </v-col>
                    <v-col cols="2" class="mr-6">
                      <v-btn
                        rounded
                        class="px-6"
                        color="primary"
                        @click="openColorPickerBackgroundModal"
                      > 背景設定
                      </v-btn>
                    </v-col>
                    <v-dialog v-model="colorPickerBackground"
                              persistent
                              scrollable
                              max-width="350px">
                      <ColorPickerBackgroundModal
                        @closeColorPickerBackgroundModal="closeColorPickerBackgroundModal"
                        @updateBackgroundColor="updateBackgroundColor"
                        @resetBackgroundColor="resetBackgroundColor"
                        :backgroundColor="backgroundColor"
                        :defaultBackgroundColor="defaultBackgroundColor"
                      />
                    </v-dialog>
                  </v-row>
                </v-col>
                <v-spacer></v-spacer>
            </v-row>
            <v-dialog v-model="RequestForImprovementModal"
                      persistent
                      max-width="40%"
                      style="overflow-y: inherit!important;">
              <RequestForImprovementModal
                :matterId="matterId"
                :RequestForImprovementModal="RequestForImprovementModal"
                :matterStatusData = "matterStatusData"
                :matterProductData = "matterProductData"
                :RequestForImprovementModalOpen="RequestForImprovementModalOpen"
                @RequestForImprovementModalClose = "RequestForImprovementModalClose"
                @saveRequestForImprovement = "saveRequestForImprovement"
              />
              <!-- ローディング中にスピナーを表示させる -->
              <v-overlay :value="loadingCount > 0">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-overlay>
            </v-dialog>
            <v-dialog v-model="InquiryContentsCardTelRecodeModal"
                      persistent
                      max-width="50%"
                      style="overflow-y: inherit!important;">
              <div v-for="inquiryContent in matterInquiriesData" :key="inquiryContent.id">
                <!-- 問合せ(受信)の場合 -->
                <div v-if="inquiryContent.receiveSendFlg === 0">
                <InquiryContentsCardTelRecodeModal
                  :inquiryContent="inquiryContent"
                  :matter-id="matterId"
                  :InquiryContentsCardTelRecodeModal="InquiryContentsCardTelRecodeModal"
                  @saveCardTelRecode = "saveCardTelRecode"
                  @InquiryContentsCardTelRecodeModalClose = "InquiryContentsCardTelRecodeModalClose"
                ></InquiryContentsCardTelRecodeModal>
                </div>
              </div>
              <!-- ローディング中にスピナーを表示させる -->
              <v-overlay :value="loadingCount > 0">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-overlay>
            </v-dialog>
            <v-dialog v-model="InquiryContentsMemoInfoModal"
                      persistent
                      max-width="50%"
                      style="overflow-y: inherit!important;">
              <InquiryContentsMemoInfoModal
                v-bind:matterStatusData.sync = "matterStatusData"
                :matter-id="matterId"
                :InquiryContentsMemoInfoModal="InquiryContentsMemoInfoModal"
                @InquiryContentsMemoInfoModalClose = "InquiryContentsMemoInfoModalClose"
                @saveMemo = "saveMemo"
              />
              <!-- ローディング中にスピナーを表示させる -->
              <v-overlay :value="loadingCount > 0">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-overlay>
            </v-dialog>
          <!-- 対応情報を入力画面 -->
            <v-dialog v-model="InquiryContentsCorrespondenceInfoModal"
                      persistent
                      max-width="50%"
                      style="overflow-y: inherit!important;">
              <InquiryContentsCorrespondenceInfoModal
                :matterId="matterId"
                :InquiryContentsCorrespondenceInfoModal="InquiryContentsCorrespondenceInfoModal"
                v-bind:matterStatusData.sync="matterStatusData"
                v-bind:matterInquiriesData.sync="matterInquiriesData"
                v-on:update:matterInquiriesData.capture="reloadInquiryContents"
                @saveCorrespondence = "saveCorrespondence"
                @InquiryContentsCorrespondenceInfoModalClose = "InquiryContentsCorrespondenceInfoModalClose"
              />
              <!-- ローディング中にスピナーを表示させる -->
              <v-overlay :value="loadingCount > 0">
                <v-progress-circular indeterminate></v-progress-circular>
              </v-overlay>
            </v-dialog>
          <!-- 返答を入力(メール作成)画面 -->
            <vue-final-modal
              v-model="mailCreateFlg"
              classes="modal-container"
              :content-class="`modal-content ${modalFlgFullScreen ? 'fullScreenResize' : 'ResizeMain'}`"
              :ssr="true"
              :click-to-close="false"
              :drag="!modalFlgFullScreen"
              drag-selector=".modal__move"
              :resize="!modalFlgFullScreen"
              :resize-directions="['t', 'tr', 'r', 'br', 'b', 'bl', 'l', 'tl']"
              :min-width="350"
              :min-height="400"
              :max-width="Infinity"
              :max-height="Infinity"
              @resize:start="clickOutside"
              @resize:end="clickOutsideEnd"
            >
              <span class="modal__title">
                <v-row class="modal__move" align-content="center" style=" margin: 0 auto; justify-content: end;" @dblclick="mailModalCreateFlgResizeMove">
                    <v-btn
                      class="px-10 modal-btn"
                      height="38px"
                      width="40px"
                      @click="mailModalCreateFlgResizeMove"
                    > <v-icon size="24" v-if="modalFlgFullScreen" >mdi-crop-square</v-icon> <v-icon size="20" v-if="!modalFlgFullScreen">mdi-image-filter-none</v-icon></v-btn>
                    <v-btn
                      class="px-10 modal-btn"
                      height="38px"
                      width="40px"
                      @click="mailCreateFlgClose"
                    ><v-icon >mdi-close</v-icon></v-btn>
                </v-row>
              </span>
              <iframe
                ref="myIframe"
                :src="iframeSrc"
                class="modal__content"
                style="user-select: none"
              ></iframe>
              <v-card v-if="!modalFlgFullScreen" class="modal__footer" outlined color="blue-grey lighten-4">
                <v-row  align="center" class="modal__move" justify="center" style="height:65px;margin: 0">
                  <v-btn
                    rounded
                    color="warning"
                    x-large
                    class="px-10"
                    height="38px"
                    @click="mailCreateFlgClose"
                  >閉じる</v-btn>
                </v-row>
              </v-card>
            </vue-final-modal>
          <v-dialog v-model="textCopyMail"
                      persistent
                      scrollable
                      max-width="50%"
            >
              <InquiryContentsTextCopyMail
                :matterStatusData="matterStatusData"
                :matterInquiriesData="matterInquiriesData"
                :matterCustomerData="matterCustomerData"
                :textCopyMail="textCopyMail"
                @textCopyMailClose = "textCopyMailClose"
              />
            </v-dialog>
        </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <!-- 問い合せのやり取り -->
        <v-card-text v-if="mountCompletion" class="overflow-y-auto py-2" style="max-height: 600px;">
            <template v-if="matterInquiriesData.length > 0">
              <p v-if="exMatterNumber" class="px-6 pt-2 pb-0 ml-0 text-h6"> NO.{{ exMatterNumber }}</p>
              <p v-if="sidMatterNumber" class="px-6 pt-2 pb-0 ml-0 text-h6"> SIDお問い合わせ番号.{{ sidMatterNumber }}</p>
                <div v-for="inquiryContent in matterInquiriesData" :key="inquiryContent.id">
                    <!-- 問合せ(受信)の場合 -->
                    <div v-if="inquiryContent.receiveSendFlg === 0">
                        <v-row align="end" class="px-7">
                            <!-- 新規案件登録(電話対応) -->
                            <v-col cols="10">
                                <InquiryContentsCardTelRecode
                                    :inquiryContent="inquiryContent"
                                    :matter-id="matterId"
                                    :InquiryContentsCardTelRecodeModal="InquiryContentsCardTelRecodeModal"
                                ></InquiryContentsCardTelRecode>
                            </v-col>
                            <v-col cols="2" class="px-0">
                              <div v-if="!$store.state.auth.user.isViewer && !$store.state.auth.user.isGuest">
                                <p
                                  class="mb-0 text-subtitle-1"
                                  style="line-height: 1;"
                                >{{ getInquiryTypeName(inquiryContent.inquiryType) }}問い合せ
                                </p>
                                <span
                                  class="mb-0 text-subtitle-1"
                                  style="line-height: 1.5;"
                                >{{ getDate(inquiryContent.receiveTime) }}</span>
                                <span
                                  class="pl-3 mb-0 text-subtitle-1"
                                  style="line-height: 1.5;"
                                >{{ getTime(inquiryContent.receiveTime) }}</span>
                              </div>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- 回答(送信)の場合 -->
                    <div v-else-if="inquiryContent.receiveSendFlg === 1">
                        <v-row align="end" class="px-7">
                            <v-col cols="2" class="px-0">
                              <div v-if="!$store.state.auth.user.isViewer && !$store.state.auth.user.isGuest">
                                <p
                                  class="mb-0 text-subtitle-1"
                                  style="line-height: 1;"
                                >{{ inquiryContent.userName }}</p>
                                <span
                                  class="mb-0 text-subtitle-1"
                                  style="line-height: 1.5;"
                                >{{ getDate(inquiryContent.sendTime) }}</span>
                                <span
                                  class="pl-3 mb-0 text-subtitle-1"
                                  style="line-height: 1.5;"
                                >{{ getTime(inquiryContent.sendTime) }}</span>
                              </div>
                            </v-col>
                            <v-col cols="10" class="mt-3">
                                <InquiryContentsCardTelRecode
                                    :inquiryContent="inquiryContent" :matter-id="matterId" :historyModalFlg="historyModalFlg"
                                    :InquiryContentsCardTelRecodeModal="InquiryContentsCardTelRecodeModal"
                                ></InquiryContentsCardTelRecode>
                            </v-col>
                        </v-row>
                    </div>
                    <!-- 対応情報の場合 -->
                    <div
                        v-else-if="inquiryContent.receiveSendFlg === null && inquiryContent.correspondenceInfo !== null"
                    >
                        <v-row align="end" class="px-7">
                            <v-col cols="2" class="px-0">
                              <div v-if="!$store.state.auth.user.isViewer && !$store.state.auth.user.isGuest">
                                <p
                                  class="mb-0 text-subtitle-1"
                                  style="line-height: 1;"
                                >{{ inquiryContent.userName }}
                                  <span><v-btn rounded color="warning" v-if="!readonlyFlg" @click="openConfirmDeleteCorrDialog(inquiryContent)">削除</v-btn></span>
                                </p>
                                <span
                                  class="mb-0 text-subtitle-1"
                                  style="line-height: 1.5;"
                                >{{ getDate(inquiryContent.createdAt) }}</span>
                                <span
                                  class="pl-3 mb-0 text-subtitle-1"
                                  style="line-height: 1.5;"
                                >{{ getTime(inquiryContent.createdAt) }}</span>
                              </div>
                            </v-col>
                            <v-col cols="10" class="mt-3">
                                <InquiryContentsCardCorrespondenceInfo
                                    :inquiryContent="inquiryContent" :matter-id="matterId"
                                ></InquiryContentsCardCorrespondenceInfo>
                            </v-col>
                        </v-row>
                    </div>
                </div>
            </template>
            <template v-else>
                <div>
                    <v-alert type="info" class="mb-0">問い合わせ内容はありません</v-alert>
                </div>
            </template>
        </v-card-text>
        <v-divider
            class="mx-4 mt-1"
            color="#BDBDBD"
            style="border-width: 1px;"
            v-show="!historyModalFlg"
        />

        <v-card class="ma-4" color="grey lighten-3" v-if="this.matterStatusData.memo && !$store.state.auth.user.isViewer && !$store.state.auth.user.isGuest">
          <InquiryContentsMemoInfo v-bind:matterStatusData.sync = "matterStatusData" :matter-id="matterId" />
        </v-card>
       <!-- 問い合わせ履歴モーダルの場合は非表示 -->
        <div v-show="!historyModalFlg">

            <!-- 発送履歴を入力画面 -->
          <div v-show="shippingHistoryFlg" class="pb-5 ma-5">
            <v-row align="center" justify="center">
              <!-- 検索条件モーダル -->
              <v-dialog :value="shippingModal" persistent max-width="45%" style="margin-top:70px;" >
                <v-toolbar color="primary" dark>
                  <span class="text-h5 ml-3">発送履歴入力</span>
                  <v-spacer></v-spacer>
                </v-toolbar>
                <InquiryContentsShippingHistory
                  ref="shippingModal"
                  :shippingHistoryId="hasShippingData?1:undefined"
                  :show-modal="shippingModal"
                  :historyModalFlg="historyModalFlg"
                  :matterId="matterId"
                  :matterCustomerData="matterCustomerData"
                  :matterProductData="matterProductData"
                  :matterStatusData="matterStatusData"
                  :readonly-flg="readonlyFlg"
                  @modal-close="$emit('closeShippingModal')"
                  @isEditing ="isEditing"
                  v-bind:matterShipmentData.sync="matterShipmentData"
                  v-on:update:matterShipmentData.capture="reloadShipmentData"
                ></InquiryContentsShippingHistory>
              </v-dialog>
            </v-row>
          </div>
            <!-- ローディング中にスピナーを表示させる -->
            <v-overlay :value="loadingCount > 0">
                <v-progress-circular indeterminate></v-progress-circular>
            </v-overlay>
        </div>
        <div
            v-show="showConfirmDeleteCorrDialog">
          <v-dialog
              v-model="showConfirmDeleteCorrDialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                削除
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="text-h5">削除してもよろしいでしょうか。</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="primary" large class="px-7" @click="deleteCorrespondence">はい</v-btn>
                <v-btn rounded color="warning" large  class="px-5" @click="closeConfirmDeleteCorrDialog">キャンセル</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>

          </v-dialog>
          <v-dialog v-model="snackbarCreateNotifySuccess" scrollable max-width="500px">
            <v-card>
              <v-card-title class="text-h5" style="margin: auto">要望事項を投稿しました。</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn rounded color="primary" large class="px-15" @click="snackbarCreateNotifySuccess = false">確認</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </div>
    </v-card>
</template>
<script>
import InquiryContentsCardCorrespondenceInfo from './InquiryContentsCardCorrespondenceInfo.vue'
import InquiryContentsTextCopyMail from './InquiryContentsTextCopyMail.vue'
import InquiryContentsShippingHistory from './InquiryContentsShippingHistory.vue'
import InquiryContentsCardTelRecode from './InquiryContentsCardTelRecode.vue'
import updateMatterService from "@/services/updateMatterService";
import InquiryContentsMemoInfo from './InquiryContentsMemoInfo.vue'
import InquiryContentsCorrespondenceInfoModal from './InquiryContentsCorrespondenceInfoModal.vue'
import InquiryContentsMemoInfoModal from './InquiryContentsMemoInfoModal.vue'
import InquiryContentsCardTelRecodeModal from './InquiryContentsCardTelRecodeModal.vue'
import RequestForImprovementModal from './RequestForImprovementModal.vue'
import notificationService from "@/services/notificationService";
import ColorPickerBackgroundModal from './ColorPickerBackgroundModal.vue'
import userSettingService from "@/services/userSettingService";
import getMatterService from "@/services/getMatterService";

export default {
    props: {
        // 親コンポーネントよりmatterIdを取得
        matterId: {
            type: Number,
        },
        exMatterNumber: {
            type: [String],
        },
        sidMatterNumber: {
          type: [String],
        },
        // 親コンポーネントよりバックエンドapi取得_案件データ(basicInfo)を取得
        matterStatusData: {
          type: [String, Number, Object]
        },
        matterInquiriesData: {
            type: [Array]
        },
        matterShipmentData: {
          type: [Array]
        },
        readonlyFlg: {
          type: Boolean,
        },
        // 問い合わせ履歴モーダル
        historyModalFlg: {
            type: Boolean,
        },
        matterCustomerData: {
          type: Object
        },
        matterProductData: {
          type: Object
        },
        shippingModal: {
          type: Boolean,
        },
        hasShippingData:{
          type: Boolean,
        },
    },
    components: {
        'InquiryContentsCardCorrespondenceInfo': InquiryContentsCardCorrespondenceInfo,
        'InquiryContentsTextCopyMail': InquiryContentsTextCopyMail,
        'InquiryContentsShippingHistory': InquiryContentsShippingHistory,
        'InquiryContentsCardTelRecode': InquiryContentsCardTelRecode,
        'InquiryContentsMemoInfo': InquiryContentsMemoInfo,
        'InquiryContentsCorrespondenceInfoModal': InquiryContentsCorrespondenceInfoModal,
        'InquiryContentsMemoInfoModal': InquiryContentsMemoInfoModal,
        'InquiryContentsCardTelRecodeModal': InquiryContentsCardTelRecodeModal,
        'RequestForImprovementModal': RequestForImprovementModal,
        ColorPickerBackgroundModal,
    },
    data() {
        return {
            // 問い合せ種類
            inquiryType: [
                {
                    id: 0,
                    name: 'メール'
                },
                {
                    id: 1,
                    name: '電話'
                },
            ],
            // 発送履歴のデータ
            shippingHistoryData: [],
            // 「返答を入力(メール作成)」ボタンのflg
            mailCreateFlg: false,
            modalFlgFullScreen: false,
            textCopyMail: false,
            // 「対応情報を入力」ボタンのflg
            correspondenceInfoFlg: false,
            // 「発送履歴を入力」ボタンのflg
            shippingHistoryFlg: false,
            // mount処理完了フラグ
            mountCompletion: false,
            // loading表示用
            loadingCount: 0,
            selectedCorrespondence: null,
            showConfirmDeleteCorrDialog:false,
            InquiryContentsCorrespondenceInfoModal:false,
            InquiryContentsCardTelRecodeModal:false,
            InquiryContentsMemoInfoModal:false,
            RequestForImprovement:false,
            RequestForImprovementModal:false,
            snackbarCreateNotifySuccess:false,
            colorPickerBackground:false,
            correspondenceInfo: '',
            backgroundColor: this.$store.state.backgroundColorInquiryContent,
            defaultBackgroundColor: '#98BB93',
            iframeSrc: null,
            pac16InquiryId: '',
            replyMailFlag: false,
        }
    },
    methods: {
        clickOutside:function () {
          const iframe = this.$refs.myIframe;
          iframe.style.pointerEvents  = 'none';
        },
        clickOutsideEnd:function () {
          const iframe = this.$refs.myIframe;
          iframe.style.pointerEvents  = 'auto';
        },
        // 問い合せもしくは返答の「日付け」or「時分秒」を返す。「０：日付」「1：時分秒」
        getDate(date) {
            const newDate = new Date(date);
            const year = newDate.getFullYear();
            const month = ("00" + (newDate.getMonth() + 1)).slice(-2);
            const day = ("00" + newDate.getDate()).slice(-2);
            return year + '/' + month + '/' + day
        },
        getTime(date) {
            const newDate = new Date(date);
            const hours = ("00" + newDate.getHours()).slice(-2);
            const minutes = ("00" + newDate.getMinutes()).slice(-2);
            const seconds = ("00" + newDate.getSeconds()).slice(-2);

            return hours + ':' + minutes + ':' + seconds
        },

        // 問い合せ種類のflgを取得し、問い合わせ名を返す
        getInquiryTypeName(inquiryTypeFlg) {
            for (let i = 0; i < this.inquiryType.length; i++) {
                if (this.inquiryType[i].id === inquiryTypeFlg) {
                    return this.inquiryType[i].name
                }
            }
        },
        // TEL対応の場合、対応情報入力欄を開く
        updateCorrespondenceInfoFlg() {
            if (this.matterInquiriesData[0].inquiryType === 1) {
                this.correspondenceInfoFlg = true
            } else {
                this.correspondenceInfoFlg = false
            }
        },
        // ルートが変更された時に呼び出す関数(この関数がないと/inquiryhandle/1から/inquiryhandle/2に変わった時にコンポーネントが更新されない)
        reloadInquiryContents() {
            // TEL対応の場合、対応情報入力欄を開く
            this.updateCorrespondenceInfoFlg();
        },
        closeConfirmDeleteCorrDialog(){
          this.showConfirmDeleteCorrDialog = false;
          this.selectedCorrespondence = null;
        },
        openConfirmDeleteCorrDialog(inquiryContent){
          this.showConfirmDeleteCorrDialog = true;
          this.selectedCorrespondence = inquiryContent;
        },
        async deleteCorrespondence() {
          let inquiryContent = this.selectedCorrespondence;
          let corrId = inquiryContent.id;
          this.closeConfirmDeleteCorrDialog();
          if (corrId) {
            corrId = corrId.replace('corr-', '');
            this.loadingCount++;

            // DB更新(delete correspondence)
            await updateMatterService.deleteCorrespondenceData(
                this.matterId,
                corrId
            );

            let newMatterInquiriesData = this.matterInquiriesData.filter(element => element.id != inquiryContent.id);
            this.$emit('update:matterInquiriesData', newMatterInquiriesData);
            this.loadingCount--;
          }
        },
        async saveAllCorrespondence(matterId, inquiryContent) {
          if (inquiryContent.oldCorrespondenceInfo != inquiryContent.correspondenceInfo){
            let corrId = inquiryContent.id;
            if (corrId) {
              corrId = corrId.replace('corr-', '');
              this.loadingCount++;
              if (inquiryContent.correspondenceInfo) {
                // DB更新(update correspondence)
                let correspondenceData = {correspondence_content: inquiryContent.correspondenceInfo};

                await updateMatterService.updateCorrespondenceData(
                  matterId,
                  corrId,
                  correspondenceData,
                );
                inquiryContent.oldCorrespondenceInfo = inquiryContent.correspondenceInfo;
              } else {
                // DB更新(delete correspondence)
                await updateMatterService.deleteCorrespondenceData(
                  matterId,
                  corrId
                );

                let newMatterInquiriesData = this.matterInquiriesData.filter(element => element.id != inquiryContent.id);
                this.$emit('update:matterInquiriesData', newMatterInquiriesData);
              }
              this.loadingCount--;
            }
          }
        },
        async saveCorrespondence(matterId,correspondenceInfo) {
          this.loadingCount++;
          // DB更新(create correspondence)
          let correspondenceData = {correspondence_content: correspondenceInfo};
          if (matterId > 0) {
            await updateMatterService.createCorrespondenceData(
              matterId,
              correspondenceData,
            ).then(response=>{
              let newCorrespondence = response.data.data;
              this.matterInquiriesData.push({
                "id": 'corr-' + newCorrespondence.id,
                "userId": null,
                "userName": newCorrespondence.created_by,
                "receiveSendFlg": null,
                "inquiryType": null,
                "receiveMailId": null,
                "sendMailId": null,
                "receiveTime": null,
                "sendTime": null,
                "subject": null,
                "content": null,
                "correspondenceInfo": newCorrespondence.correspondence_content,
                "oldCorrespondenceInfo": newCorrespondence.correspondence_content,
                "shippingHistoryId": null,
                "createdAt": newCorrespondence.created_at,
                "updatedAt": newCorrespondence.created_at
              })

              this.$emit('update:matterInquiriesData', this.matterInquiriesData);
              this.correspondenceInfo = '';
            }).catch(error=>{
              if(error.response.status === 403){
                const editing_by = error.response.data.message.editing_by;
                this.$emit('isEditing',editing_by);
              }
            });
          } else {
            const response = await updateMatterService.createMatterCorrespondenceData(correspondenceData);
            this.correspondenceInfo = '';
            // 取得(更新)したデータを格納
            const newMatter = response.data.data;

            this.$emit('create:matterInquiry', newMatter);
          }
          this.InquiryContentsCorrespondenceInfoModalClose();
          this.loadingCount--;
        },
        async saveCardTelRecode(matterId,inquiryContent) {
          this.loadingCount++;
          if (matterId > 0) {
            await updateMatterService.updateInquiryData(matterId, inquiryContent.id, inquiryContent)
              .catch(error=>{
                if(error.response.status === 403){
                  const editing_by = error.response.data.message.editing_by;
                  this.$emit('isEditing',editing_by);
                }
            });
            inquiryContent.oldContent = inquiryContent.content;
          }else {
            const response = await updateMatterService.createMatterInquiryData(inquiryContent);
            // 取得(更新)したデータを格納
            const newMatter = response.data.data;

            this.$emit('create:matterInquiry', newMatter);
          }
          this.InquiryContentsCardTelRecodeModalClose();
          this.loadingCount--;
        },
        saveMemo: async function (matterId,matterStatusData) {
          this.loadingCount++;
          if (matterId > 0) {
            await updateMatterService.updateMatterStatusData(matterId, matterStatusData).then(response=>{
              const basicInfo = response.data.data.basicInfo;
              basicInfo.oldMemo = basicInfo.memo;
              this.$emit('update:matterStatusData', basicInfo);
            }).catch(error=>{
              if(error.response.status === 403){
                const editing_by = error.response.data.message.editing_by;
                this.$emit('isEditing',editing_by);
              }
            });

          } else {
            const response = await updateMatterService.createMatterStatusData(matterStatusData);
            // 取得(更新)したデータを格納
            const newMatter = response.data.data;

            this.$emit('create:matterInquiry', newMatter);
          }
            this.InquiryContentsMemoInfoModalClose();
          this.loadingCount--;
        },
        async saveInquiryContent(matterId, inquiryContent) {
          if (inquiryContent.oldContent != inquiryContent.content){
            this.loadingCount++;
            if (inquiryContent.id) {
              await updateMatterService.updateInquiryData(matterId, inquiryContent.id, inquiryContent);
            }else{
              await updateMatterService.createInquiryData(matterId, inquiryContent);
            }
            this.loadingCount--;
            inquiryContent.oldContent = inquiryContent.content;
          }
        },
        async saveAllMatter(matterId){
          await this.matterInquiriesData.forEach(inquiryContent => {
            if (inquiryContent.receiveSendFlg !== null){
              this.saveInquiryContent(matterId, inquiryContent)
            }else if (inquiryContent.receiveSendFlg === null && inquiryContent.correspondenceInfo !== null){
              this.saveAllCorrespondence(matterId, inquiryContent)
            }
          })
        },
        async saveRequestForImprovement(notification) {
            // APIを呼び出し、保存処理を行う
            try {
              await notificationService.createNotification(notification);
              this.RequestForImprovementModalClose();
              this.snackbarCreateNotifySuccess = true;
              let response = await notificationService.countUnreadNotifications();
              this.$store.commit('updateUnreadNotify', response.data.amount);
            } catch (e) {
              console.log(e)
            }
        },
        updateBackgroundColor(backgroundColorInquiryContent) {
          const data = {
            background_inquiry_content: backgroundColorInquiryContent,
          }
          this.$store.commit('updateBackgroundColorInquiryContent', backgroundColorInquiryContent);
          userSettingService.updateMyUserSettings(data);
        },
        resetBackgroundColor() {
          const data = {
            background_inquiry_content: this.defaultBackgroundColor,
          }
          this.$store.commit('updateBackgroundColorInquiryContent', this.defaultBackgroundColor);
          userSettingService.updateMyUserSettings(data);
          this.closeColorPickerBackgroundModal();
        },
        openColorPickerBackgroundModal() {
          if (this.$store.state.backgroundColorInquiryContent == null){
            this.backgroundColor = this.defaultBackgroundColor
          }else {
            this.backgroundColor = this.$store.state.backgroundColorInquiryContent
          }
          this.colorPickerBackground = true
        },
        closeColorPickerBackgroundModal() {
          this.colorPickerBackground = false
        },
        async reloadShipmentData(){
          this.$emit('reloadMatterShipmentData');
        },
        InquiryContentsCorrespondenceInfoModalOpen(){
          this.InquiryContentsCorrespondenceInfoModal = true
        },
        InquiryContentsCorrespondenceInfoModalClose(){
          this.InquiryContentsCorrespondenceInfoModal = false
        },
        InquiryContentsCardTelRecodeModalOpen(){
          this.InquiryContentsCardTelRecodeModal = true
        },
        InquiryContentsCardTelRecodeModalClose(){
          this.InquiryContentsCardTelRecodeModal = false
        },
        InquiryContentsMemoInfoModalOpen(){
          this.InquiryContentsMemoInfoModal = true
        },
        InquiryContentsMemoInfoModalClose(){
          this.InquiryContentsMemoInfoModal = false
        },
        RequestForImprovementModalOpen(){
          this.RequestForImprovementModal = true
        },
        RequestForImprovementModalClose(){
          this.RequestForImprovementModal = false
        },
        mailCreateFlgClose(){
          this.mailCreateFlg = false;
          this.iframeSrc = '';
          this.pac16InquiryId = '';
          this.replyMailFlag = false;
        },
        textCopyMailClose(){
          this.textCopyMail = false
        },
        isEditing(editing_by){
          this.$emit("isEditing",editing_by)
        },
        async openMail(){
          this.textCopyMail = true;
        },
        mailModalCreateFlgResizeMove(){
          this.modalFlgFullScreen = !this.modalFlgFullScreen;
        },
        async replyMail(){
          let urlOrigin = window.location.href;
          let url = process.env.VUE_APP_PAC_21_URL;
          let paramUrl = '?';
          this.pac16InquiryId = this.matterId;
          if (this.matterId > 0){
             let response = await getMatterService.getPac16InquiryId(this.matterId);
             let data = response.data.data;
             if (data.pac16InquiryId && data.receiveAddress){
               this.pac16InquiryId = data.pac16InquiryId;
               paramUrl = `box/${data.receiveAddress}/${data.pac16InquiryId}/reply?back=true&`;
             }
          }
          url = url + ('login?redirect=' + encodeURIComponent(url + paramUrl +`mode=shachihatadesk&urlorigin=${urlOrigin}`));
          this.mailCreateFlg = true;
          this.modalFlgFullScreen = false;
          this.iframeSrc = url;
        },
        getCorrespondenceContentFromPac21(event) {
          let data = event.data;
          let url = process.env.VUE_APP_PAC_21_URL;
          if(data && data.from == url){
            if(data.type == 'pac21_link'){
              window.open(url + (`login?t=${data.token}&redirect=${encodeURIComponent(data.result)}`), '_blank');
            }
            if(['pac21_send', 'pac21_send_opener'].includes(data.type) && data.inquiry_id == this.pac16InquiryId){
              if(this.replyMailFlag && data.type == 'pac21_send_opener') return;
              this.saveCorrespondence(this.matterId, data.result);
              this.replyMailFlag = true;
            }
          }
        },
    },
    mounted() {
      window.addEventListener('message', this.getCorrespondenceContentFromPac21)
      // TEL対応の場合、対応情報入力欄を開く
        this.updateCorrespondenceInfoFlg();
        // マウント時に行う処理
        this.mountCompletion = true;
    },
    beforeUnmount() {
      window.removeEventListener('message', this.getCorrespondenceContentFromPac21)
    },
}
</script>
<style scoped>
.v-btn:not(.v-btn--round).v-size--large{
 height: 36px;
}
::v-deep .modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .modal-content {
  display: flex;
  flex-direction: column;
  touch-action: none;
  position: absolute;
  margin: unset;
  background: #1976d2 !important;
  border-radius: 3px;
}
::v-deep .fullScreenResize {
  height: 100% !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
}
::v-deep .ResizeMain {
  max-height: 100%;
  height: 822px;
  width: 1499px;
}
::v-deep .modal__content {
  width: 100%;
  height: 100%;
}
::v-deep .modal-btn {
  border-radius: 0;
  box-shadow: none;
  background-color: #1976d2 !important;
  color: white;
}
</style>
<style>
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 95% !important;
}
</style>
