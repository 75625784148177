import Vue from 'vue'
import Vuex from 'vuex'
import auth from '@/store/modules/auth';
import snackbar from '@/store/helpers/snackbar';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import LZUTF8 from 'lzutf8';
import VueCookies from 'vue-cookies';
import constants from "@/components/helpers/const";

Vue.use(VueCookies);

const ls = new SecureLS({ isCompression: false });

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    // 現在対応中の問い合わせの情報をここに登録する
    // ナビゲーションバーの問い合わせ一覧とリンクする
    navbarMatterLists: [
      {
        matterTitle: '一覧表示', matterLink: '/webcs-matter-list'
      },
      // ContactListTable.vueからダブルクリックされた行が追加される
      // データサンプル
      // {
      //   matterId: 1, matterTitle: 'キャップを無くした件', matterLink: 'inquiryhandle/案件id(matterId)'
      // },
    ],
    navbarOShopMatterLists: [
      {
        matterTitle: '一覧表示', matterLink: '/oshop-matter-list'
      },
    ],
    navbarSIDMatterLists: [
      {
        matterTitle: '一覧表示', matterLink: '/sid-matter-list'
      },
    ],

    // ヘッダーのタイトルリスト
    headerTitleList: [
      {
        titleName: 'ダッシュボード',
        titleIcon: 'mdi-clipboard-text-outline'
      },
      {
        titleName: 'お客様相談室',
        titleIcon: 'mdi-email-multiple-outline'
      },
      {
        titleName: '新規受付',
        titleIcon: 'mdi-email-outline'
      },
      {
        titleName: 'マスター管理',
        titleIcon: 'mdi-database-outline'
      },
      {
        titleName: 'お問い合わせ内容検索',
        titleIcon: 'mdi-email-search-outline'
      },
      {
        titleName: 'お知らせ一覧',
        titleIcon: 'mdi-bell-outline'
      },
      {
        titleName: 'ダウンロード状況確認',
        titleIcon: 'mdi-cloud-download-outline'
      },
      {
        titleName: '取り込み状況',
        titleIcon: 'mdi-cloud-upload-outline'
      },
    ],
    // ヘッダーのタイトルを表示画面に合わせて更新する
    headerTitle: {
      titleName: 'ダッシュボード',
      titleIcon: 'mdi-clipboard-text-outline'
    },
    // MatterListSeach Search Mode Status from DashBoard.
    dash2mattelst: {
      valid: false,   // true: 下の値が有効
      value: -1  // 対応状況（直近1か月／全体）0:'未対応', 1:'対応中', 2:'保留', 3:'対応済', 対応状況（直近1か月／担当分）4:'未対応', 5:'対応中', 6:'保留', 7:'対応済'
    },
    // 問い合わせ履歴リスト
    inquiryHistorySearchResultList: [
      // {
      //   historySearchFlg: 1,
      //   historyIcon: 'mdi-account-multiple',
      //   historySearchKeyword: 'インク 黒',
      // },
      // {
      //   historySearchFlg: 2,
      //   historyIcon: 'mdi-forum-outline',
      //   historySearchKeyword: '問い合わせ ペン',
      // },
    ],
    unreadNotify: 0,
    backgroundColorCustomer: null,
    backgroundColorProduct: null,
    backgroundColorInquiryContent: null,
    classBackgrourdColorChangeForm:'',
  },
  getters: {
    // 現在開いている案件一覧を取得
    getMatterList: state => {
      return state.navbarMatterLists;
    },
    getOShopMatterList: state => {
      return state.navbarOShopMatterLists;
    },
    getSIDMatterList: state => {
      return state.navbarSIDMatterLists;
    },
    getServerFlgByMatterId: (state) => (matterId) => {
      const isWebCsMatter = state.navbarMatterLists.find(item => item.matterId === matterId)
      if(isWebCsMatter) return isWebCsMatter.serverFlag;
      const isOShopMatter = state.navbarOShopMatterLists.find(item => item.matterId === matterId)
      if(isOShopMatter) return isOShopMatter.serverFlag;
      const isSIDMatter = state.navbarSIDMatterLists.find(item => item.matterId === matterId)
      if(isSIDMatter) return isSIDMatter.serverFlag;
    },
    // ヘッダータイトルを取得
    getHeaderTitle: state => {
      return state.headerTitle;
    },
    // Get Search Mode from DashBoard to MatterListSeach. If return -1 then a data is invalid.
    getDash2mattelst: state => {
      if (true == state.dash2mattelst.valid) {
        return state.dash2mattelst.value
      }
      return -1
    },
    // 問い合わせ履歴検索結果一覧
    getInquiryHistorySearchResultList: state => {
      return state.inquiryHistorySearchResultList;
    },
    // get matter state
    // eslint-disable-next-line no-unused-vars
    getMatterState: (state) => (key) => {
      let matterState = sessionStorage.getItem(key);
      if (matterState){
        try{
          matterState = LZUTF8.decompress(matterState, {inputEncoding: "StorageBinaryString", outputEncoding: "String"});
          matterState = JSON.parse(matterState);
          return matterState;
        }catch(error){
          console.error(error)
          return {};
        }
      }else{
        return {};
      }
    }
  },
  mutations: {
    // 画面遷移時にヘッダーのタイトルを変更する
    headerTitleChange: function (state, flg) {
      state.headerTitle.titleName = state.headerTitleList[flg].titleName;
      state.headerTitle.titleIcon = state.headerTitleList[flg].titleIcon;
    },
    // 問い合せ対応画面で「問い合せを閉じる」ボタンを押した時の処理
    updateMatterList: function (state, newMatterList) {
      state.navbarMatterLists = newMatterList;
    },
    updateOShopMatterList: function (state, newMatterList) {
      state.navbarOShopMatterLists = newMatterList;
    },
    updateSIDMatterList: function (state, newMatterList) {
      state.navbarSIDMatterLists = newMatterList;
    },
    // ナビゲーションバーの案件タイトルの更新
    updateMatterTitle: function (state, updateTitle) {
      // インデックス取得
      const index = state.navbarMatterLists.findIndex(({ matterId }) => matterId === updateTitle.id);
      // タイトル更新
      if (index >= 0){
        state.navbarMatterLists[index].matterTitle = updateTitle.title;
      }
    },

    updateMatterUrgency: function (state, updateUrgency) {
      // インデックス取得
      const index = state.navbarMatterLists.findIndex(({ matterId }) => matterId === updateUrgency.id);
      // タイトル更新
      if (index >= 0){
        state.navbarMatterLists[index].matterUrgency = updateUrgency.urgency;
      }
    },

    // Set MatterListSeach Search Mode from DashBoard. If 0 <= value <= 7 then valid set true.
    setDash2mattelst: function (state, value) {
      if ((0 <= value) && (13 >= value)) {
        state.dash2mattelst.valid = true
        state.dash2mattelst.value = value
      } else {
        state.dash2mattelst.valid = false
        state.dash2mattelst.value = -1
      }
    },
    // ナビゲーションバーの案件一覧にタブを追加
    updateNavbarMatterList: function (state, newNavbarMatter) {
      switch (newNavbarMatter.serverFlag) {
        case constants.serverFlag.webcs:
          state.navbarMatterLists.push(newNavbarMatter);
          break;
        case constants.serverFlag.oshop:
          state.navbarOShopMatterLists.push(newNavbarMatter);
          break;
        case constants.serverFlag.sid:
          state.navbarSIDMatterLists.push(newNavbarMatter);
          break;
      }
    },
    // ナビゲーションバーに履歴検索結果のタブを追加
    updateInquiryHistorySearchResultList: function (state, newHistorySearchTab) {
      state.inquiryHistorySearchResultList.push(newHistorySearchTab);
    },
    // ナビゲーションバーの履歴検索結果のタブを削除
    deleteInquiryHistorySearchResultTab: function (state, newHistorySearchList) {
      state.inquiryHistorySearchResultList = newHistorySearchList;
    },
    updateUnreadNotify: function (state, val){
      state.unreadNotify = val
    },
    updateBackgroundColorCustomer: function (state, val){
      state.backgroundColorCustomer = val
    },
    updateBackgroundColorInquiryContent: function (state, val){
      state.backgroundColorInquiryContent = val
    },
    updateBackgroundColorProduct: function (state, val){
      state.backgroundColorProduct = val
    },
    updateBackgroundColor: function (state,val) {
      state.classBackgrourdColorChangeForm = val;
    }
  },
  actions: {
    // 画面遷移時にヘッダーのタイトルを変更する
    headerTitleChangeAction: function (context, flg) {
      context.commit('headerTitleChange', flg);
    },
    // 問い合せ対応画面で「問い合せを閉じる」ボタンを押した時の処理
    removeAllMatterListAction: function (context) {
      context.commit('updateMatterList', []);
      context.commit('updateOShopMatterList', []);
      context.commit('updateSIDMatterList', []);
    },
    // ナビゲーションバーの案件タイトルの更新
    updateMatterTitleAction: function (context, { matterId, matterTitle }) {
      const updateTitle = {
        id: matterId,
        title: matterTitle
      }
      context.commit('updateMatterTitle', updateTitle);
    },

    updateMatterUrgencyAction: function (context, { matterId, matterUrgency }) {
      const updateUrgency = {
        id: matterId,
        urgency: matterUrgency
      }
      context.commit('updateMatterUrgency', updateUrgency);
    },
    // ナビゲーションバーの案件一覧にタブを追加
    updateNavbarMatterListAction: function (context, newNavbarMatter) {
      context.commit('updateNavbarMatterList', newNavbarMatter)
    },
    // ナビゲーションバーに履歴検索結果のタブを追加
    updateInquiryHistorySearchResultListAction: function (context, newHistorySearchTab) {
      context.commit('updateInquiryHistorySearchResultList', newHistorySearchTab)
    },
    // ナビゲーションバーの履歴検索結果のタブを削除
    deleteInquiryHistorySearchResultTabAction: function (context, newHistorySearchList) {
      context.commit('deleteInquiryHistorySearchResultTab', newHistorySearchList)
    },
    // save Matter state to session storage
    saveMatterStateAction: function (context, { matterId, key, data }) {
      if (matterId <= 0 || sessionStorage.getItem(key)){
        let storeState = JSON.stringify(data)
        storeState = LZUTF8.compress(storeState, {outputEncoding: "StorageBinaryString"});
        sessionStorage.setItem(key, storeState);
      }
    },
    // clear Matter state to session storage
    updateMatterStateKeyAction: function (context, { oldMatterId, newMatterId}) {
      const keys = Object.keys(sessionStorage).filter(key => key.indexOf(`matter_state_${oldMatterId}`) != -1)
      keys.forEach(key => {
        let newKey = key.replace(`_${oldMatterId}_`, `_${newMatterId}_`)
        let matterState = sessionStorage.getItem(key);
        sessionStorage.setItem(newKey, matterState);
        sessionStorage.removeItem(key);
      })
    },

    // clear Matter state to session storage
    clearMatterStateAction: function (context, { matterId}) {
      const keys = Object.keys(sessionStorage).filter(key => key.indexOf(`matter_state_${matterId}`) != -1)
      keys.forEach(key => sessionStorage.removeItem(key))
    },

    removeMatterListAction: function (context, matterId) {
      const findByWebcsMatter = context.state.navbarMatterLists.find(item => item.matterId === matterId)
      if (findByWebcsMatter) {
        const newWebcsMatter = context.state.navbarMatterLists.filter(item => item.matterId !== matterId)
        return context.commit('updateMatterList', newWebcsMatter);
      }
      const findByOShopMatter = context.state.navbarOShopMatterLists.find(item => item.matterId === matterId)
      if (findByOShopMatter) {
        const newOShopMatter = context.state.navbarOShopMatterLists.filter(item => item.matterId !== matterId)
        return context.commit('updateOShopMatterList', newOShopMatter);
      }
      const findBySIDMatter = context.state.navbarSIDMatterLists.find(item => item.matterId === matterId)
      if(findBySIDMatter) {
        const newSIDMatter = context.state.navbarSIDMatterLists.filter(item => item.matterId !== matterId)
        return context.commit('updateSIDMatterList', newSIDMatter);
      }
    },
  },
  modules: {
    auth,
    snackbar
  },
  plugins: [
    createPersistedState({
      paths: ['auth.user','backgroundColorCustomer','backgroundColorProduct','backgroundColorInquiryContent'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
})

