import http from '@/httpCommon.js';

export default {
  // 問い合せ対応(状態パネル)の更新
  async createUser(data) {
    return http.post(`/users`, data);
  },

  // 問い合せ対応(お客様情報パネル)の更新
  async updateUser(userId, user) {
    console.log(user);
    return http.put(`/users/${userId}`, user);
  },

  //問い合せ対応(商品情報パネル)の商品追加・削除__商品複数想定
  async deleteUser(userId) {
    return http.delete(`/users/${userId}`);
  },

  // 問い合せ対応(商品情報パネル)の商品変更・削除__商品は1つのみを想定
  async getUser(userId) {
    return http.get(`/users/${userId}`);
  },

  async searchForUser(data) {
    let query = ''
    const key = Object.keys(data)
    const value = Object.values(data)

    for(let i=0; i < key.length; i++){
      if (i == 0) query += `?${key[i]}=${value[i]}`;
      else query += `&${key[i]}=${value[i]}`;
    }
    return http.get(`/user-search${query}`);
  },
  async changeUserPassword(passwordData){
    return http.put(`/my-info/password`,passwordData);
  },
  async updateMatterAssignableOrder(dataUpdate){
    return http.put('/user/matterAssignableOrder',dataUpdate,{ headers: {enableMessage: true}});
  },
  async getMatterAssignableUsers(){
    return http.get('/matter-assignable-users',{ headers: {enableMessage: false}});
  },
};
