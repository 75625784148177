import http from "@/httpCommon";

export default {
  getDownloadList(){
    return http.get(`/download-list`);
  },

  downloadFile(file_id){
    return http.get(`download/${file_id}`);
  },

  deleteFileDownLoad(file_id){
    return http.delete(`/download/${file_id}`);
  },
  retryDownLoadMattersFile(file_id) {
    return http.get(`/download/retry/${file_id}`);
  }
}
