import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import download from 'downloadjs'

import JsonCSV from 'vue-json-csv';
Vue.component('downloadCsv', JsonCSV);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  download,
  render: function (h) { return h(App) }
}).$mount('#app')

router.beforeEach((to, from, next) => {
  next()
})
