<template>
<div id="app">
<v-card width="500px" class="mx-auto mt-5" elevation-20 >
  <v-img style="margin-left: 190px; text-align:center" width="120px" class="justify-md-center" src="@/assets/logo_shachihata.png" />
<!--   <v-card-title>
    <h3 class="display-1">ログイン</h3>
  </v-card-title>
 -->
  <v-form @submit.prevent="submit">
    <v-text-field v-model="loginId" prepend-icon="mdi-account-circle" label="ユーザ名" autofocus/>
      <v-text-field
        v-model="password"
        v-bind:type="showPassword ? 'text' : 'password'"
        @click:append="showPassword = !showPassword"
        prepend-icon="mdi-lock"
        v-bind:append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
        label="パスワード" />
      <v-alert dense outlined type="error" v-if="showError">
        ログインに失敗しました。
      </v-alert>
      <v-card-actions justify="end" >
        <v-btn class="ml-auto" type="submit" color="primary" >ログイン</v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</div>
</template>

<script>
import { mapActions } from 'vuex';
import userSettingService from "@/services/userSettingService";

export default {
  name: 'Login',
  data: function () {
    return {
      loginId: '',
      password: '',
      showPassword: false,
      showError: false,
    }
  },
  methods: {
    ...mapActions('auth', ['login']),
    async submit() {
      try {
        await this.login({ loginId: this.loginId, password: this.password });
        if (this.$store.state.auth.user.isViewer || this.$store.state.auth.user.isGuest){
          this.$store.dispatch('removeAllMatterListAction');
        }

        userSettingService.getMyUserSettings().then(
            response => {
              if (response.data.data){
                this.$store.commit('updateBackgroundColorCustomer', response.data.data.background_customer);
                this.$store.commit('updateBackgroundColorProduct', response.data.data.background_product);
                this.$store.commit('updateBackgroundColorInquiryContent', response.data.data.background_inquiry_content);
              }else{
                this.$store.commit('updateBackgroundColorCustomer', null);
                this.$store.commit('updateBackgroundColorProduct', null);
                this.$store.commit('updateBackgroundColorInquiryContent', null);
              }
            }
          );
        this.$router.push('/dashboard');
        this.showError = false;
      } catch (error) {
        this.showError = true;
        console.log(error.response);
      }
    }
  }
}
</script>
