<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,
  props: {
    label : {type: [Object,Array], default: () => []},
    dataBarChart: {type: [Object,Array], default: () => []},
  },
  watch:{
    dataBarChart(val){
     if (val){
       this.renderBarChart();
      }
    },
  },
  methods:{
    renderBarChart() {
      this.renderChart(
        {
          labels: this.label,
          datasets: this.dataBarChart,
        },
        {
          responsive: true,
          maintainAspectRatio: false,
          legend:{
            labels: {
              boxWidth: 20,
              padding:30,
            },
            position: "bottom",
          },
          animation: {
            duration: 2000,
            easing: "easeInOutQuart",

          },
        }
      );
    },
  },
  created(){
    // this.renderBarChart();
  }
};
</script>
