import http from '@/httpCommon.js';

export default {

  async createProduct(data) {
    return http.post(`/products`, data);
  },

  async importProduct(data,headers){
    return http.post('/products/import-csv',data,headers);
  },
  async updateProduct(productId, product) {
    return http.put(`/products/${productId}`, product);
  },

  async deleteProduct(productId) {
    return http.delete(`/products/${productId}`);
  },

  async getProduct(productId) {
    return http.get(`/products/${productId}`);
  },
  async searchForProduct(data) {
    let query = '';
    const key = Object.keys(data);
    const value = Object.values(data);

    for (let i = 0; i < key.length; i++) {
      if(i == 0 ) query += `?${key[i]}=${value[i]}`
      else query += `&${key[i]}=${value[i]}`
    }

    return http.get(`/product-search${query}`);
  },
};
