<template>
  <v-dialog
    :value="dialog"
    @input="$emit('input', $event)"
    @click:outside="close"
    max-width="600px"
  >
    <v-card>
      <v-card-title class="py-1">
        <!-- お知らせタイトル -->
        <span>{{ title }}</span>
        <v-spacer></v-spacer>
        <!-- 投稿日時や更新日時 -->
        <span v-if="createdAt" class="subtitle-1"
          >投稿日時:{{ createdAt.slice(0, -3) }}</span
        >
        <span v-if="createdAt !== updatedAt" class="subtitle-1">
          &nbsp;(更新:{{ updatedAt.slice(0, -3) }})</span
        >
        <v-spacer></v-spacer>
        <span class="subtitle-1">{{ `投稿者: ${createdBy}` }}</span
        >
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <!-- 関連商品 -->
        <p class="py-2" style="white-space: pre-wrap ; color: black">{{ relatedProducts }}</p>
        <v-divider></v-divider>
        <!-- お知らせ詳細 -->
        <v-textarea
          readonly
          solo
          flat
          auto-grow
          v-model="content"
          class="py-2"
          hide-details
        ></v-textarea>
        <v-divider></v-divider>
        <!-- 掲載期間 -->
        <p  class="py-2" style="white-space: pre-wrap ; color: black">{{ `投稿日時: ${postPeriod}` }}</p>
        <v-divider></v-divider>
        <p class="py-2" style="white-space: pre-wrap ; color: black ;margin-bottom: 0px!important;">
          <span>更新者: </span>
          <span v-if="updatedBy">{{updatedBy}}</span>
        </p>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <!-- 閉じるボタン -->
        <v-spacer></v-spacer>
        <v-btn color="primary" @click="close">閉じる</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    title: String,
    content: String,
    relatedProducts: String,
    notificationStartDate: String,
    notificationEndDate: String,
    postPeriod: String,
    createdAt: String,
    updatedAt: String,
    createdBy: String,
    updatedBy: String,
  },
  methods: {
    close() {
      this.$emit('close-dialog');
    },
  },
};
</script>
<style scoped>
>>>.v-text-field.v-text-field--enclosed:not(.v-text-field--rounded) > .v-input__control > .v-input__slot, .v-text-field.v-text-field--enclosed .v-text-field__details{
  padding: 0px!important;
}
</style>
