<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <span class="text-h5 ml-3">検索条件</span>
      <v-spacer></v-spacer>
      <v-btn icon @click="closeModal">
        <v-icon large>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-form ref="customerSearchform" @submit.prevent="searchCustomerList(1)">
        <v-row align="center" class="mt-2">
          <v-col cols="3">
            <p class="mb-0 text-subtitle-1 ml-16">名前</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="customerLastName"
              autofocus
              outlined
              clearable
              hide-details
              dense
              placeholder="姓名（漢字）"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" style="margin-top: 0">
          <v-col cols="3">
            <p class="mb-0 text-subtitle-1 ml-16">名前（かな）</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="customerLastNameKana"
              outlined
              clearable
              hide-details
              dense
              placeholder="姓名（ひらがな）"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" style="margin-top: 0">
          <v-col cols="3">
            <p class="mb-0 text-subtitle-1 ml-16">メールアドレス１</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="email1"
              outlined
              clearable
              hide-details
              dense
              placeholder="メールアドレスを入力"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" style="margin-top: 0">
          <v-col cols="3">
            <p class="mb-0 text-subtitle-1 ml-16">メールアドレス２</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="email2"
              outlined
              clearable
              hide-details
              dense
              placeholder="メールアドレスを入力"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" style="margin-top: 0">
          <v-col cols="3">
            <p class="mb-0 text-subtitle-1 ml-16">会社名/販売店名</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="companyName"
              outlined
              clearable
              hide-details
              dense
              placeholder="株式会社〇〇"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" style="margin-top: 0">
          <v-col cols="3">
            <p class="mb-0 text-subtitle-1 ml-16">会社名（かな）</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="companyNameKana"
              outlined
              clearable
              hide-details
              dense
              placeholder="かぶしきかいしゃ〇〇"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" class="mt-0">
          <v-col cols="3">
            <p class="mb-0 text-subtitle-1 ml-16">ND表示電話番号</p>
          </v-col>
          <v-col cols="8" class="pb-0 pt-0">
            <v-checkbox
              ref="chkNdDisplayTel"
              v-model="isNdDisplayTel"
              :label="ndDisplayTelSearch"
              class="d-inline-flex mini-size"
              dense
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row align="center" style="margin-top: 0">
          <v-col cols="3">
            <p class="mb-0 text-subtitle-1 ml-16">電話番号１</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="phoneNumber1"
              :disabled="isNdDisplayTel"
              outlined
              clearable
              hide-details
              dense
              placeholder="例：09012345678 (半角･ハイフンなし)"
              :background-color="isNdDisplayTel ? 'grey lighten-2' : ''"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" style="margin-top: 0">
          <v-col cols="3">
            <p class="mb-0 text-subtitle-1 ml-16">電話番号２</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="phoneNumber2"
              :disabled="isNdDisplayTel"
              outlined
              clearable
              hide-details
              dense
              placeholder="例：09012345678 (半角･ハイフンなし)"
              :background-color="isNdDisplayTel ? 'grey lighten-2' : ''"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" style="margin-top: 0">
          <v-col cols="3">
            <p class="mb-0 text-subtitle-1 ml-16">郵便番号</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="postalCode"
              outlined
              clearable
              hide-details
              dense
              placeholder="例：0001234 (半角･ハイフンなし)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row align="center" style="margin-top: 0">
          <v-col cols="3">
            <p class="mb-0 text-subtitle-1 ml-16">都道府県</p>
          </v-col>
          <v-col cols="8">
            <v-select
              v-model="prefectureName"
              :items="prefectureNameList"
              outlined
              clearable
              hide-details
              dense
              placeholder="選択してください"
            ></v-select>
          </v-col>
        </v-row>
        <v-row align="center" style="margin-top: 0">
          <v-col cols="3">
            <p class="mb-0 text-subtitle-1 ml-16">住所</p>
          </v-col>
          <v-col cols="8">
            <v-text-field
              v-model="address"
              outlined
              clearable
              hide-details
              dense
              placeholder="例：〇〇市△△区〇〇町0-0"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-divider class="mx-4 mt-2"></v-divider>
        <!-- 検索ボタンと入力クリアボタン -->
        <v-card-actions class="justify-center py-3">
          <v-btn
            rounded
            color="success"
            x-large
            class="px-8 mr-10"
            type="submit"
          >入力した内容で検索
          </v-btn>
          <v-btn rounded color="warning" x-large class="px-12" @click="customerSearchReset">入力クリア</v-btn>
        </v-card-actions>
      </v-form>
    </v-card-text>
    <!-- 検索結果モーダル -->
    <v-dialog v-model="searchResulDialog" persistent max-width="95%" scrollable>
      <v-card>
        <v-toolbar color="primary" dark>
          <span class="text-h5 ml-3">検索結果</span>
          <v-spacer></v-spacer>
          <v-btn icon @click="searchResulDialogClose">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-title class="pl-4 pt-3 pb-0 text-h6 font-weight-bold blue--text text--darken-2">
          ※ダブルクリックでお客様決定
        </v-card-title>
        <!-- 検索結果の商品一覧テーブル -->
        <v-data-table
          fixed-header
          height="500px"
          :loading="loadingCount > 0"
          :items-per-page="-1"
          :headers="searchResultHeaders"
          :items="scrollDataList"
          :sort-by.sync="sortBy"
          loading-text="読み込み中"
          :sort-desc.sync="sortDesc"
          hide-default-footer
          no-data-text="検索結果に一致するお客様情報はありません。"
          class="elevation-3 mx-4 mb-2 mt-0 justify-center"
          @click:row="tableClick"
          @dblclick:row="tableDoubleClick">

          <template v-slot:foot>
            <v-row v-intersect="infiniteScrolling"></v-row>
          </template>
        </v-data-table>

        <!-- ページネーション -->

        <v-card-text class="mt-10 rounded-lg" style="height: 600px;">
          <span v-show="customerSelected" class="pb-0 text-h6 font-weight-bold blue--text text--darken-2">
            ※お客様詳細情報
          </span>
          <!-- ローディング中にスピナーを表示させる -->
          <v-card class="customer-details mt-3 pl-0 ml-0" v-if="customerSelected">
            <v-row>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">氏名: </label>
                <div class="d-inline">{{ customerSelected.lastName }}</div>
              </v-col>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">お客様種別: </label>
                <div class="d-inline">{{ customerTypeFlgName[customerSelected.customerTypeFlg] }}</div>
              </v-col>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">E-Mail①: </label>
                <div class="d-inline">{{ customerSelected.email1 }}</div>
              </v-col>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">Tel①:</label>
                <div class="d-inline">{{ customerSelected.phoneNumber1 }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">氏名(カナ): </label>
                <div class="d-inline">{{ customerSelected.lastNameKana }}</div>
              </v-col>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">要注意フラグ: </label>
                <div class="d-inline">{{ attentionFlgName[customerSelected.attentionFlg] }}</div>
              </v-col>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">E-Mail②: </label>
                <div class="d-inline">{{ customerSelected.email2 }}</div>
              </v-col>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">Tel②: </label>
                <div class="d-inline">{{ customerSelected.phoneNumber2 }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">性別: </label>
                <div class="d-inline">{{ genderName[customerSelected.gender - 1] }}</div>
              </v-col>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">業種名: </label>
                <div class="d-inline">{{ customerSelected.industryName }}</div>
              </v-col>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">会社名: </label>
                <div class="d-inline">{{ customerSelected.companyName }}</div>
              </v-col>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">会社名（よみがな）</label>
                <div class="d-inline">{{ customerSelected.companyNameKana }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">部署名: </label>
                <div class="d-inline">{{ customerSelected.departmentName }}</div>
              </v-col>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">郵便番号: </label>
                <div class="d-inline">{{ customerSelected.postalCode }}</div>
              </v-col>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">都道府県名カナ: </label>
                <div class="d-inline">{{ customerSelected.prefectureName }}</div>
              </v-col>
              <v-col class="py-0">
                <label class="font-weight-bold mr-1">備考: </label>
                <div class="d-inline">{{ customerSelected.remarks }}</div>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="3" class="py-0">
                <label class="font-weight-bold mr-1">住所: </label>
                <div class="d-inline">{{ customerSelected.address }}</div>
              </v-col>
              <v-col cols="3" class="py-0">
                <label class="font-weight-bold mr-1">FAX番号: </label>
                <div class="d-inline">{{ customerSelected.faxNumber }}</div>
              </v-col>
              <v-col cols="3" class="py-0">
                <label class="font-weight-bold mr-1">購入先: </label>
                <div class="d-inline">{{ customerSelected.retailName }}</div>
              </v-col>
            </v-row>
          </v-card>
        </v-card-text>
        <!--        <v-overlay :value="loadingCount > 0">-->
        <!--          <v-progress-circular indeterminate></v-progress-circular>-->
        <!--        </v-overlay>-->
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script>
import getCustomerService from '@/services/getCustomerService.js';

export default {
  props: {
    dataTest: {
      type: [Object]
    },
    ndDisplayTelSearch: {
      type: String,
    },
  },
  data() {
    return {
      isNdDisplayTel: true,
      // 検索結果テーブルのページネーション関係
      sortBy: 'id',
      sortDesc: [true],
      page: 1,
      pageCount: 0,
      // 1ページに表示する行数の指定
      itemsPerPage: 30,
      // 名前(姓)
      customerLastName: '',
      // よみ(姓)
      customerLastNameKana: '',
      // メールアドレス1
      email1: '',
      // メールアドレス2
      email2: '',
      // 会社名/販売店名
      companyName: '',
      // 会社名/販売店名(よみ)
      companyNameKana: '',
      // 電話番号1
      phoneNumber1: '',
      // 電話番号2
      phoneNumber2: '',
      // 郵便番号
      postalCode: '',
      // 都道府県
      prefectureName: '',
      prefectureNameList: ['北海道', '青森県', '岩手県', '宮城県', '秋田県', '山形県', '福島県', '茨城県', '栃木県', '群馬県', '埼玉県',
        '千葉県', '東京都', '神奈川県', '新潟県', '富山県', '石川県', '福井県', '山梨県', '長野県', '岐阜県', '静岡県', '愛知県', '三重県',
        '滋賀県', '京都府', '大阪府', '兵庫県', '奈良県', '和歌山県', '鳥取県', '島根県', '岡山県', '広島県', '山口県', '徳島県', '香川県',
        '愛媛県', '高知県', '福岡県', '佐賀県', '長崎県', '熊本県', '大分県', '宮崎県', '鹿児島県', '沖縄県'],
      // 住所
      address: '',

      // 検索結果テーブルのヘッダー
      searchResultHeaders: [
        {text: '名前(姓名)', value: 'lastName'},
        {text: 'よみ(姓名)', value: 'lastNameKana'},
        {text: 'メールアドレス1', value: 'email1'},
        {text: 'メールアドレス2', value: 'email2'},
        {text: '会社名/販売店名', value: 'companyName'},
        {text: '会社名/販売店名(よみ)', value: 'companyNameKana'},
        {text: '電話番号1', value: 'phoneNumber1'},
        {text: '電話番号2', value: 'phoneNumber2'},
        {text: '郵便番号', value: 'postalCode'},
        {text: '都道府県', value: 'prefectureName'},
        {text: '住所', value: 'address'},
      ],
      // 検索結果モーダルflg
      searchResulDialog: false,
      // loading時間
      loadingCount: 0,
      customerSelected: null,
      genderName: ['男', '女', 'その他'],
      attentionFlgName: ['注意', '要注意'],
      customerTypeFlgName: ['個人消費者', '法人消費者', '販売店', '支店', 'その他'],

      // scroll data list
      scrollDataList: [],
    }
  },

  methods: {
    // テーブルの行をダブルクリックした時のイベント
    tableClick(event, {item}) {
      this.customerSelected = item
    },
    tableDoubleClick(event, {item}) {
      this.$emit('get-data-detail', item);
      this.$emit('modal-close');
      this.clearData();
    },
    // 入力クリアボタンイベント
    async customerSearchReset() {
      await this.$refs.customerSearchform.reset();
      this.isNdDisplayTel = true;
    },
    searchResulDialogClose() {
      this.clearData();
      this.searchResulDialog = false;
    },
    closeModal() {
      this.customerSearchReset();
      this.$emit('modal-close');
    },
    clearData() {
      this.page = 1;
      this.pageCount = 0;
      this.scrollDataList = [];
      this.customerSelected = null;
    },
    // 商品/サービス情報の検索結果取得
    searchCustomerList: async function (page = null) {
      // 検索結果モーダルを開く
      this.searchResulDialog = true;
      if(page) this.page = page;
      this.loadingCount++;
      try {
        const response = await getCustomerService.getCustomerList(
          this.customerLastName,
          this.customerLastNameKana,
          this.companyName,
          this.companyNameKana,
          this.email1,
          this.email2,
          this.phoneNumber1,
          this.phoneNumber2,
          this.isNdDisplayTel ? (this.ndDisplayTelSearch ? this.ndDisplayTelSearch : '') : null,
          this.postalCode,
          this.prefectureName,
          this.address,
          {
            limit: this.itemsPerPage,
            page: this.page,
            orderBy: !this.sortBy || this.sortBy.length == 0 ? 'id' : this.sortBy,
            orderDir: !this.sortDesc[0] ? 'asc' : 'desc'
          }
        );
        // 取得したデータを格納
        let searchResulCustomerList = response.data.data.data;
        this.pageCount = response.data.data.last_page
        if (searchResulCustomerList.length > 0) {
          searchResulCustomerList.forEach(item => {
            this.scrollDataList.push(item);
          });
        }
      } catch (e) {
        console.log(e);
      }
      this.loadingCount--;
    },

    //scroll to load
    async infiniteScrolling() {
      if(this.page < this.pageCount){
        this.page++
        await this.searchCustomerList()
      }
    },
  },
  watch: {
    async sortBy() {
      this.clearData()
      await this.searchCustomerList()
    },
    async sortDesc() {
      this.clearData()
      await this.searchCustomerList()
    },
  },
}
</script>
<style scoped>
/* テーブルのヘッダーのフォントサイズ */
.v-data-table ::v-deep th {
  font-size: 18px !important;
  background-color: #bdbdbd;
}

/* 検索結果が「０」の場合に表示するテキストのフォントサイズ */
::v-deep .v-data-table__empty-wrapper > td {
  font-size: 20px !important;
}

/*.centerLoad{*/
/*  position: relative;*/
/*  left: 900%;*/
/*  transform: translateX(-450%);*/
/*}*/
</style>
