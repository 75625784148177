<template>
  <v-row justify="center">
    <v-dialog
      v-model="usrMstDialog"
      persistent
      overflow-y=initial
      width=1100px
    >
    <v-card>
      <v-card class="ma-3" elevation-10>
        <v-card-title class="headline">{{this.dialogTitle}}</v-card-title>
      </v-card>
      <v-card class="ma-3">
        <v-container fluid>
          <v-row>
            <v-col cols='6'>
              <v-card flat>
                <v-container fluid>
                  <v-row ma-0 pa-0>
                    <v-col cols="4" class="text-right text-h6" style="margin-top: 10px;" right>id</v-col>
                    <v-col cols="8"><v-text-field :readonly="actionMode!=1?true:false" outlined ma-0 pa-0 hide-details dense v-model='usr_data.id'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="text-right text-h6" style="margin-top: 10px;" right>ログインID<span v-if="actionMode!=0" style="color: red;">*</span></v-col>
                    <v-col cols="8">
                      <v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details :rules="rules" dense v-model='usr_data.loginId'></v-text-field>
                      <div style="color: red;" v-if="loginIdError">{{ loginIdMessageError }}</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="text-right text-h6" style="margin-top: 10px;" right>パスワード<span v-if="actionMode!=0" style="color: red;">*</span></v-col>
                    <v-col cols="8"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="passwordRules" v-model='usr_data.password'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="text-right text-h6" style="margin-top: 10px;" right>メールアドレス<span v-if="actionMode!=0" style="color: red;">*</span></v-col>
                    <v-col cols="8">
                      <v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="actionMode!=0?emailRules:rules" v-model='usr_data.email' placeholder="aaa@bbb.co.jp"></v-text-field>
                      <div style="color: red;" v-if="emailError">{{ emailMessageError }}</div>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="text-right text-h6" style="margin-top: 10px;" right>名前<span v-if="actionMode!=0" style="color: red;">*</span></v-col>
                    <v-col cols="4"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='usr_data.lastName'></v-text-field></v-col>
                    <v-col cols="4"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='usr_data.firstName'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="text-right text-h6" style="margin-top: 10px;" right>権限<span v-if="actionMode!=0" style="color: red;">*</span></v-col>
<!--                     <v-col cols="6"><v-text-field outlined ma-0 pa-0 hide-details dense v-model='usr_data.auth_flg'></v-text-field></v-col> -->
                    <v-col cols="8">
                      <v-radio-group :readonly=readOnly v-model="usr_data.authFlg" row ma-0 pa-0 dense>
                        <v-radio
                          style="margin-right: 4px;"
                          v-for="n,index in this.authFlgName"
                            :key="index"
                            :label=n
                            :value=index
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="text-right text-h6" style="margin-top: 10px;" right>部署名</v-col>
                    <v-col cols="8"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense :rules="rules" v-model='usr_data.departmentName'></v-text-field></v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols='6' style="margin-left: 0;">
              <v-card flat>
                <v-container fluid>
                  <v-row>
                    <v-col cols="4" class="text-right text-h6" style="margin-top: 10px;" right>作成者</v-col>
                    <v-col cols="8"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense v-model='usr_data.createdBy'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="text-right text-h6" style="margin-top: 10px;" right>更新者</v-col>
                    <v-col cols="8"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense v-model='usr_data.updatedBy'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="text-right text-h6" style="margin-top: 10px;" right>削除者</v-col>
                    <v-col cols="8"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense v-model='usr_data.deletedBy'></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="text-right text-h6" style="margin-top: 10px;" right>作成日時</v-col>
                    <v-col cols="8"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense v-model='usr_data.createdAt' placeholder="YYYY/MM/DD"></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="text-right text-h6" style="margin-top: 10px;" right>更新日時</v-col>
                    <v-col cols="8"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense v-model='usr_data.updatedAt' placeholder="YYYY/MM/DD"></v-text-field></v-col>
                  </v-row>
                  <v-row>
                    <v-col cols="4" class="text-right text-h6" style="margin-top: 10px;" right>削除日時</v-col>
                    <v-col cols="8"><v-text-field :readonly=readOnly outlined ma-0 pa-0 hide-details dense v-model='usr_data.deletedAt' placeholder="YYYY/MM/DD"></v-text-field></v-col>
                  </v-row>
                  <v-row v-if='this.actionMode==2'>
                  <v-col cols="4" class="text-right text-h6" style="margin-top: 10px;" right>案件作成画面</v-col>
                  <v-col cols="8">
                    <v-radio-group :readonly=readOnly v-model="usr_data.matterAssignable" row ma-0 pa-0 dense>
                      <v-radio
                        v-for="n in this.matterAssignableName"
                        :key="n.key"
                        :label="n.text"
                        :value="n.key"
                      ></v-radio>
                    </v-radio-group>
                  </v-col>
                  </v-row>
                  <v-row v-if='this.actionMode==2' class="mb-4">
                    <v-col cols="4" ></v-col>
                    <v-col cols="8" class="d-flex justify-start">
                      <v-btn @click="csvSettingsDialog = true" color="#1976d2cf" class="white--text">CSV出力項目設定</v-btn>
                    </v-col>
                    <v-dialog
                      persistent
                      overflow-y=initial
                      max-width="500"
                      v-model="csvSettingsDialog"
                    >
                      <v-card>
                        <v-card-title class="text-h5">
                          CSV出力項目設定
                        </v-card-title>
                        <div class="d-flex" style="margin: -10px 24px">
                          <v-checkbox
                            v-model="selectAll"
                            style="padding-left: 2px"
                          ></v-checkbox>
                          <span style="margin: auto 65px;font-weight: bold">項目一覧</span>
                        </div>
                        <v-divider></v-divider>
                        <draggable tag="ul" :list="setting_fields" @change="reOder">
                          <transition-group>
                            <v-col
                              class="list-group-item"
                              v-for="(settings,index) in setting_fields"
                              :key="index"
                            >
                              <v-row style="border-bottom: 1px solid #E0E0E0">
                                <v-col cols="2"  style="margin-top:-20px;margin-bottom:-35px;margin-left: -10px">
                                  <v-checkbox
                                    v-model="settings.selected"
                                  ></v-checkbox>
                                </v-col>
                                <v-col cols="6" style="margin: auto">
                                  <span>{{ settings.display_name }}</span>
                                </v-col>
                                <v-col cols="2" style="margin: auto">
                                  <v-icon class="handle">mdi-drag</v-icon>
                                </v-col>
                              </v-row>
                            </v-col>
                          </transition-group>
                        </draggable>
                        <v-card-actions class="mt-3">
                          <v-spacer></v-spacer>
                          <v-btn class="col-3"
                            color="primary"
                            @click="saveSettings"
                          >
                            保存
                          </v-btn>
                          <v-btn class="col-3"
                                 color="primary"
                                 @click="closeSettings"
                          >
                            閉じる
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>

                </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <v-card class="ma-3" elevation-24 dense>
        <v-card-actions>
          <v-spacer></v-spacer>
          <template v-if='this.actionMode!=2'>
            <v-btn color="primary" @click="cancelEvent" style="width: 120px;">キャンセル</v-btn>
          </template>
          <template v-if='this.actionMode==2'>
            <v-btn color="primary" :disabled="!isValid" @click="updateUser" style="width: 120px;">保存</v-btn>
            <v-btn color="primary" @click="deleteUser" style="width: 120px;">削除</v-btn>
          </template>
            <v-btn color="primary" :disabled="!isValid && actionMode==1" @click="okEvent" style="width: 120px;">{{okButtonName[actionMode]}}</v-btn>
        </v-card-actions>
      </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  import constants from '@/components/helpers/const';
  import userService from '@/services/userService.js';
  import userSettingService from "@/services/userSettingService";
  import draggable from 'vuedraggable'
  import constant from "@/components/helpers/const";

  export default {
    components: {
      draggable,
    },
    name: 'masterUserDialog',
    props: {
      dialogTitle: String,
      usrMstDialog: Boolean,
      actionMode: Number, /* 0:検索, 1:登録 */
      dialogItems: Object,
      savedSettings: Array,
      readOnly: Boolean
    },
    data: function () {
      return {
        authFlgName: ['管理者', '利用者', '閲覧者','ゲスト'],
        matterAssignableName: [
          {key: 1, text: '表示'},
          {key: 0, text: '非表示'}
        ],
        okButtonName: ['検索', '登録', '閉じる'],
        csvSettingsDialog: false,
        dialog: false,
        usr_data: {},
        rules: [v => (v.length < 256 || !v ) || "Max 255 characters"],
        passwordRules: [v => ((v.length < 256 && v.length > 0) || !v ) || "Max 255 characters"],
        emailRules: [v => ((v.length < 256  && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ) || !v) || "Invalid email"],

        setting_fields: constants.allExportSettings,
        defaultMatterFields: constants.defaultMatterFields,
        loginIdError: false,
        loginIdMessageError: '',
        emailError: false,
        emailMessageError: ''
        //
      }
    },
    computed: {
      isValid (){
        return (!this.usr_data.loginId || this.usr_data.loginId.length < 256)
         && (this.usr_data.email && this.usr_data.email.length < 256 && /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.usr_data.email))
         && ((this.usr_data.password && this.actionMode == 1) || (!this.usr_data.password && this.actionMode != 1) || (this.usr_data.password.length < 256 && this.usr_data.password.length > 0 && this.actionMode != 1))
         && (!this.usr_data.firstName || this.usr_data.firstName.length < 256)
         && (!this.usr_data.lastName || this.usr_data.lastName.length < 256)
         && (!this.usr_data.departmentName || this.usr_data.departmentName.length < 256)
         && (this.usr_data.lastName && this.usr_data.firstName)
      },
      selectAll: {
        get: function () {
          return this.setting_fields ? this.setting_fields.every((item) => item.selected) : false;
        },
        set: function (value) {
          this.setting_fields = this.setting_fields.map((item) => ({...item,selected: !!value}))
        }
      }
    },
    async mounted() {
/*       this.readOnly = true
      if( (0 == this.actionMode) || (1 == this.actionMode) ) {
        this.readOnly = false
      }
      if( 0 == this.actionMode ) {
      if( 0 == this.actionMode ) {
        this.readOnly = true
      }
      if(1 == this.actionMode)  {
        this.readOnly = false
      } */
    },
    methods: {
      reOder() {
        const newSettingList = [...this.setting_fields].map((item, index) => {
          const newOrder = index + 1;
          item.hasChanged = item.sortOrder !== newOrder;
          if (item.hasChanged) {
            item.sortOrder = newOrder;
          }
          return item;
        });
        this.setting_fields = newSettingList;
      },
      async saveSettingOrder(selected) {
        let data = {
          header_display_orders: [],
          field_display_orders: []
        };
        if (selected.length > 0){
          this.setting_fields.forEach(setting => {
            data.header_display_orders.push(setting.display_name);
            data.field_display_orders.push(setting.field);
          });
        }else {
          this.setting_fields = constant.allExportSettings;
        }
        await userSettingService.updateDisplaySettingOrders(this.usr_data.id,data);
      },
      async reloadList(userId) {
        await userSettingService.getUserSettings(userId).then(Response => {
          let data = Response.data.data;
          let setting = [];
          if (data && data.field_display && data.field_display_orders && data.header_display_orders){
            let header_display_orders = data.header_display_orders.split(';').filter(item => item !== '');
            let field_display_orders = data.field_display_orders.split(';').filter(item => item !== '');
            let field_display = data.field_display.split(';').filter(item => item !== '');
            field_display_orders.forEach((value,index) => {
              setting.push(Object.assign({field: value, display_name: header_display_orders[index]}));
            })
            if (field_display.length > 0){
              let objectExMatterNumber = {field: "ex_matter_number", display_name: "NO"};
              let objectSidMatterNumber = {field: "sid_matter_number", display_name: "SIDお問い合わせ番号"};
              let isExMatterNumber = setting.some(item => item.field == objectExMatterNumber.field)
              let isSidMatterNumber = setting.some(item => item.field == objectSidMatterNumber.field)
              if(!isExMatterNumber){
                setting.push(objectExMatterNumber);
              }
              if(!isSidMatterNumber){
                setting.push(objectSidMatterNumber);
              }
              this.setting_fields = setting.map((item)=>{
                return {...item, selected: !!field_display.includes(item.field)}
              });
            }else {
              this.defaultChoose(setting);
            }
          }else {
            this.defaultChoose(this.setting_fields);
          }
        })
      },
      defaultChoose(data){
        let defaultChoose = [];
        this.defaultMatterFields.map((item) => {
          defaultChoose.push(item);
        })
        this.setting_fields = data.map((item)=>{
          return {...item, selected: !!defaultChoose.includes(item.field)}
        });
      },
      cancelEvent() {
        this.$emit('result', {'res': false, 'message': []})
      },
      reloadData(){
        this.$emit('reload')
      },
      async okEvent() {
        if(this.actionMode == 1){
          this.resetError()
          await userService.createUser(this.usr_data)
          .then(response => {
            if(response.status == 200){
              this.cancelEvent()
            }
          })
          .catch(error =>{
            if(error.response.status == 422){
              if(error.response.data.errors.loginId) {
                this.loginIdError = true
                this.loginIdMessageError = error.response.data.errors.loginId[0]
              }
              if(error.response.data.errors.email) {
                this.emailError = true
                this.emailMessageError = error.response.data.errors.email[0]
              }
            }
          });
          this.reloadData()
        }
        else if(this.actionMode == 0 ){
          this.$emit('result', {'res': true, 'message': this.usr_data})
        }
        else this.cancelEvent()
      },
      async deleteUser(){
        await userService.deleteUser(this.usr_data.id)
        this.cancelEvent()
        this.reloadData()
      },
      resetError() {
        this.loginIdError = false
        this.loginIdMessageError = ''
        this.emailError = false
        this.emailMessageError = ''
      },
      async updateUser(){
        this.resetError()
        await userService.updateUser(this.usr_data.id, this.usr_data)
        .then(response => {
          if(response.status == 200){
            this.cancelEvent()
          }
        })
        .catch(error =>{
          if(error.response.status == 422){
            if(error.response.data.errors.loginId) {
              this.loginIdError = true
              this.loginIdMessageError = error.response.data.errors.loginId[0]
            }
            if(error.response.data.errors.email) {
              this.emailError = true
              this.emailMessageError = error.response.data.errors.email[0]
            }
          }
        });
        this.reloadData()
      },


      //todo settings matters csv export
      closeSettings(){
        this.$emit('closeSetting')
        this.csvSettingsDialog = false
      },
      async saveSettings(){
        let data = {
          header_display: [],
          field_display: [],
        }
        let selected = [];
        this.setting_fields.filter(function (setting) {
          if (setting.selected === true)
            selected.push(Object.assign({display_name: setting.display_name, field: setting.field}));
        });
        selected.forEach(setting => {
          data.field_display.push(setting.field);
          data.header_display.push(setting.display_name);
        })
        await this.saveSettingOrder(selected);
        await userSettingService.updateUserSettings(this.usr_data.id,data);
        this.$emit('saveUserSetting',this.usr_data.id);
        this.csvSettingsDialog = false
      },
      //
    },
    watch: {
      usrMstDialog: function (newUsrMstDialog, oldUsrMstDialog) {
        oldUsrMstDialog == oldUsrMstDialog
        if(newUsrMstDialog == true){
          this.usr_data = JSON.parse(JSON.stringify(this.dialogItems));
          this.reloadList(this.usr_data.id);
        }else {
          this.setting_fields =  constants.allExportSettings;
          this.setting_fields.every((item) => item.selected = false);
        }
      },
      csvSettingsDialog(val){
        if (val === false){
          this.reloadList(this.usr_data.id);
        }
      },
      actionMode(val){
        if(val == 1){
          this.usr_data.authFlg = 0;
          this.usr_data.matterAssignable = 1;
        }
      },

    }
  }
</script>
<style>
.container{
  margin: 0;
     padding:0px;
  }
</style>
