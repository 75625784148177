<template>
  <v-container fluid>
    <v-toolbar style="height: auto!important;background: #BDBDBD" dense>
      <span style="margin: auto;font-size: 18px;">製造ロット</span>
    </v-toolbar>
    <v-text-field
      label="製造ロットを入力してください"
      style="border-top-left-radius: unset;border-top-right-radius: unset"
      v-model="matterStatusData.productLot"
      :background-color="getBackgroundColor(matterStatusData.oldProductLot, matterStatusData.productLot)"
      solo
      flat
      :readonly="readonlyFlg"
      hide-details
    ></v-text-field>
  </v-container>
</template>
<script>
import {getBackgroundColor} from '@/components/helpers/utils';

export default {
  props: {
    matterStatusData: {
      type: [String, Number, Object]
    },
    // // 親コンポーネントよりmatterIdを取得
    matterId: {
      type: Number,
    },
    readonlyFlg: {
      type: Boolean,
    },
  },
  data() {
    return {
      // 最小化の表示を切り替えるflg
      minimizeFlg: false,
    }
  },
  watch: {
    matterStatusData: {
      handler(val) {
        if (this.mountCompletion) {
          this.saveState(val.productLot);
        }
      },
      deep: true
    },
    $route() {
      this.mountCompletion = true;
      this.restoreState();
    },
    matterId: function () {
      this.mountCompletion = false;
    },
  },
  methods: {
    getBackgroundColor,
    restoreState() {
      let matterState = this.$store.getters.getMatterState(this.matterStateKey);
      if (typeof matterState === 'string') this.matterStatusData.productLot = matterState;
    },
    saveState(data) {
      this.$store.dispatch('saveMatterStateAction', {matterId: this.matterId, key: this.matterStateKey, data})
    },
  },
  computed: {
    matterStateKey() {
      return `matter_state_${this.matterId}_inquiry_product_lot`;
    }
  },
  mounted() {
    this.mountCompletion = true;
    this.restoreState();
  }
}
</script>
<style scoped></style>
